import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '@/types/user'

const userSlice = createSlice({
  name: 'user',
  initialState: {} as User,
  reducers: {
    setUserAccountId: (state: User, action: PayloadAction<User>) => ({
      ...state,
      ...action.payload
    })
  }
})

export const { setUserAccountId } = userSlice.actions
export default userSlice.reducer
