import React from 'react'
import NavBarLink from '@/react/components/NavBarLink'
import { useAppSelector } from '@/react/hooks'
import {
  selectCanNavigate,
  selectPaid,
  selectCheckoutComplete,
  selectPaymentDisabled,
  selectShippingDisabled,
  selectContactDisabled
} from '@/react/selectors'

const NavigationBar = () => {
  const canNavigate = useAppSelector(selectCanNavigate)
  const paid = useAppSelector(selectPaid)
  const checkoutComplete = useAppSelector(selectCheckoutComplete)
  const shippingDisabled = useAppSelector(selectShippingDisabled)
  const paymentDisabled = useAppSelector(selectPaymentDisabled)
  const contactDisabled = useAppSelector(selectContactDisabled)

  return paid || checkoutComplete ? null : (
    <div
      className="NavigationStepsBar _background border-b"
      data-testid="NavigationBar"
    >
      <div className="container flex items-center bg-gray-100 select-none">
        {contactDisabled ? null : (
          <NavBarLink name="contact" disabled={!canNavigate['/contact']} />
        )}
        {shippingDisabled ? null : (
          <NavBarLink name="shipping" disabled={!canNavigate['/shipping']} />
        )}
        {paymentDisabled ? null : (
          <NavBarLink name="payment" disabled={!canNavigate['/payment']} />
        )}
        <NavBarLink name="review" disabled={!canNavigate['/review']} />
      </div>
    </div>
  )
}

export default NavigationBar
