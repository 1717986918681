import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  BillingAddress,
  BillingAddressStatus,
  ShippingAddress,
  ShippingAddressStatus
} from '@/types/address'
import { Checkout, CheckoutStatus, Vouchers } from '@/types/checkout'
import { Contact } from '@/types/contact'
import { remove } from 'lodash'

const initialState = {} as Checkout

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // We prefer mutation of targeted pieces of the state proxy vs returning a new, full state in this codebase.
    // However, the Redux Toolkit docs recommend returning the action payload when replacing the entire slice;
    // therefore, replaceCheckout and setCheckoutStatus must return the next state.
    // See https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
    replaceCheckout: (state: Checkout, action: PayloadAction<Checkout>) => {
      return action.payload
    },
    setCheckoutStatus: (
      state: Checkout,
      action: PayloadAction<CheckoutStatus>
    ) => {
      return { ...state, ...action.payload }
    },
    replacePricing: (
      state: Checkout,
      action: PayloadAction<Checkout['pricing']>
    ) => {
      state.pricing = action.payload
    },
    addVoucherCode: (
      state: Checkout,
      action: PayloadAction<{ code: string }>
    ) => {
      state.vouchers = [{ code: action.payload.code }]
    },
    // for correctness, remove vouchers by their code to support multivoucher
    removeVoucherByCode: (
      state: Checkout,
      action: PayloadAction<string | undefined>
    ) => {
      remove(
        state.vouchers,
        ({ code: voucherCode }) => voucherCode == action.payload
      )
      state.vouchers = state.vouchers
      state.stale_quote = true
    },
    clearVoucher: (state: Checkout) => {
      state.vouchers = []
    },
    updateVoucherByCode: (
      state: Checkout,
      action: PayloadAction<{ code: string; updatedVoucher: Vouchers }>
    ) => {
      const voucherIndex = state.vouchers.findIndex(
        (voucher) => voucher.code == action.payload.code
      )
      state.vouchers[voucherIndex] = action.payload.updatedVoucher
    },
    setContact: (state: Checkout, action: PayloadAction<Partial<Contact>>) => {
      state.contact = {
        ...state.contact,
        ...action.payload
      }
    },
    setShippingAddress: (
      state: Checkout,
      action: PayloadAction<Partial<ShippingAddress>>
    ) => {
      state.shipping_address = {
        ...state.shipping_address,
        ...action.payload
      } as ShippingAddress
    },
    setShippingAddressStatus: (
      state: Checkout,
      action: PayloadAction<Partial<ShippingAddressStatus>>
    ) => {
      state.shipping_address = {
        ...state.shipping_address,
        ...action.payload
      } as ShippingAddress
    },
    setBillingAddress: (
      state: Checkout,
      action: PayloadAction<Partial<BillingAddress>>
    ) => {
      state.billing_address = {
        ...state.billing_address,
        ...action.payload
      } as BillingAddress
    },
    setBillingAddressStatus: (
      state: Checkout,
      action: PayloadAction<Partial<BillingAddressStatus>>
    ) => {
      state.billing_address = {
        ...state.billing_address,
        ...action.payload
      } as BillingAddress
    },
    setRedirectUrl: (
      state: Checkout,
      action: PayloadAction<Checkout['redirect_url']>
    ) => {
      state.redirect_url = action.payload
    }
  }
})

export const {
  replaceCheckout,
  replacePricing,
  setBillingAddress,
  setBillingAddressStatus,
  setCheckoutStatus,
  setContact,
  setRedirectUrl,
  setShippingAddress,
  setShippingAddressStatus,
  addVoucherCode,
  removeVoucherByCode,
  updateVoucherByCode,
  clearVoucher
} = checkoutSlice.actions
export default checkoutSlice.reducer
