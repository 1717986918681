import React from 'react'
import classNames from 'classnames'
import CurrencyFormat from 'react-currency-format'
import ActivityIndicator from '@/react/components/ActivityIndicator'
import Image from '@/react/components/Image'
import formatCurrency from '@/react/utils/currencyFormatter'

interface LineItemProps {
  alwaysShow?: boolean
  amount?: number | string
  placeholder?: number | string
  bottomLine?: boolean
  credit?: boolean
  description?: string
  title: string
  loading?: boolean
}

interface ImageItemProps {
  amount?: number | string
  baseKey: string
  description?: string
  images: string[]
  quantityPrice: string
  title: string
  pricingDisabled: boolean
}

const HtmlItemDescription = (props: LineItemProps | ImageItemProps) => (
  <>
    {props.description ? (
      <div className="w-full text-sm font-semibold text-gray-700">
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
    ) : null}
  </>
)
interface CurrencyProps {
  amount?: number | string
  credit?: boolean
}

// Safely conver the various possible types for amount to a float
const floatAmount = (amount: string | number | undefined) => {
  if (!amount) {
    return 0.0
  }

  return parseFloat(amount.toString())
}

export const Currency = (
  props: LineItemProps | ImageItemProps | CurrencyProps
) => {
  const isCredit = 'credit' in props && props.credit === true
  return (
    <CurrencyFormat
      className={classNames({ 'text-red-500': isCredit })}
      decimalScale={2}
      displayType={'text'}
      fixedDecimalScale={true}
      isNumericString={true}
      prefix={isCredit ? '-' : ''}
      thousandSeparator={true}
      value={formatCurrency(props.amount || 0)}
    />
  )
}

export const LineItemWithImages = (props: ImageItemProps) => {
  return props.amount ? (
    <div className="lg:flex-nowrap relative flex flex-wrap items-center w-full pt-3 pb-3">
      {props.images && props.images.length > 0 && (
        <div className="lg:mb-0 lg:-ml-px lg:mr-4 lg:w-auto justify-left flex w-full mb-3 space-x-2">
          {props.images?.map((image, idx) => (
            <Image
              key={`${props.baseKey}-image-${idx}`}
              className="lg:w-24 w-36 bg-white border border-gray-300 rounded"
              src={image}
            />
          ))}
        </div>
      )}
      <div className="flex flex-col flex-wrap w-full">
        <div className="text-md w-full font-bold">{props.title}</div>
        <div className="flex justify-between w-full">
          <div>{props.quantityPrice}</div>
          {!props.pricingDisabled && (
            <div className="font-semibold text-right">
              {Currency({ amount: props.amount })}
            </div>
          )}
        </div>
        {HtmlItemDescription(props)}
      </div>
    </div>
  ) : null
}

export default function LineItem(props: LineItemProps) {
  const shouldDisplayAmount =
    floatAmount(props.amount) !== 0 || props.alwaysShow
  const shouldDisplayItem = shouldDisplayAmount || props.placeholder

  return shouldDisplayItem ? (
    <div className="w-full py-4">
      <div
        className={classNames(
          'flex items-center justify-between font-semibold',
          {
            'py-4 text-xl': props.bottomLine
          }
        )}
      >
        <div>{props.title}</div>
        {(props.loading && <ActivityIndicator size="small" />) ||
          (shouldDisplayAmount && Currency(props)) ||
          props.placeholder}
      </div>
      {props.bottomLine ? null : HtmlItemDescription(props)}
    </div>
  ) : null
}
