import React from 'react'
import { batch } from 'react-redux'
import { useAppSelector, useAppDispatch } from '@/react/hooks'
import { PaymentMethod } from '@/types/payment'
import {
  setPaymentMethod,
  setVerifyPayment
} from '@/react/features/checkout/paymentSlice'
import RadioButton from '@/react/components/RadioButton'
import AnalyticsService from '@/services/AnalyticsService'

export default function PaymentMethodRadioButton({
  element,
  label,
  icon,
  method,
  testId
}: {
  element?: JSX.Element
  label: string
  icon?: string
  method: PaymentMethod
  testId?: string
}) {
  const dispatch = useAppDispatch()
  const paymentMethod: PaymentMethod = useAppSelector(
    (state) => state.payment.method
  )

  const isSelected = paymentMethod === method

  const handlePaymentMethodClick = (selection: PaymentMethod) => () => {
    batch(() => {
      dispatch(setVerifyPayment(false))
      dispatch(setPaymentMethod(selection))
    })
    AnalyticsService.trackEvent(null, 'change-payment-method', selection)
  }

  return (
    <div className="px-6 py-4 border-b">
      <div
        className="flex items-center flex-auto cursor-pointer"
        data-testid={testId}
        onClick={handlePaymentMethodClick(method)}
      >
        <RadioButton className="mr-2" checked={isSelected} />

        <div className="flex-auto mr-2 font-bold leading-none">{label}</div>

        {icon ? <img src={icon} className="h-8" alt={label} /> : null}
      </div>
      {element ? (
        <div
          className={
            isSelected ? 'px-lg-2 w-full mt-6 mb-3' : 'hidden disabled'
          }
        >
          {element}
        </div>
      ) : null}
    </div>
  )
}
