import React from 'react'
import classNames from 'classnames'
import imageUnknown from '@/assets/images/credit-cards/empty.svg'
import imageVisa from '@/assets/images/credit-cards/visa.svg'
import imageMaestro from '@/assets/images/credit-cards/maestro.svg'
import imageMasterCard from '@/assets/images/credit-cards/master-card.svg'
import imageAmericanExpress from '@/assets/images/credit-cards/american-express.svg'
import imageDiscover from '@/assets/images/credit-cards/discover.svg'
import imageDinersClub from '@/assets/images/credit-cards/diners-club.svg'
import imageJcb from '@/assets/images/credit-cards/jcb.svg'
import imageUnionpay from '@/assets/images/credit-cards/unionpay.svg'

const cardIcons: { [key: string]: any } = {
  'unknown': imageUnknown,
  'visa': imageVisa,
  'maestro': imageMaestro,
  'mastercard': imageMasterCard,
  'americanexpress': imageAmericanExpress,
  'discover': imageDiscover,
  'dinersclub': imageDinersClub,
  'jcb': imageJcb,
  'unionpay': imageUnionpay
}

const CreditCardIcon = (props: { cardType?: string; className?: string }) => {
  const unknown = 'unknown'
  const cardType = !!props.cardType
    ? props.cardType.toLowerCase().replace('-', '')
    : unknown
  const src = cardType in cardIcons ? cardIcons[cardType] : cardIcons[unknown]

  return (
    <img
      src={src}
      className={classNames('CreditCardIcon w-6', props.className)}
    />
  )
}

export default CreditCardIcon
