import { ShippingCountry } from '@/types/shipping'
import { Country } from '@/types/address'
import { Config, FeatureFlag } from '@/types/config'

// Load config data
const element = document.getElementById('config')
const config = element ? JSON.parse(element.innerText) : {}

export default {
  ...config,

  countries: config?.countries?.length ? config.countries : ([] as Country[]),

  feature_flags: config?.feature_flags?.length
    ? config.feature_flags
    : ([] as FeatureFlag[]),

  shipping_countries: config.countries.filter((country: ShippingCountry) => {
    return config.valid_country_codes.includes(country.code)
  }) as ShippingCountry[],

  shipping_methods: [
    {
      key: 'standard',
      name: 'Standard Delivery'
    },
    {
      key: 'rush',
      name: 'Rush Delivery'
    },
    {
      key: 'super_rush',
      name: 'Super Rush Delivery'
    }
  ],

  purchase_order_validation: {
    max_contact_length: 80,
    max_institution_length: 80,
    max_po_number_length: 40,
    max_po_file_size_in_mb: 5,
    supported_file_types: [
      'image/png',
      'image/jpeg',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf'
    ]
  }
} as Config
