import type { RootState } from '@/react/store'
import { createSelector } from '@reduxjs/toolkit'
import { validateContact } from '@/react/services/contact_validation'
import {
  validateShippingAddress,
  validateSelectableShipping
} from '@/react/services/address_validation'
import type {
  CheckoutError,
  taxableAddressOptions,
  Item,
  PricingDiscounts
} from '@/types/checkout'
import findLast from 'lodash.findlast'

export const selectIsCreditCard = (state: RootState) =>
  state.payment.method === 'cc'

export const selectIsPurchaseOrder = (state: RootState) =>
  state.payment.method === 'purchase_order'

export const selectIsApplePay = (state: RootState) =>
  state.payment.method === 'applepay'

export const selectIsPayPal = (state: RootState) =>
  state.payment.method === 'paypal'

export const selectIsPaymentWalletMethod = (state: RootState) =>
  // TODO: add PayPal back once billing address is fixed for PayPal
  // ['applepay', 'paypal'].includes(state.payment.method)
  ['applepay'].includes(state.payment.method)

export const selectAmount = (state: RootState) => state.checkout.pricing?.amount

export const selectSameAsShipping = (state: RootState) =>
  !state.checkout.billing_address ||
  !!state.checkout.billing_address?.same_as_shipping

export const selectShowBillingAddress = (state: RootState) => {
  if (selectIsPaymentWalletMethod(state)) {
    return false
  }
  return (
    (!!state.checkout.billing_address &&
      !state.checkout.billing_address.same_as_shipping) ||
    selectShippingDisabled(state) ||
    selectIsSelectableShipping(state)
  )
}

export const selectContactDisabled = (state: RootState) =>
  state.checkout.configuration?.contact?.disabled === true

export const selectContactReadonly = (state: RootState) =>
  state.checkout.configuration?.contact?.readonly === true

export const selectPaymentDisabled = (state: RootState) =>
  !!state.checkout.configuration?.payment?.disabled

export const selectPricingDisabled = (state: RootState) =>
  !!state.checkout.configuration?.pricing?.disabled

export const selectShippingDisabled = (state: RootState) =>
  !!state.checkout.configuration?.shipping_address?.disabled ||
  !!state.checkout.shipping_address?.disabled

export const selectConfigTaxableAddress = (state: RootState) =>
  state.checkout.configuration?.pricing?.taxable_address

export const selectUseAddressTypeForTax =
  (addressType: taxableAddressOptions) => (state: RootState) =>
    selectConfigTaxableAddress(state) === addressType

export const selectUseBillingForTax = (state: RootState) =>
  selectUseAddressTypeForTax('billing')(state)

export const selectUseShippingForTax = (state: RootState) =>
  selectUseAddressTypeForTax('shipping')(state) ||
  // default to 'shipping' if there was nothing specified
  !selectConfigTaxableAddress(state)

export const selectVouchersDisabled = (state: RootState) =>
  !!state.checkout.configuration?.vouchers?.disabled

export const selectVouchersReadonly = (state: RootState) =>
  !!state.checkout.configuration?.vouchers?.readonly

export const selectTaxFieldsReadonly = (state: RootState) =>
  !!state.checkout.configuration?.billing_address?.tax_fields_readonly ||
  !!state.checkout.billing_address?.tax_fields_readonly

export const selectHasShippingPostalCode = (state: RootState) =>
  !!state.checkout.shipping_address?.postal_code &&
  state.checkout.shipping_address?.postal_code !== ''

export const selectHasTaxableFields = (state: RootState) =>
  !!state.checkout.shipping_address?.state &&
  selectHasShippingPostalCode(state) &&
  !!state.checkout.shipping_address?.country

export const selectPaid = (state: RootState) =>
  !!state.checkout.paid || !!state.checkout.paid_at

export const selectCheckoutComplete = (state: RootState) =>
  !!state.checkout.completed || !!state.checkout.completed_at

export const selectRedirectable = (state: RootState) =>
  selectCheckoutComplete(state) && state.checkout.redirect_url

export const selectHasErrors = (
  state: RootState,
  sliceName: keyof CheckoutError
) =>
  !!Object.values((state.errors && state.errors[sliceName]) || {}).some(
    (value) => typeof value !== 'undefined'
  )

export const selectHasContactErrors = (state: RootState) =>
  selectHasErrors(state, 'contact')

export const selectHasShippingAddressErrors = (state: RootState) =>
  selectHasErrors(state, 'shipping_address')

export const selectItems = (state: RootState) => state.checkout.items || []
export const selectDiscounts = (state: RootState) =>
  state.checkout.pricing?.discounts || []

export const selectHasMultipleQuoteGroups = createSelector(
  [selectItems, selectDiscounts],
  (items, discounts) => {
    if (!items?.length && !discounts?.length) {
      return false
    }
    const quoteGroupIdHash: { [id: string]: number } = {}
    const quoteGroupIdItemsArray: Array<Item | PricingDiscounts> = [
      ...items,
      ...discounts
    ]
    quoteGroupIdItemsArray.forEach((item) => {
      if (!item) return
      if (item.quote_group_id !== undefined) {
        // theres no particular reason for incrementing here
        const instances = quoteGroupIdHash[item.quote_group_id]
        quoteGroupIdHash[item.quote_group_id] = instances + 1
      }
    })
    return Object.keys(quoteGroupIdHash).length > 1
  }
)

export const selectCanProceedFromContact = (state: RootState) =>
  validateContact({ data: state.checkout.contact }).valid ||
  selectContactDisabled(state)

export const selectCanNavigateToComplete = (state: RootState) =>
  selectPaid(state)

export const selectCanNavigateToContact = (state: RootState) =>
  !selectContactDisabled(state)

export const selectCanNavigateToShipping = (state: RootState) =>
  !selectCanNavigateToComplete(state) &&
  !selectShippingDisabled(state) &&
  selectCanProceedFromContact(state)

export const selectCanNavigateToPayment = (state: RootState): boolean => {
  const checkoutFlowConditions =
    selectPaymentDisabled(state) ||
    selectCanNavigateToComplete(state) ||
    !selectCanProceedFromContact(state)
  if (checkoutFlowConditions) {
    return false
  }

  // if configured for selectable shipping then either there is a selected option or valid address
  if (
    selectHasSelectableShippingConfig(state) &&
    !selectShippingDisabled(state)
  ) {
    if (!selectSelectableShippingFormLocation(state)) {
      return validateSelectableShipping({
        data: state.checkout.shipping_address
      }).valid
    }
    return (
      validateSelectableShipping({ data: state.checkout.shipping_address })
        .valid ||
      validateShippingAddress({ data: state.checkout.shipping_address }).valid
    )
  }

  // normal shipping flow: if no errors and its either certified
  return (
    !selectHasShippingAddressErrors(state) &&
    (state.checkout.shipping_address?.certified ||
      selectShippingDisabled(state))
  )
}

export const selectCanNavigateToReview = (state: RootState) => {
  return (
    selectPaymentDisabled(state) ||
    (selectCanNavigateToPayment(state) && !!state.payment?.verified)
  )
}

export const selectCanNavigate = (state: RootState) => {
  return {
    '/contact': selectCanNavigateToContact(state),
    '/shipping': selectCanNavigateToShipping(state),
    '/payment': selectCanNavigateToPayment(state),
    '/review': selectCanNavigateToReview(state),
    '/complete': selectCanNavigateToComplete(state)
  }
}

export const selectFarthestEligibleStage = (state: RootState) => {
  const canNavigateTo = selectCanNavigate(state)
  const stages: Array<keyof typeof canNavigateTo> = [
    '/contact',
    '/shipping',
    '/payment',
    '/review',
    '/complete'
  ]
  const farthest = findLast(stages, (stage) => canNavigateTo[stage])
  if (!farthest) {
    throw Error('routes have not been configured correctly')
  } else {
    return farthest
  }
}

export const selectSelectableShippingOptions = (state: RootState) =>
  state.checkout.configuration?.shipping_address?.location_options

export const selectSelectableShippingFormLocation = (state: RootState) =>
  state.checkout.configuration?.shipping_address?.form_shipping_location

export const selectHasSelectableShippingConfig = createSelector(
  [selectSelectableShippingOptions],
  (options) => !!options?.length
)

export const selectSelectedSelectableShippingIds = (state: RootState) =>
  state.checkout.shipping_address?.selected_shipping_ids

export const selectIsSelectableShipping = (state: RootState) =>
  !!selectSelectedSelectableShippingIds(state)?.length

export const selectSelectedShippingOptions = createSelector(
  [selectSelectableShippingOptions, selectSelectedSelectableShippingIds],
  (selectableShippingOptions, selectedShippingIds) => {
    const selectedShippingOption = selectableShippingOptions?.find(
      (option) => option.id === selectedShippingIds?.[0]
    )
    if (!selectedShippingOption) {
      return
    }
    return selectedShippingOption.value.shipping_address
  }
)
