import { configureStore, Middleware } from '@reduxjs/toolkit'
import { checkoutApi } from '@/react/services/checkout_api'
import checkoutReducer from '@/react/features/checkout/checkoutSlice'
import errorsReducer from '@/react/features/checkout/errorsSlice'
import flashReducer from '@/react/features/checkout/flashSlice'
import paymentReducer from '@/react/features/checkout/paymentSlice'
import purchaseOrderReducer from '@/react/features/checkout/purchaseOrderSlice'
import userReducer from '@/react/features/checkout/userSlice'
import statusReducer from '@/react/features/checkout/statusSlice'
import suggestionsReducer from '@/react/features/checkout/suggestionsSlice'

export const reducer = {
  [checkoutApi.reducerPath]: checkoutApi.reducer,
  checkout: checkoutReducer,
  errors: errorsReducer,
  flash: flashReducer,
  payment: paymentReducer,
  purchase_order: purchaseOrderReducer,
  user: userReducer,
  status: statusReducer,
  suggestions: suggestionsReducer
}

const middleware: Middleware[] = []
if (
  process.env.NODE_ENV === 'development' &&
  process.env.REDUX_LOGGER !== '0'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { reduxLogger } = require('@/react/utils/reduxLogger')
  middleware.push(reduxLogger)
}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(checkoutApi.middleware).concat(middleware)
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
