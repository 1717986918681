import React from 'react'
import { TextField } from '@customink/pigment-react'
import type { HTMLInputType } from '@/types/html'

interface TextInputFieldProps {
  autoComplete?: string
  className?: string
  disabled?: boolean
  endAdornment?: any
  error?: boolean
  errorMessage?: string
  id: string
  label?: string
  maxLength?: number
  name: string
  placeholder?: string
  propertyName: string
  removeHelperTextSpace?: boolean
  required?: boolean
  startAdornment?: any
  type?: HTMLInputType
  value: string
  onBlur?: (data: any) => void
  onChange?: (data: any) => void
  onFocus?: (data: any) => void
  onKeyUp?: (data: any) => void
}

const noOpHandler = () => {}

const TextInput = (props: TextInputFieldProps) => {
  const {
    onBlur = noOpHandler,
    onChange = noOpHandler,
    onFocus = noOpHandler,
    onKeyUp = noOpHandler
  } = props
  return (
    <div className={`checkout-input ${props.className || ''}`}>
      <TextField
        aria-label={props.label}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        endAdornment={props.endAdornment}
        error={!!props.errorMessage || !!props.error}
        fullWidth
        id={props.id}
        InputLabelProps={{ 'htmlFor': props.id }}
        inputProps={{
          'aria-label': props.label,
          'maxLength': props.maxLength,
          'required': props.required
        }}
        label={props.label}
        name={props.name}
        helperText={props.errorMessage}
        placeholder={props.placeholder}
        removeHelperTextSpace={props.removeHelperTextSpace}
        required={props.required}
        startAdornment={props.startAdornment}
        type={props.type || 'text'}
        value={props.value}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          event.preventDefault()
          onBlur({
            [props.propertyName]: event.target.value
          })
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault()
          onChange({
            [props.propertyName]: event.target.value
          })
        }}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
          event.preventDefault()
          onFocus({
            [props.propertyName]: event.target.value
          })
        }}
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
          onKeyUp(event)
        }}
      />
    </div>
  )
}

export default TextInput
