// ---------------------------------- */
// GA4 ecommerce events
// ---------------------------------- */

// typescript definition
declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[]
  }
}

import type { Checkout } from '@/types/checkout'
import deepmerge from 'deepmerge'
import reactStore, { RootState } from '@/react/store'

type events =
  | 'begin_checkout'
  | 'add_shipping_info'
  | 'purchase'
  // below events occur in CCO atm
  | 'add_payment_info'
  // below events aren't part of standard GA4 spec
  | 'cco_contact'
  | 'cco_shipping'
  | 'cco_payment'
  | 'cco_review'

const ga4DatalayerEvent = (event: events, payload?: any) => {
  window.dataLayer?.push({
    event,
    ecommerce: payload
  })
}

const should_fire_event = (checkout: Checkout) =>
  !!checkout.configuration?.affiliation
true

const push = (eventFunc: (state: RootState) => void) => {
  const state = reactStore.getState()
  if (should_fire_event(state.checkout)) {
    eventFunc(state)
  }
}

const ga4_begin_checkout = () =>
  push(({ checkout }: RootState) =>
    ga4DatalayerEvent('begin_checkout', ecommerceBase(checkout))
  )

const ga4_add_shipping_info = () => {
  push(({ checkout }: RootState) =>
    ga4DatalayerEvent(
      'add_shipping_info',
      deepmerge(ecommerceBase(checkout), {
        shipping_tier: checkout?.delivery?.name
      })
    )
  )
}

const ga4_add_payment_info = () => {
  push(({ checkout, payment }: RootState) =>
    ga4DatalayerEvent(
      'add_payment_info',
      deepmerge(ecommerceBase(checkout), {
        payment_type: payment.method
      })
    )
  )
}

const ga4_purchase = () => {
  // ga4DatalayerEvent(
  //   'purchase',
  //   deepmerge(ecommerceBase(checkout), {
  //     tax: checkout.pricing?.tax,
  //     shipping: checkout.pricing?.shipping,
  //     transaction_id: checkout.payment?.mint_payment_id
  //   })
  // )
}

// The below events are page view not part of standard GA ecommerce events.

const ga4_contact_page_view = () => {
  push(() => ga4DatalayerEvent('cco_contact'))
}

const ga4_shipping_page_view = () => {
  push(() => ga4DatalayerEvent('cco_shipping'))
}

const ga4_payment_page_view = () => {
  push(() => ga4DatalayerEvent('cco_payment'))
}

const ga4_review_page_view = () => {
  push(() => ga4DatalayerEvent('cco_review'))
}

const ecommerceBase = (checkout: Checkout) => {
  const items = checkout?.items?.map((item) => ({
    item_id: item.sku,
    item_name: item.name,
    affiliation: checkout.configuration?.affiliation,
    // we dont have coupons associated with items
    // coupon: 'SUMMER_FUN',
    // we dont have this info in CCO
    item_brand: item.item_brand,
    item_category: item.item_category,
    item_category2: item.item_category2,
    item_category3: item.item_category3,
    item_variant: item.item_variant,
    price: item.total_amount,
    quantity: item.quantity
  }))
  return {
    currency: 'USD',
    value: checkout.pricing?.amount,
    // we should treat it as single voucher
    coupon: checkout.vouchers?.[0]?.code,
    items
  }
}

export {
  ga4DatalayerEvent,
  ga4_begin_checkout,
  ga4_add_shipping_info,
  ga4_add_payment_info,
  ga4_purchase,
  ga4_contact_page_view,
  ga4_shipping_page_view,
  ga4_payment_page_view,
  ga4_review_page_view
}
