import React, { useCallback, useEffect, useState } from 'react'
import { batch } from 'react-redux'
import { useAppSelector, useAppDispatch, useResponse } from '@/react/hooks'
import {
  selectCanNavigate,
  selectSameAsShipping,
  selectIsPaymentWalletMethod,
  selectShowBillingAddress,
  selectIsCreditCard,
  selectIsPurchaseOrder,
  selectUseBillingForTax,
  selectTaxFieldsReadonly,
  selectShippingDisabled,
  selectIsSelectableShipping
} from '@/react/selectors'
import { useNavigate } from 'react-router-dom'
import size from 'lodash/size'

import OrderSummarySidebar from '@/react/features/checkout/OrderSummarySidebar'
import PaymentMethodPicker from '@/react/features/checkout/PaymentMethodPicker'
import {
  setBillingAddress,
  setBillingAddressStatus,
  setCheckoutStatus
} from '@/react/features/checkout/checkoutSlice'
import { clearFlash } from '@/react/features/checkout/flashSlice'
import {
  setPayment,
  setVerifyPayment
} from '@/react/features/checkout/paymentSlice'
import { setPurchaseOrderStatus } from '@/react/features/checkout/purchaseOrderSlice'
import {
  clearBillingErrors,
  setBillingErrors,
  setPaymentErrors,
  setPurchaseOrderErrors
} from '@/react/features/checkout/errorsSlice'
import { useValidateBillingAddressMutation } from '@/react/services/checkout_api'

import AddressForm from '@/react/components/AddressForm'
import PhoneInput from '@/react/components/PhoneInput'
import Checkbox from '@/react/components/Checkbox'
import SubmitButton from '@/react/components/SubmitButton'
import Title from '@/react/components/Title'
import CreditCardInput from '@/react/features/checkout/CreditCardInput'
import PurchaseOrderForm from '@/react/features/checkout/PurchaseOrderForm'

import { validatePayment } from '@/react/services/payment_validation'
import { validatePurchaseOrder } from '@/react/services/purchase_order_validation'
import {
  validateBillingAddress,
  validateAddressField
} from '@/react/services/address_validation'

import type {
  Address,
  AddressError,
  BillingAddress,
  BillingAddressStatus
} from '@/types/address'
import type { PaymentError } from '@/types/payment'
import { Subset } from '@/types/generic'
import { isConus, includesTaxField } from '@/react/services/addressHelpers'
import FormGroup from '@/react/components/FormGroup'
import { ga4_add_payment_info } from '@/services/GA4'

import AnalyticsService from '@/services/AnalyticsService'

export default function Payment() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const payment = useAppSelector((state) => {
    return state.payment
  })
  const purchaseOrder = useAppSelector((state) => {
    return state.purchase_order
  })
  const billingAddress = useAppSelector((state) => {
    return state.checkout.billing_address
  })
  const billingErrors = useAppSelector((state) => {
    return state.errors.billing_address
  })
  const purchaseOrderErrors = useAppSelector((state) => {
    return state.errors.purchase_order
  })
  const status = useAppSelector((state) => {
    return state.status
  })

  const sameAsShipping = useAppSelector(selectSameAsShipping)
  const isPaymentWalletMethod = useAppSelector(selectIsPaymentWalletMethod)
  const showBilling = useAppSelector(selectShowBillingAddress)
  const isCreditCard = useAppSelector(selectIsCreditCard)
  const isPurchaseOrder = useAppSelector(selectIsPurchaseOrder)
  const canNavigateToReview = useAppSelector(selectCanNavigate)['/review']
  const useBillingForTax = useAppSelector(selectUseBillingForTax)
  const taxFieldsReadonly = useAppSelector(selectTaxFieldsReadonly)
  const shippingDisabled = useAppSelector(selectShippingDisabled)

  const isSelectableShipping = useAppSelector(selectIsSelectableShipping)

  const hideSameAsShippingCheckbox = shippingDisabled || isSelectableShipping

  const [creditCardErrors, setCreditCardErrors] = useState<PaymentError>({})
  const [shouldTokenizeCard, setShouldTokenizeCard] = useState<boolean>(false)
  const [tokenized, setTokenized] = useState<boolean>(false)
  const [validateAddress, validationResponse] =
    useValidateBillingAddressMutation()

  const hasErrors = (errors?: { [key: string]: any }) =>
    errors && Object.values(errors).filter((error) => !!error).length > 0

  const disableSubmit =
    hasErrors(billingErrors) ||
    (isCreditCard && hasErrors(creditCardErrors)) ||
    (isPurchaseOrder && hasErrors(purchaseOrderErrors)) ||
    validationResponse.isLoading

  const [goToReview, setGoToReview] = useState<boolean>(false)

  useEffect(() => {
    if (shippingDisabled) {
      dispatch(
        setBillingAddressStatus({ same_as_shipping: false, validated: false })
      )
    }
  }, [dispatch, shippingDisabled])

  // finally mark valid payment as verified and proceed to review.
  const handleVerification = useCallback(
    (options?: {
      billingAddressValidated?: boolean
      purchaseOrderValid?: boolean
    }) => {
      const billingValidated =
        !showBilling ||
        billingAddress?.validated ||
        options?.billingAddressValidated
      const poValid =
        !isPurchaseOrder || purchaseOrder.valid || options?.purchaseOrderValid
      // tokenization happens in the CreditCardInput component
      const cardTokenized = !isCreditCard || tokenized

      if (billingValidated && cardTokenized && poValid && !payment.verified) {
        dispatch(setVerifyPayment(true))
        setGoToReview(true)
      }
    },
    [
      billingAddress?.validated,
      dispatch,
      isCreditCard,
      isPurchaseOrder,
      tokenized,
      payment.verified,
      purchaseOrder.valid,
      showBilling
    ]
  )

  // validate billing address and handle tokenization/payment validation.
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (canNavigateToReview) {
      setGoToReview(true)
      return
    }

    if (showBilling) {
      handleBillingAddressValidation()
    }

    // if CC, we tokenize, which will trigger validation later.
    // otherwise, go straight to validaton.
    if (isCreditCard) {
      setShouldTokenizeCard(true)
    } else if (isPurchaseOrder) {
      handlePurchaseOrderValidation()
    } else {
      handlePaymentValidation()
    }
  }

  const handlePaymentValidation = useCallback(() => {
    const { errors, valid } = validatePayment({ data: payment })

    if (valid) {
      AnalyticsService.trackEvent(
        null,
        'submit-payment'
        // 'certification-success'
      )
      handleVerification()
    } else {
      AnalyticsService.trackEvent(null, 'submit-payment', 'validation-failure')
      dispatch(setPaymentErrors(errors))
    }
  }, [dispatch, handleVerification, payment])

  const handlePurchaseOrderValidation = useCallback(() => {
    const { errors, valid } = validatePurchaseOrder({ data: purchaseOrder })

    if (valid) {
      dispatch(setPurchaseOrderStatus({ valid: true }))
      AnalyticsService.trackEvent(
        null,
        'submit-payment'
        // 'certification-success'
      )
      handleVerification({ purchaseOrderValid: true })
    } else {
      AnalyticsService.trackEvent(null, 'submit-payment', 'validation-failure')
      dispatch(setPurchaseOrderErrors(errors))
    }
  }, [dispatch, handleVerification, purchaseOrder])

  const handleBillingAddressValidation = () => {
    const { errors, valid } = validateBillingAddress({
      data: billingAddress
    })

    if (errors && size(errors) > 0) {
      dispatch(setBillingErrors(errors))
    }

    if (valid) {
      validateAddress(billingAddress || ({} as BillingAddress))
    }
  }

  const handleChangeBillingAddress = (address: Subset<Address>) => {
    let key: keyof Address
    const errors: AddressError = {}
    for (key in address) {
      errors[key] = undefined
    }

    if (
      billingAddress?.country &&
      address?.country &&
      isConus(billingAddress.country) &&
      !isConus(address.country)
    ) {
      errors['postal_code'] = undefined
      errors['state'] = undefined
    }

    batch(() => {
      dispatch(setBillingAddress(address))
      dispatch(setBillingAddressStatus({ validated: false }))
      dispatch(setVerifyPayment(false))
      dispatch(setBillingErrors(errors))
      if (includesTaxField(address) && useBillingForTax) {
        dispatch(setCheckoutStatus({ stale_quote: true }))
      }
    })
  }

  const handleFormBlur = (data: Subset<Address>) => {
    let key: keyof Address
    for (key in data) {
      const options = {
        data: {
          [key]: data[key]
        }
      }
      if (key === 'phone_number') {
        options.data.country = billingAddress?.country
      }
      const errors = validateAddressField(options)
      if (errors.length) {
        dispatch(setBillingErrors({ [key]: errors.join(', ') }))
      }
    }
  }

  const handleCheck = (data: Subset<BillingAddressStatus>) => {
    if (data.same_as_shipping === true) {
      batch(() => {
        dispatch(clearBillingErrors())
        dispatch(setBillingAddressStatus(data))
      })
    } else {
      batch(() => {
        dispatch(clearBillingErrors())
        dispatch(setBillingAddressStatus({ ...data, validated: false }))
        dispatch(setVerifyPayment(false))
      })
    }
  }

  // for navigating to next step after submit.
  useEffect(() => {
    if (goToReview) {
      setGoToReview(false)
      dispatch(clearFlash())
      ga4_add_payment_info()
      navigate('../review')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToReview])

  // if the backend regards the billing address as valid,
  // mark validated and attempt to certify payment/proceed to review
  useResponse(validationResponse, (response) => {
    // if the billing address is validated, validate payment
    // and attempt to proceed to the next step.
    if (response.valid) {
      AnalyticsService.trackEvent(
        null,
        'submit-billing',
        'certification-success'
      )
      dispatch(setBillingAddressStatus({ validated: true }))
      handleVerification({ billingAddressValidated: true })
    } else {
      AnalyticsService.trackEvent(null, 'submit-billing', 'validation-failure')
      if (size(response.errors) > 0) {
        dispatch(setBillingErrors(response.errors))
      }
    }
  })

  // once tokenizable methods (cc) are tokenized, validate payment.
  // required for moving to the review page.
  useEffect(() => {
    if (tokenized) {
      handlePaymentValidation()
    }
    // handlePaymentValidation updates everytime the payment updates
    // we do NOT want to run this hook every time the payment updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenized])

  return (
    <div
      className={`CheckoutViewContainer container${
        status.showPayment ? ' flex' : ' hidden'
      }`}
      data-testid="PaymentPage"
    >
      <div className="lg:w-1/2 lg:p-12 lg:pl-8 w-full p-4 mx-auto">
        <Title>Payment Method</Title>
        <PaymentMethodPicker
          creditCardForm={
            <CreditCardInput
              cardType={payment.card_type}
              setErrors={useCallback((data) => setCreditCardErrors(data), [])}
              shouldTokenize={shouldTokenizeCard}
              setShouldTokenize={useCallback(
                (data) => setShouldTokenizeCard(data),
                []
              )}
              setTokenized={useCallback((data) => setTokenized(data), [])}
              setPayment={useCallback(
                (data) => {
                  dispatch(setPayment(data))
                },
                [dispatch]
              )}
              setVerifyPayment={useCallback(
                (data) => {
                  dispatch(setVerifyPayment(data))
                },
                [dispatch]
              )}
            />
          }
          purchaseOrderForm={<PurchaseOrderForm />}
        />
        {!isPaymentWalletMethod ? (
          <>
            <Title>Billing Address</Title>
            <div className="mb-6">
              {hideSameAsShippingCheckbox ? null : (
                <Checkbox
                  disabled={false}
                  label={'Same as shipping address'}
                  checked={sameAsShipping}
                  onChange={handleCheck}
                  propertyName="same_as_shipping"
                ></Checkbox>
              )}
              {showBilling ? (
                <div className="mt-6" data-testid="paymentBillingAddressForm">
                  <AddressForm
                    address={billingAddress}
                    errors={billingErrors}
                    handleChange={handleChangeBillingAddress}
                    handleBlur={handleFormBlur}
                    taxFieldsReadonly={taxFieldsReadonly}
                  ></AddressForm>
                  <FormGroup className="lg:flex-nowrap flex-wrap">
                    <PhoneInput
                      countryCode={billingAddress?.country}
                      errorMessage={billingErrors?.phone_number}
                      phoneNumber={billingAddress?.phone_number || ''}
                      onBlur={handleFormBlur}
                      onChange={handleChangeBillingAddress}
                    />
                  </FormGroup>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        <SubmitButton
          disabled={disableSubmit}
          onClick={handleSubmit}
          testId="submitPaymentButton"
          value={'Continue to Order Review'}
        />
      </div>
      <OrderSummarySidebar />
    </div>
  )
}
