import React from 'react'

interface Props {
  className?: string
  name: string
  size?: string
}

export default function Icon(props: Props) {
  const fontSize = props.size ? props.size + 'px' : ''
  return (
    <i
      className={`Icon icon icon-${props.name} ${
        props.className ? props.className : ''
      }`}
      style={{ fontSize: fontSize }}
    ></i>
  )
}
