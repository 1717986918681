import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type FlashState = {
  type: string
  message: string
  detail?: string
} | null

const initialState = null as FlashState

const flashSlice = createSlice({
  name: 'flash',
  initialState,
  reducers: {
    setFlash: (_state: FlashState, action: PayloadAction<FlashState>) =>
      action.payload,
    clearFlash: (_state: FlashState) => null
  }
})

export const { clearFlash, setFlash } = flashSlice.actions
export default flashSlice.reducer
