import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PurchaseOrder,
  PurchaseOrderField,
  PurchaseOrderStatus
} from '@/types/purchaseOrder'
import { PurchaseOrderUpload } from '@/types/purchaseOrderUpload'
import { Subset } from '@/types/generic'

const initialState = {} as PurchaseOrder

const purchaseOrderSlice = createSlice({
  name: 'purchase_order',
  initialState,
  reducers: {
    replacePurchaseOrder: (
      _state: PurchaseOrder,
      action: PayloadAction<PurchaseOrder>
    ) => action.payload,
    setPurchaseOrder: (
      state: PurchaseOrder,
      action: PayloadAction<Subset<PurchaseOrderField>>
    ) => ({
      ...state,
      ...action.payload
    }),
    setPurchaseOrderStatus: (
      state: PurchaseOrder,
      action: PayloadAction<Subset<PurchaseOrderStatus>>
    ) => ({
      ...state,
      ...action.payload
    }),
    replacePurchaseOrderUpload: (
      state: PurchaseOrder,
      action: PayloadAction<PurchaseOrderUpload>
    ) => ({
      ...state,
      purchase_order_upload: action.payload
    })
  }
})

export const {
  replacePurchaseOrder,
  setPurchaseOrder,
  setPurchaseOrderStatus,
  replacePurchaseOrderUpload
} = purchaseOrderSlice.actions
export default purchaseOrderSlice.reducer
