import Rollbar from 'rollbar'
import config from '@/config'

export const isLogArgument = (logArgument: any) =>
  typeof logArgument === 'undefined' ||
  typeof logArgument === 'string' ||
  typeof logArgument === 'object' ||
  typeof logArgument === 'function'

const isEnabled = !['development', 'test'].includes(config.rails_env)

const rollbar = Rollbar.init({
  accessToken: config.rollbar_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  hostSafeList: ['customink.com'],
  environment: config.rails_env,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.code_version,
        guess_uncaught_frames: true
      }
    }
  }
})

const getProxy = () => {
  const methods = [
    'critical',
    'debug',
    'error',
    'info',
    'log',
    'warn',
    'warning'
  ] as const

  const handler = {
    get(_target: any, logMethod: typeof methods[number]) {
      if (!methods.includes(logMethod)) {
        return
      }
      return (message?: string, details?: any) => {
        const options = [
          `%c rollbar ${logMethod}`,
          'color: gray; font-weight: lighter;',
          message
        ]
        if (details) {
          options.push(details)
        }
        console.log(...options)
      }
    }
  }

  return new Proxy(Rollbar, handler)
}

export default isEnabled ? rollbar : getProxy()
