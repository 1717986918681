import React from 'react'
import { Routes, Route, Navigate, PathRouteProps } from 'react-router-dom'
import Shipping from '@/react/features/checkout/Shipping'
import ShowPayment from '@/react/features/checkout/ShowPayment'
import Review from '@/react/features/checkout/Review'
import Complete from '@/react/features/checkout/Complete'
import Contact from '@/react/features/checkout/Contact'
import { useAppSelector } from '@/react/hooks'
import {
  selectFarthestEligibleStage,
  selectCanNavigate
} from '@/react/selectors'

export default function CheckoutRoutes() {
  const farthestEligibleStage = useAppSelector(selectFarthestEligibleStage)
  const canNavigate = useAppSelector(selectCanNavigate)

  const guardRoute = (
    path: keyof typeof canNavigate,
    page: JSX.Element
  ): PathRouteProps => ({
    path,
    element: canNavigate[path] ? (
      page
    ) : (
      <Navigate to={farthestEligibleStage} replace={true} />
    )
  })

  return (
    <>
      <Routes>
        <Route
          path="*"
          element={<Navigate to={farthestEligibleStage} replace={true} />}
        />
        <Route {...guardRoute('/contact', <Contact />)} />
        <Route {...guardRoute('/shipping', <Shipping />)} />
        <Route {...guardRoute('/payment', <ShowPayment />)} />
        <Route {...guardRoute('/review', <Review />)} />
        <Route {...guardRoute('/complete', <Complete />)} />
      </Routes>
    </>
  )
}
