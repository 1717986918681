import React from 'react'

interface Props {
  className?: string
  children: any
}

const FormGroup = (props: Props) => {
  return <div className={`flex ${props.className || ''}`}>{props.children}</div>
}

export default FormGroup
