import { isIos } from '@/react/utils/device'

const braintreeInputStyles = {
  'input': {
    'color': 'rgba(0,0,0,0.87)',
    'font-size': isIos ? '16px' : '14px',
    'line-height': '20px',
    'padding': '10px 12px 10px 46px'
  },
  'input.isInvalid': {
    'color': '#da1e28'
  },
  '::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder':
    {
      'color': 'rgba(0,0,0,0.25)'
    }
}

export default braintreeInputStyles
