import React, { useEffect } from 'react'
import { batch } from 'react-redux'
import {
  useAppDispatch,
  useAppSelector,
  useErrorResponse,
  useResponse
} from '@/react/hooks'
import {
  selectAmount,
  selectSameAsShipping,
  selectShowBillingAddress,
  selectIsPaymentWalletMethod,
  selectIsPurchaseOrder,
  selectIsApplePay,
  selectIsPayPal,
  selectPaymentDisabled,
  selectShippingDisabled,
  selectSelectedShippingOptions
} from '@/react/selectors'
import { useNavigate, NavLink } from 'react-router-dom'

import { useCompleteCheckoutMutation } from '@/react/services/checkout_api'

import { clearFlash, setFlash } from '@/react/features/checkout/flashSlice'
import { setCheckoutStatus } from '@/react/features/checkout/checkoutSlice'
import { setVerifyPayment } from '@/react/features/checkout/paymentSlice'

import OrderSummary from '@/react/features/checkout/OrderSummary'
import OrderSummarySidebar from '@/react/features/checkout/OrderSummarySidebar'
import ApplePayButton from '@/react/features/checkout/ApplePayButton'
import PayPalButton from '@/react/features/checkout/PayPalButton'

import AddressBlock from '@/react/components/AddressBlock'
import ActivityIndicator from '@/react/components/ActivityIndicator'
import PaymentMethodPreview from '@/react/components/PaymentMethodPreview'
import SubmitButton from '@/react/components/SubmitButton'
import Title from '@/react/components/Title'

import AnalyticsService from '@/services/AnalyticsService'

import { ApiErrorResponse } from '@/types/api'
import { Payment } from '@/types/payment'
import { ShippingAddress } from '@/types/address'
import { ga4_review_page_view } from '@/services/GA4'

export default function Review() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const checkout = useAppSelector((state) => state.checkout)
  const payment = useAppSelector((state) => state.payment)
  const purchaseOrder = useAppSelector((state) => state.purchase_order)
  const amount = useAppSelector(selectAmount)
  const user = useAppSelector((state) => state.user)
  const email: string | undefined = useAppSelector(
    (state) => state.checkout.contact?.email
  )
  const sameAsShipping = useAppSelector(selectSameAsShipping)
  const showBilling = useAppSelector(selectShowBillingAddress)

  const selectedShippingAddress = useAppSelector(selectSelectedShippingOptions)

  const shippingAddress = useAppSelector(
    (state) => state.checkout.shipping_address
  )
  const billingAddress = useAppSelector(
    (state) => state.checkout.billing_address
  )
  const isPurchaseOrder = useAppSelector(selectIsPurchaseOrder)
  const isApplePay = useAppSelector(selectIsApplePay)
  const isPayPal = useAppSelector(selectIsPayPal)
  const isPaymentWalletMethod = useAppSelector(selectIsPaymentWalletMethod)
  const hideShippingAddress = useAppSelector(selectShippingDisabled)
  const paymentDisabled = useAppSelector(selectPaymentDisabled)
  const displayAsCombined: boolean = sameAsShipping && !isPaymentWalletMethod
  const pageTitle = `Shipping${displayAsCombined ? ' & Billing' : ''} Address`

  const [completeCheckout, completeCheckoutResponse] =
    useCompleteCheckoutMutation()

  useEffect(() => {
    ga4_review_page_view()
  }, [])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    placeOrder(payment)
  }

  const placeOrder = (payment: Payment) => {
    dispatch(clearFlash())

    const paymentData = isPurchaseOrder
      ? {
          ...payment,
          payment_method_nonce: 'purchase_order',
          purchase_order: purchaseOrder
        }
      : payment

    completeCheckout({ ...checkout, payment: paymentData, user: user })
  }

  useResponse(completeCheckoutResponse, () => {
    dispatch(setCheckoutStatus({ paid: true }))
    AnalyticsService.trackEvent(null, 'place-order', 'success', amount)
    navigate('../complete')
  })

  useErrorResponse(completeCheckoutResponse, (error: ApiErrorResponse) => {
    const isPaymentError = error.data?.message?.includes('PaymentError')
    const flashMessage = isPaymentError
      ? 'There was a problem while processing your payment.'
      : "We've encountered an error while processing your order."
    const analyticsLabel = isPaymentError
      ? 'process-payment-failure'
      : 'process-order-failure'

    AnalyticsService.trackEvent(null, 'process-order', analyticsLabel)

    batch(() => {
      dispatch(setVerifyPayment(false))
      dispatch(
        setFlash({
          type: 'error',
          message: flashMessage,
          detail: error.data?.detail
        })
      )
    })
  })

  const renderSubmitButton = () => {
    if (isApplePay) {
      return (
        <div className="w-full" data-testid="payByApplePayButton">
          <ApplePayButton handlePlaceOrder={placeOrder} />
        </div>
      )
    } else if (isPayPal) {
      return (
        <div className="w-full">
          <PayPalButton completeCheckout={completeCheckout} />
        </div>
      )
    } else {
      return (
        <SubmitButton
          testId="placeOrderButton"
          value={isPurchaseOrder ? 'Submit Purchase Order' : 'Place Order'}
          onClick={(event) => {
            handleSubmit(event)
          }}
        />
      )
    }
  }

  const renderShippingTitle = (shippingAddress?: Partial<ShippingAddress>) => {
    if (!shippingAddress) {
      return
    }
    return (
      shippingAddress?.title && (
        <div className="mb-8">
          <div className="flex items-center mb-4">
            <Title className="mb-0">{pageTitle}</Title>
          </div>
          <div className="p-6 border rounded">
            <b>{shippingAddress?.title}</b>
          </div>
        </div>
      )
    )
  }

  const zeroAmountCheckout = amount === '0'
  const activityDescription =
    paymentDisabled || zeroAmountCheckout
      ? 'Placing your order...'
      : 'Processing your payment...'

  return (
    <>
      {completeCheckoutResponse.isLoading ? (
        <ActivityIndicator label={activityDescription} />
      ) : (
        <div
          className="CheckoutViewContainer container flex"
          data-testid="ReviewPage"
        >
          <div className="lg:w-1/2 lg:p-12 lg:pl-8 w-full p-4 mx-auto">
            <form onSubmit={handleSubmit}>
              {(hideShippingAddress && shippingAddress) ||
              selectedShippingAddress ? (
                renderShippingTitle(selectedShippingAddress || shippingAddress)
              ) : (
                <div className="mb-8">
                  <div className="flex items-center mb-4">
                    <Title className="mb-0">{pageTitle}</Title>
                    <NavLink
                      to={'/shipping'}
                      className="link lg:hidden ml-auto text-lg"
                    >
                      Edit
                    </NavLink>
                  </div>
                  <div className="p-6 border rounded">
                    <AddressBlock
                      title={shippingAddress?.title}
                      address={shippingAddress}
                      email={email}
                      contact={checkout?.contact}
                    ></AddressBlock>
                  </div>
                </div>
              )}

              {showBilling && !displayAsCombined && (
                <div className="mb-8">
                  <div className="flex items-center mb-4">
                    <Title className="mb-0">Billing Address</Title>
                    <NavLink
                      to={'/payment'}
                      className="link lg:hidden ml-auto text-lg"
                    >
                      Edit
                    </NavLink>
                  </div>
                  <div className="p-6 border rounded">
                    <AddressBlock
                      address={billingAddress}
                      email={email}
                      contact={checkout?.contact}
                    ></AddressBlock>
                  </div>
                </div>
              )}

              {!paymentDisabled && (
                <div className="mb-8">
                  <div className="flex items-center mb-4">
                    <Title className="mb-0">Payment Method</Title>
                    <NavLink
                      to={'/payment'}
                      className="link lg:hidden ml-auto text-lg"
                    >
                      Edit
                    </NavLink>
                  </div>
                  <div className="px-6 py-4 border rounded">
                    <PaymentMethodPreview
                      payment={payment}
                    ></PaymentMethodPreview>
                  </div>
                </div>
              )}

              <div className="lg:hidden mb-8">
                <OrderSummary />
              </div>

              {renderSubmitButton()}
            </form>
          </div>
          <OrderSummarySidebar />
        </div>
      )}
    </>
  )
}
