import React from 'react'
import classNames from 'classnames'
import { hasFlag } from 'country-flag-icons'
import 'country-flag-icons/3x2/flags.css'

export default function FlagIcon(props: {
  country?: string
  isFocused?: boolean
}) {
  const countryCode = props.country?.toUpperCase()
  const flagClass = countryCode && hasFlag(countryCode) ? countryCode : 'XX'

  return (
    <span
      className={classNames('FlagIcon', `flag:${flagClass}`, {
        'isFocused': props.isFocused
      })}
      data-testid="FlagIcon"
    />
  )
}
