import { parsePhoneNumber } from 'awesome-phonenumber'
import EMAIL_REGEXP from '@/constants/emailRegexp'
import includes from 'lodash/includes'
import type {
  RulesValidator,
  Validator,
  ValidationError
} from '@/types/validation'
import { AddressValue } from '@/types/address'
import { ContactValue } from '@/types/contact'
import { PaymentValue, PaymentMethod } from '@/types/payment'
import { PurchaseOrderValue } from '@/types/purchaseOrder'
import { PurchaseOrderUploadValue } from '@/types/purchaseOrderUpload'
import { isConus } from '@/react/services/addressHelpers'

type RulesFor = AddressValue | ContactValue | PaymentValue | PurchaseOrderValue

const validators: RulesValidator<RulesFor> = {
  presence: ({ error, value }) => {
    const isValid = !!value
    return isValid ? [] : [error ? error : 'value is missing']
  },
  minLength: ({ error, min, value }) => {
    const isValid = !value || (!!min && !(String(value).length < min))
    return isValid ? [] : [error ? error : 'value is too short']
  },
  maxLength: ({ error, max, value }) => {
    const isValid = !value || (!!max && !(String(value).length > max))
    return isValid ? [] : [error ? error : 'value is too long']
  },
  phoneFormat: ({ error, value, country }) => {
    const isValid =
      !value ||
      (typeof value === 'string' &&
        (parsePhoneNumber(value, country).isValid() ||
          parsePhoneNumber(value, 'US').isValid() ||
          parsePhoneNumber(value).isValid()))
    return isValid ? [] : [error ? error : 'unable to verify phone number']
  },
  emailFormat: ({ error, value }) => {
    const isValid =
      !value || (typeof value === 'string' && EMAIL_REGEXP.test(value))
    return isValid ? [] : [error ? error : 'invalid email format']
  },
  paymentType: ({ error, set, value }) => {
    const isValid = !value || includes(set, value)
    return isValid ? [] : [error ? error : 'invalid payment type']
  },
  fileType: ({ error, set, value }) => {
    const isValid =
      !value || (value instanceof File && includes(set, value.type))
    return isValid ? [] : [error ? error : 'invalid file type']
  },
  fileSize: ({ error, max, value }) => {
    const isValid =
      !value ||
      (value instanceof File && !!max && value.size <= max * 1024 * 1024)
    return isValid ? [] : [error ? error : 'file size too large']
  },
  isChecked: ({ error, value }) => {
    const isValid = value === true
    return isValid ? [] : [error ? error : 'required']
  }
}

export const invalidData = (data?: any): boolean =>
  !data || typeof data !== 'object'

export const doNotValidate = (): Validator<any> => () => []

export const validatePresence =
  (): Validator<string> =>
  ({ value }) => {
    return [...validators.presence({ value })]
  }

export const validatePhoneNumber =
  ({ max }: { max: number }): Validator<AddressValue | ContactValue> =>
  ({ value, required, country }) => {
    const presence = required
      ? validators.presence({ error: 'Enter your phone number', value })
      : []
    return [
      ...presence,
      ...validators.phoneFormat({
        error: 'Invalid phone number format',
        value,
        country
      }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateFirstName =
  ({ max }: { max: number }): Validator<AddressValue | ContactValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Enter your first name', value }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateLastName =
  ({ max }: { max: number }): Validator<AddressValue | ContactValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Enter your last name', value }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateEmailAddress =
  ({
    errors,
    alwaysRequired
  }: {
    errors?: ValidationError
    alwaysRequired?: boolean
  }): Validator<ContactValue | PurchaseOrderValue> =>
  ({ value, required }) => {
    const presence =
      required || alwaysRequired
        ? validators.presence({
            error: errors?.presence ? errors.presence : 'Enter your email',
            value
          })
        : []
    return [
      ...presence,
      ...validators.emailFormat({ error: 'Please enter a valid email', value })
    ]
  }

export const validateOrganization =
  ({ max }: { max: number }): Validator<AddressValue> =>
  ({ value }) => {
    return [
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateAddress1 =
  ({ min, max }: { min: number; max: number }): Validator<AddressValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Enter your street address', value }),
      ...validators.minLength({
        error: `Minimum length is ${min} chars`,
        min,
        value
      }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateAddress2 =
  ({ max }: { max: number }): Validator<AddressValue> =>
  ({ value }) => {
    return [
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateCity =
  ({ max }: { max: number }): Validator<AddressValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Enter your city', value }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validatePostalCode =
  ({ min, max }: { min: number; max: number }): Validator<AddressValue> =>
  ({ value, country }) => {
    const conusValidators = isConus(country)
      ? [
          ...validators.presence({ error: 'Enter your postal code', value }),
          ...validators.minLength({
            error: `Minimum length is ${min} chars`,
            min,
            value
          })
        ]
      : []
    return [
      ...conusValidators,
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateState =
  ({ max }: { max: number }): Validator<AddressValue> =>
  ({ value, country }) => {
    const presence = isConus(country)
      ? validators.presence({ error: 'Select your state', value })
      : []
    return [
      ...presence,
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateCountry =
  (): Validator<AddressValue> =>
  ({ value }) => {
    return [...validators.presence({ error: 'Select your country', value })]
  }

export const validateContactField =
  ({ max }: { max: number }): Validator<PurchaseOrderValue> =>
  ({ value }) => {
    return [
      ...validators.presence({
        error: 'Provide your name of department',
        value
      }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateInstitution =
  ({ max }: { max: number }): Validator<PurchaseOrderValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Provide your institution', value }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validatePONumber =
  ({ max }: { max: number }): Validator<PurchaseOrderValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Provide your PO number', value }),
      ...validators.maxLength({
        error: `Maximum length is ${max} chars`,
        max,
        value
      })
    ]
  }

export const validateIsChecked =
  (): Validator<PurchaseOrderValue> =>
  ({ value }) => {
    return [...validators.isChecked({ error: '*Required', value })]
  }

export const validateFile =
  ({
    max,
    set
  }: {
    max: number
    set: Array<string>
  }): Validator<PurchaseOrderValue> =>
  ({ value }) => {
    return [
      ...validators.presence({ error: 'Upload PO File', value }),
      ...validators.fileType({
        error: 'File type not supported',
        value,
        set
      }),
      ...validators.fileSize({
        error: `The file size limit is ${max} mb, please email service@customink.com or call 866-779-3570 if your PO exceeds this limit.`,
        max,
        value
      })
    ]
  }

export const validateUpload =
  (): Validator<PurchaseOrderUploadValue> =>
  ({ value }) => {
    return [...validators.presence({ error: 'Upload unsuccessful', value })]
  }

export const validatePaymentMethod =
  (): Validator<PaymentValue> =>
  ({ value }) => {
    const paymentMethods: Array<PaymentMethod> = [
      'applepay',
      'cc',
      'paypal',
      'purchase_order'
    ]
    return [
      ...validators.presence({ value, error: 'missing payment method' }),
      ...validators.paymentType({
        value,
        set: paymentMethods,
        error: 'invalid payment method'
      })
    ]
  }

export const validateCreditCardId =
  (): Validator<PaymentValue> =>
  ({ value, required }) =>
    required ? validators.presence({ value }) : []

export const validatePaymentMethodNonce =
  (): Validator<PaymentValue> =>
  ({ value, required }) =>
    required ? validators.presence({ value }) : []

export const validatePurchaseOrderField =
  (): Validator<PaymentValue> =>
  ({ value }) => {
    return [...validators.presence({ value })]
  }
