import config from '@/config'
import { FeatureFlag } from '@/types/config'

const selectFeatureFlag = (flagName: string): FeatureFlag => {
  const foundFlag = config.feature_flags.find(
    (ff: FeatureFlag) => ff.name === flagName
  )
  return foundFlag || ({ name: 'unknown_flag', enabled: false } as FeatureFlag)
}

export const voucherFormFlag = selectFeatureFlag('voucher_form')
export const auth0Flag = selectFeatureFlag('auth0')
export const disableAddSaveCardFlag = selectFeatureFlag('disable_add_save_cc')
