import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@customink/pigment-react'
import { useAppSelector } from '@/react/hooks'
import { selectPaid, selectCheckoutComplete } from '@/react/selectors'
import CustomInkLogo from '@/react/components/CustomInkLogo'
import headsetIcon from '@/assets/images/headset-icon.svg'
import { auth0Flag } from '@/react/services/feature_flags'

export default function SiteHeader() {
  const paid = useAppSelector(selectPaid)
  const checkoutComplete = useAppSelector(selectCheckoutComplete)
  const hideHeader = paid || checkoutComplete
  const { user, logout } = useAuth0()
  const auth0Enabled = auth0Flag.enabled

  function handleLogoutClick() {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <div
      className={classNames('SiteHeader lg:block flex-none hidden border-b', {
        'lg:hidden': hideHeader
      })}
      data-testid="SiteHeader"
    >
      <div className="lg:px-8 container flex items-center py-4">
        <Link to="/">
          <CustomInkLogo />
        </Link>

        {auth0Enabled && user && (
          <div className="flex-grow ml-auto mr-8 text-right">
            {user.email}
            <Button variant="link" onClick={handleLogoutClick}>
              Log Out
            </Button>
          </div>
        )}

        <div className="ml-auto">
          <div className="flex items-center">
            <img src={headsetIcon} className="h-6 mr-3" />
            <div>
              <div className="text-xs uppercase">Talk To A Real Person</div>

              <a href="tel:+17034343214" className="text-ci-red font-semibold">
                +1 703-434-3214
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
