import React, { useCallback, useEffect, useState } from 'react'
import { batch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { size } from 'lodash'
import {
  useAppSelector,
  useAppDispatch,
  useResponse,
  useErrorResponse
} from '@/react/hooks'
import {
  selectCanNavigate,
  selectHasShippingAddressErrors,
  selectHasContactErrors,
  selectPaymentDisabled,
  selectUseShippingForTax,
  selectHasSelectableShippingConfig,
  selectSelectedSelectableShippingIds,
  selectSelectableShippingOptions,
  selectSelectableShippingFormLocation,
  selectIsSelectableShipping
} from '@/react/selectors'
import {
  useQuoteCheckoutMutation,
  useUpdateCheckoutMutation,
  useValidateAddressMutation
} from '@/react/services/checkout_api'
import {
  setCheckoutStatus,
  setShippingAddress,
  setShippingAddressStatus
} from '@/react/features/checkout/checkoutSlice'
import { setStatus } from '@/react/features/checkout/statusSlice'
import {
  clearSuggestions,
  setSuggestions
} from '@/react/features/checkout/suggestionsSlice'
import {
  setShippingErrors,
  clearShippingErrors
} from '@/react/features/checkout/errorsSlice'
import DeliveryPreview from '@/react/features/checkout/DeliveryPreview'
import AddressForm from '@/react/components/AddressForm'
import SubmitButton from '@/react/components/SubmitButton'
import CertifyAddressModal from '@/react/features/checkout/CertifyAddressModal'
import { Address, AddressError } from '@/types/address'
import { Contact, ContactError } from '@/types/contact'
import AnalyticsService from '@/services/AnalyticsService'
import {
  validateAddressField,
  validateSelectableShipping,
  validateShippingAddress
} from '@/react/services/address_validation'
import { Checkout, AddressOption } from '@/types/checkout'
import { ApiErrorResponse } from '@/types/api'
import {
  includesTaxField,
  isConus,
  isConusAddress,
  isUsAddress
} from '@/react/services/addressHelpers'
import OrderSummarySidebar from '@/react/features/checkout/OrderSummarySidebar'
import { SelectableShipping } from '@/react/components/SelectableShipping'
import { ga4_shipping_page_view } from '@/services/GA4'

export default function Shipping() {
  const checkout = useAppSelector((state) => state.checkout)
  const errors = useAppSelector((state) => state.errors)
  const certified = useAppSelector(
    (state) => !!state.checkout.shipping_address?.certified
  )
  const canNavigateToPayment = useAppSelector(selectCanNavigate)['/payment']
  const canNavigateToReview = useAppSelector(selectCanNavigate)['/review']
  const hasShippingAddressErrors = useAppSelector(
    selectHasShippingAddressErrors
  )
  const isPaymentDisabled = useAppSelector(selectPaymentDisabled)
  const hasContactErrors = useAppSelector(selectHasContactErrors)
  const useShippingForTax = useAppSelector(selectUseShippingForTax)
  const useSelectableShipping = useAppSelector(
    selectHasSelectableShippingConfig
  )
  const hasSelectableShippingAddressForm = !!useAppSelector(
    selectSelectableShippingFormLocation
  )
  const selectableShippingIds = useAppSelector(
    selectSelectedSelectableShippingIds
  )
  const isSelectableShipping = useAppSelector(selectIsSelectableShipping)

  const selectableShippingOptions = useAppSelector(
    selectSelectableShippingOptions
  )
  const selectableShippingFormLocation = useAppSelector(
    selectSelectableShippingFormLocation
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [updateCheckout, updateResponse] = useUpdateCheckoutMutation()
  const [validateAddress, validationResponse] = useValidateAddressMutation()
  const [, quoteResponse] = useQuoteCheckoutMutation()

  useEffect(() => {
    const payload = {
      first_name:
        checkout.shipping_address?.first_name || checkout.contact.first_name,
      last_name:
        checkout.shipping_address?.last_name || checkout.contact.last_name
    }
    dispatch(setShippingAddress(payload))
    ga4_shipping_page_view()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = useCallback(
    (data: Partial<Address & Contact>) => {
      const errors: AddressError & ContactError = {}
      let key: keyof typeof data
      for (key in data) {
        errors[key] = undefined
      }

      // if we have swapped countries to one that potentially doesn't require
      // postal codes or states, unset those errors
      if (
        checkout.shipping_address?.country &&
        data?.country &&
        isConus(checkout.shipping_address.country) &&
        !isConus(data.country)
      ) {
        errors['postal_code'] = undefined
        errors['state'] = undefined
      }

      batch(() => {
        dispatch(setShippingAddressStatus({ certified: false }))
        dispatch(clearSuggestions())
        dispatch(setShippingAddress(data))
        dispatch(setShippingErrors(errors))

        // requote if a tax-relevant field changed
        if (includesTaxField(data) && useShippingForTax) {
          dispatch(setCheckoutStatus({ stale_quote: true }))
        }
      })
    },
    [checkout, dispatch]
  )

  const handleBlur = useCallback(
    (data: Partial<Address & Contact>) => {
      let key: keyof Partial<Address & Contact>
      for (key in data) {
        const options: {
          data: typeof data
          required?: boolean
          country?: string
        } = {
          data: {
            [key]: data[key]
          }
        }
        const errors = validateAddressField(options)
        errors.length &&
          dispatch(setShippingErrors({ [key]: errors.join(', ') }))
      }
    },
    [dispatch]
  )

  const validateFormLocally = useCallback(
    (checkout: Partial<Checkout>) => {
      const { valid: shippingAddressValid, errors: shippingAddressErrors } =
        validateShippingAddress({
          data: checkout.shipping_address
        })
      const valid = shippingAddressValid

      if (!valid) {
        batch(() => {
          dispatch(setShippingErrors(shippingAddressErrors))
        })
      }

      return { valid, errors: { ...shippingAddressErrors } }
    },
    [dispatch]
  )

  const validateLocationSelected = (checkout: Partial<Checkout>) => {
    const { valid, errors } = validateSelectableShipping({
      data: checkout.shipping_address
    })
    if (!valid) {
      batch(() => {
        dispatch(setShippingErrors(errors))
      })
    }
    return { valid }
  }

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()

    // perform client-side Address validation before attempting to save the results

    const { valid } = useSelectableShipping
      ? !selectableShippingIds?.length && hasSelectableShippingAddressForm
        ? validateFormLocally(checkout)
        : validateLocationSelected(checkout)
      : validateFormLocally(checkout)

    if (!valid) return

    // PENDING: Is there a good reason not to always save when moving forward?
    if (canNavigateToReview) {
      navigate('../review')
    } else if (canNavigateToPayment) {
      navigate('../payment')
    } else {
      updateCheckout(checkout)
    }
  }

  const askToCertify = useCallback(() => {
    AnalyticsService.trackEvent(
      null,
      'submit-shipping',
      'certification-failure'
    )
    dispatch(
      setStatus({
        showCertifyAddressModal: true
      })
    )
  }, [dispatch])

  const submitLabel = !isPaymentDisabled
    ? 'Continue to Payment'
    : 'Continue to Order Review'

  useResponse(updateResponse, () => {
    // PENDING: This is local validation
    const { valid, errors } = validateShippingAddress({
      data: checkout.shipping_address
    })

    if (valid) {
      if (certified) {
        AnalyticsService.trackEvent(
          null,
          'submit-shipping',
          'certification-success'
        )
        navigate('../payment')
      } else {
        validateAddress(checkout.shipping_address || ({} as Address))
      }
    } else if (size(errors) > 0) {
      dispatch(setShippingErrors(errors))
    } else {
      AnalyticsService.trackEvent(null, 'submit-shipping', 'validation-failure')
    }
  })

  useErrorResponse(validationResponse, (error: ApiErrorResponse) => {
    if (error.data?.message?.includes('AddressNotFound')) {
      // informs the user that this is expected behavior
      console.info(
        'Could not find the shipping address, requesting customer certification'
      )
      askToCertify()
    }
  })

  useResponse(validationResponse, (response) => {
    if (response.suggestion && isConusAddress(checkout.shipping_address)) {
      dispatch(setSuggestions(response.suggestion))
    } else if (response.valid) {
      AnalyticsService.trackEvent(
        null,
        'submit-shipping',
        'certification-success'
      )
      dispatch(setShippingAddressStatus({ certified: true }))
      navigate('../payment')
    }

    AnalyticsService.trackEvent(null, 'submit-shipping', 'validation-failure')
    if (size(response.errors) > 0) {
      dispatch(setShippingErrors(response.errors))
    }

    askToCertify()
  })

  const onSelectableShippingLocationChange = (data: any) => {
    batch(() => {
      dispatch(clearShippingErrors())
      dispatch(setShippingAddress(data))
      dispatch(setCheckoutStatus({ stale_quote: true }))
    })
  }

  const onRadioChange = (value: string) => {
    const formSelected = value === 'free'
    dispatch(clearShippingErrors())
    if (!formSelected) {
      onSelectableShippingLocationChange({ selected_shipping_ids: [value] })
    }
    if (!formSelected) {
      batch(() => {
        dispatch(clearSuggestions())
      })
    } else {
      batch(() => {
        dispatch(setCheckoutStatus({ stale_quote: true }))
        dispatch(setShippingAddress({ selected_shipping_ids: [] }))
      })
    }
  }

  return (
    <>
      <CertifyAddressModal />
      <div
        className="CheckoutViewContainer container flex"
        data-testid="ShippingPage"
      >
        <div className="lg:w-1/2 lg:p-12 lg:pl-8 w-full p-4 mx-auto">
          <div className="mb-12">
            <DeliveryPreview />
            {useSelectableShipping ? (
              <SelectableShipping
                formOptions={selectableShippingFormLocation}
                locationOptions={selectableShippingOptions as AddressOption[]}
                onLocationChange={onSelectableShippingLocationChange}
                onRadioChange={onRadioChange}
                errors={{ ...errors.shipping_address }}
                selectedShippingIds={selectableShippingIds}
                addressForm={
                  <AddressForm
                    address={checkout.shipping_address}
                    contact={checkout.contact}
                    errors={{ ...errors.shipping_address, ...errors.contact }}
                    shippingCountryOptions={checkout.shipping_country_options}
                    readonly={checkout.shipping_address?.readonly}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  ></AddressForm>
                }
              />
            ) : (
              <AddressForm
                address={checkout.shipping_address}
                contact={checkout.contact}
                errors={{ ...errors.shipping_address, ...errors.contact }}
                shippingCountryOptions={checkout.shipping_country_options}
                readonly={checkout.shipping_address?.readonly}
                handleBlur={handleBlur}
                handleChange={handleChange}
              ></AddressForm>
            )}
            <SubmitButton
              className="mt-4"
              disabled={
                updateResponse?.isLoading ||
                validationResponse?.isLoading ||
                hasShippingAddressErrors ||
                hasContactErrors
              }
              testId="submitShippingButton"
              onClick={handleSubmit}
              value={submitLabel}
            />
          </div>
        </div>
        <OrderSummarySidebar
          isQuotingCheckout={quoteResponse.isLoading}
          alwaysShowTax={isUsAddress(checkout.shipping_address)}
        />
      </div>
    </>
  )
}
