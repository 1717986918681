import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button
} from '@customink/pigment-react'
import rollbar from '@/react/utils/rollbar'

type ErrorBoundaryDialogProps = {
  error: Error
  resetErrorBoundary: () => void
}

const ErrorBoundaryDialogStackStyle: React.CSSProperties = {
  overflowX: 'auto',
  maxWidth: '95vw'
}

const ErrorBoundaryDialog = ({
  error,
  resetErrorBoundary
}: ErrorBoundaryDialogProps) => {
  rollbar.info(`Error Boundary: ${error.message}`, error)

  const safeToDisplayActualError = process.env.NODE_ENV === 'development'
  const actualError = (
    <>
      <code>{error.message}</code>
      <pre style={ErrorBoundaryDialogStackStyle}>{error.stack}</pre>
    </>
  )
  const genericError = 'Please try again later.'

  return (
    <Dialog title="Something went wrong." open={true} onClose={() => {}}>
      <DialogContent>
        {safeToDisplayActualError ? actualError : genericError}
      </DialogContent>
      <DialogActions>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorBoundaryDialog
