import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '@/react/hooks'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import { FormControl, FormLabel } from '@customink/pigment-react'
import Icon from '@/react/components/Icon'
import { PurchaseOrder } from '@/types/purchaseOrder'
import { validatePurchaseOrderField } from '@/react/services/purchase_order_validation'
import {
  setPurchaseOrderErrors,
  clearPurchaseOrderErrors
} from '@/react/features/checkout/errorsSlice'
import { replacePurchaseOrderUpload } from '@/react/features/checkout/purchaseOrderSlice'
import { useUploadPurchaseOrderFileMutation } from '@/react/services/checkout_api'
import ActivityIndicator from '@/react/components/ActivityIndicator'
import rollbar from '@/react/utils/rollbar'

export default function PurchaseOrderUploader() {
  const inputId = 'purchase-order-upload-name'
  const dispatch = useAppDispatch()
  const token = useAppSelector((state) => state.checkout.token)
  const upload = useAppSelector(
    (state) => state.purchase_order.purchase_order_upload
  )
  const errors = useAppSelector((state) => state.errors.purchase_order)
  const [uploadFile, response] = useUploadPurchaseOrderFileMutation()
  const [fileName, setFileName] = useState<string | null>(null)

  const errorMessage = [errors?.po_file, errors?.purchase_order_upload]
    .filter((message) => !!message)
    .join(', ')

  const errorMessagePresent = errorMessage.length > 0

  const propertyKey = 'po_file' as keyof PurchaseOrder['po_file']

  const infoMessage =
    'The following file formats are accepted (.PDF, .JPG, .PNG, and .DOC). File size is limited to 5mb. If your PO is available online, please create a .PDF for upload.'

  const getFile = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.files?.length && event.target.files.length > 0
      ? event.target.files[0]
      : false

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const file = getFile(event)
    dispatch(clearPurchaseOrderErrors([propertyKey]))

    if (file) {
      const validationErrors = validatePurchaseOrderField({
        data: { [propertyKey]: file }
      })
      if (validationErrors.length > 0) {
        dispatch(
          setPurchaseOrderErrors({ [propertyKey]: validationErrors.join(', ') })
        )
      } else if (token) {
        const formData = new FormData()
        formData.append(propertyKey, file)
        setFileName(file.name)
        uploadFile({ token, file: formData })
      }
    }
  }

  useEffect(() => {
    if (!response.isSuccess) {
      return
    }
    if (!fileName) {
      rollbar.warn('missing file name for purchase order upload')
    } else {
      dispatch(
        replacePurchaseOrderUpload({
          name: response.data.name,
          key: response.data.key
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.isSuccess])

  return (
    <div className="PurchaseOrderUploader" data-testid="PurchaseOrderUploader">
      <FormControl error={errorMessagePresent}>
        <div className="pre-pigment-label-wrapper">
          <FormLabel>
            <div className="pre-pigment-label">
              PO File <span className="pre-pigment-error-color">*</span>
            </div>
          </FormLabel>
        </div>

        <input
          accept="accept"
          className="hidden"
          id={inputId}
          name="po_file_name"
          type="file"
          onChange={onFileChange}
        />

        <FormLabel className="flex-nowrap flex items-center" htmlFor={inputId}>
          <Button
            className={`pre-pigment-file-input${
              errorMessagePresent ? ' file-input-error' : ''
            }`}
            component="div"
          >
            Choose File
          </Button>
          <div className="ml-3">
            {fileName ? (
              response.isLoading || !upload?.name ? (
                <ActivityIndicator size="small" />
              ) : errorMessagePresent ? null : (
                <div className="pre-pigment-label">{upload.name}</div>
              )
            ) : (
              <div className="pre-pigment-label">No file chosen</div>
            )}
          </div>
        </FormLabel>

        <FormHelperText className="pre-pigment-helper-text mb-2">
          {errorMessage.length > 0 ? errorMessage : ''}
        </FormHelperText>

        <div className="flex items-center pb-4 text-sm">
          <Icon name="info" className="mr-1" size="16" />
          {infoMessage}
        </div>
      </FormControl>
    </div>
  )
}
