import React from 'react'
import guarantee from '@/assets/images/guarantee.svg'
import seal from '@/assets/images/norton-security-seal.png'

export default function Guarantee() {
  return (
    <div className="lg:flex items-center justify-between hidden py-8 mt-16 border-t">
      <div className="flex items-center">
        <img
          width="100"
          src={guarantee}
          alt="100% Satisfaction Guaranteed"
          className="mr-6"
        />
        <div>
          <div className="label mb-2">Our Guarantee</div>
          <div className="font-italic text-sm">
            We carefully review every
            <br />
            design to ensure a great print!
          </div>
        </div>
      </div>
      <div>
        <img
          width="100"
          src={seal}
          alt="Secured and encrypted checkout"
          className="blend-multiply mr-6"
        />
      </div>
    </div>
  )
}
