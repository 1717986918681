import React, { useMemo, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Link } from '@customink/pigment-react'

const UserLogin = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

  const handleLoginClick = useCallback(() => {
    loginWithRedirect()
  }, [loginWithRedirect])

  const linkProps = useMemo(
    () => ({
      className: 'inline-link-button',
      button: true,
      onClick: handleLoginClick
    }),
    [handleLoginClick]
  )

  return (
    <>
      {!isAuthenticated && (
        <Alert className="container" severity="info">
          Welcome back!&nbsp;
          {!isLoading && !isAuthenticated && (
            <>
              <Link {...linkProps}>Log in</Link>
              &nbsp;to auto-fill your information for faster checkout.
            </>
          )}
        </Alert>
      )}
    </>
  )
}

export default UserLogin
