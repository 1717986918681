import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import {
  Address,
  AddressError,
  AddressKey,
  BaseAddressValidationKeys,
  AddressValue,
  BillingAddressError,
  ShippingAddress,
  ShippingAddressError
} from '@/types/address'
import {
  CheckoutValidator,
  FieldValidator,
  DataValidator,
  ValidationOptions
} from '@/types/validation'
import {
  invalidData,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateAddress1,
  validateAddress2,
  validateCity,
  validatePostalCode,
  validateState,
  validateCountry
} from '@/react/services/checkout_validation'
import { config } from '@/services/DocumentService'
import rollbar from '@/react/utils/rollbar'

const {
  max_name_length,
  min_address_line_length,
  max_address_line_length,
  max_city_length,
  max_postal_code_length,
  max_state_length,
  max_phone_length
} = config.address_validation
const min_postal_code_length = 5

const addressValidators: FieldValidator<
  BaseAddressValidationKeys,
  AddressValue
> = {
  'first_name': validateFirstName({ max: max_name_length }),
  'last_name': validateLastName({ max: max_name_length }),
  'address_1': validateAddress1({
    min: min_address_line_length,
    max: max_address_line_length
  }),
  'address_2': validateAddress2({ max: max_address_line_length }),
  'city': validateCity({ max: max_city_length }),
  'postal_code': validatePostalCode({
    min: min_postal_code_length,
    max: max_postal_code_length
  }),
  'state': validateState({ max: max_state_length }),
  'country': validateCountry()
}

const fullAddressValidators: FieldValidator<AddressKey, AddressValue> = {
  ...addressValidators,
  ...{
    phone_number: validatePhoneNumber({ max: max_phone_length })
  }
}

export const validateAddressField: DataValidator<Address> = (options) => {
  if (!options || invalidData(options.data) || isEmpty(options.data)) {
    return []
  }
  const { required, data } = options
  const fieldName = Object.keys(data)[0] as AddressKey

  if (fieldName in fullAddressValidators) {
    const value = data[fieldName]
    return fullAddressValidators[fieldName]({
      value,
      required,
      country: data.country
    })
  } else {
    rollbar.error('no validations available for address field: ', fieldName)
    return []
  }
}

const validateAddress =
  ({
    type
  }: {
    type?: 'shipping' | 'billing'
  }): CheckoutValidator<Address, AddressError | BillingAddressError> =>
  (options) => {
    const errors = {} as AddressError
    if (!options) {
      return { errors, valid: false }
    }
    const address = (options.data as Address) || {}

    let valid = true
    each(
      type === 'shipping' ? addressValidators : fullAddressValidators,
      (validator, field) => {
        const addressField = field as AddressKey
        const value = address[addressField]

        const validationOptions: ValidationOptions<AddressValue> = { value }
        validationOptions.country = address.country

        const fieldErrors = validator(validationOptions)
        if (fieldErrors.length > 0) {
          valid = false
          errors[addressField] = fieldErrors.join(', ')
        }
      }
    )

    return { errors, valid }
  }

export const validateBillingAddress: CheckoutValidator<
  Address,
  BillingAddressError
> = validateAddress({ type: 'billing' })
export const validateShippingAddress: CheckoutValidator<Address, AddressError> =
  validateAddress({ type: 'shipping' })

export const validateSelectableShipping: CheckoutValidator<
  ShippingAddress,
  ShippingAddressError
> = (options) => {
  const errors = {} as ShippingAddressError
  if (!options || invalidData(options.data) || isEmpty(options.data)) {
    return { errors: {}, valid: false }
  }

  // trim out falsy values
  const validSelectableShippingIds =
    options.data.selected_shipping_ids?.filter((id) => !!id) || []

  const valid = !!validSelectableShippingIds.length
  if (!valid) {
    errors.selected_shipping_ids = 'Select a Shipping Location'
  }
  return { errors, valid }
}
