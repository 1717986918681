import React from 'react'
import classNames from 'classnames'
import { RotatingLines } from 'react-loader-spinner'

interface SizeConfig {
  centerAlign: boolean
  strokeWidth: string
  width: string
}

export default function ActivityIndicator(props: {
  label?: string
  size?: string
}) {
  const sizeConfig: { [key: string]: SizeConfig } = {
    'large': {
      centerAlign: true,
      strokeWidth: '5',
      width: '96'
    },
    'small': {
      centerAlign: false,
      strokeWidth: '3',
      width: '24'
    }
  }

  const config: SizeConfig =
    sizeConfig[!!props.size && props.size in sizeConfig ? props.size : 'large']

  return (
    <div
      className={classNames(
        'LargeActivityIndicator flex flex-col items-center',
        { 'm-auto': config.centerAlign }
      )}
    >
      <RotatingLines
        animationDuration="0.75"
        strokeColor="rgba(0,0,0,0.25)"
        strokeWidth={config.strokeWidth}
        visible={true}
        width={config.width}
      />

      {props.label ? <div className="mt-6 text-xl">{props.label}</div> : null}
    </div>
  )
}
