import { getSupportedRegionCodes } from 'awesome-phonenumber'
import includes from 'lodash/includes'
import some from 'lodash/some'
import { config } from '@/services/DocumentService'
import { Address, Country, ShippingAddress, State } from '@/types/address'

export const includesTaxField = (address?: Partial<ShippingAddress>): boolean =>
  some(address, (_value, key) =>
    includes(['postal_code', 'country', 'state', 'city', 'address_1'], key)
  )

export const isUsAddress = (address?: Address): boolean =>
  address?.country === 'US'

export const isConus = (country?: Address['country']): boolean =>
  includes(['US', 'CA'], country)

export const isConusAddress = (address?: Address): boolean =>
  isConus(address?.country)

export const countries: Array<Country> = config.countries.filter(
  (country) =>
    getSupportedRegionCodes().includes(country.code) &&
    config.valid_country_codes.includes(country.code)
)

export const getStates = (address?: Address): State[] => {
  const country = getCountry(address)
  return (country ? country.states : []).filter(
    (state) =>
      state.name && state.code && state.name.length > 0 && state.code.length > 0
  )
}

export const getCountryByCode = (
  countryCode?: Address['country']
): Country | undefined =>
  countries.find(
    (country) =>
      countryCode && country.code.toLowerCase() === countryCode.toLowerCase()
  )

export const getCountry = (address?: Address): Country | undefined =>
  getCountryByCode(address?.country)

export const getState = (address?: Address): State | undefined => {
  const country = getCountry(address)
  return country?.states?.find(
    (state) =>
      address?.state &&
      state.code.toLowerCase() === address?.state?.toLowerCase()
  )
}

export const getStateName = (address?: Address): string | undefined => {
  const state = getState(address)
  return state?.name || address?.state
}

export const getCountryName = (address?: Address): string | undefined =>
  getCountry(address)?.name

export const getCountryOptions = (
  requestedCountryCodes?: string[]
): Country[] => {
  const allowedCountryCodes =
    requestedCountryCodes || config.valid_country_codes
  const list = countries.filter((country) =>
    allowedCountryCodes.includes(country.code)
  )
  return list
}
