import React from 'react'
import ciLogoFull from '@/assets/images/ci-logo-full.svg'
import ciLogoSign from '@/assets/images/ci-logo.svg'

const CustomInkLogo = ({ logoType }: { logoType?: string }) => {
  const logoSrc = logoType === 'sign' ? ciLogoSign : ciLogoFull

  return (
    <img
      className="CustomInkLogo w-32"
      src={logoSrc}
      title="Custom Ink Checkout"
    />
  )
}

export default CustomInkLogo
