import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Address, BillingAddress } from '@/types/address'
import type { Checkout } from '@/types/checkout'
import {
  ValidationResponse,
  VoucherValidationRequest,
  VoucherValidationResponse
} from '@/types/validation'
import { csrfTokenMetaElement } from '@/services/DocumentService'
import { marshalCheckout } from '@/react/services/transformers'
import { securityService } from '@/react/services/security_service'
import config from '@/config'

interface POFileUploadRequest {
  token: string
  file: FormData
}

interface POFileUploadResponse {
  key: string
  name: string
}

export const checkoutApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: async (headers: Headers, { getState }) => {
      try {
        const accessToken = await securityService.getAccessTokenSilently()({
          authorizationParams: {
            audience: config.auth0_audience
          }
        })
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken}`)
        }
      } catch (_error) {
        /* user not logged in */
      }

      headers.set('X-CSRF-Token', csrfTokenMetaElement.content)
      return headers
    }
  }),
  endpoints: (builder) => ({
    completeCheckout: builder.mutation<Checkout, Checkout>({
      query: (checkout) => ({
        url: `checkouts/${checkout.token}/complete`,
        method: 'PATCH',
        body: { checkout: marshalCheckout(checkout) }
      })
    }),
    getAccountByUUID: builder.query<{ account_id: number }, string>({
      query: (uuid) => ({
        url: `accounts`,
        params: { uuid },
        timeout: 5000
      })
    }),
    getBraintreeToken: builder.query<
      { braintree_env: string; braintree_token: string },
      { token: string; accountId?: number }
    >({
      query: ({ token, accountId }) => {
        const params = accountId !== undefined ? { account_id: accountId } : {}
        return {
          url: `checkouts/${token}/braintree_token`,
          params
        }
      }
    }),
    getCheckoutByToken: builder.query<Checkout, string>({
      query: (token) => `checkouts/${token}`
    }),
    quoteCheckout: builder.mutation<Checkout, Checkout>({
      query: (checkout) => ({
        url: `checkouts/${checkout.token}/quote`,
        method: 'POST',
        body: { checkout: marshalCheckout(checkout) }
      })
    }),
    updateCheckout: builder.mutation<Checkout, Checkout>({
      query: (checkout) => ({
        url: `checkouts/${checkout.token}`,
        method: 'PATCH',
        body: { checkout: marshalCheckout(checkout) }
      })
    }),
    // TODO use DirectUpload instead of sending form data
    uploadPurchaseOrderFile: builder.mutation<
      POFileUploadResponse,
      POFileUploadRequest
    >({
      query: ({ token, file }) => ({
        url: `checkouts/${token}/upload_po_file`,
        method: 'POST',
        body: file
      })
    }),
    validateAddress: builder.mutation<ValidationResponse, Address>({
      query: (address) => ({
        url: 'checkouts/validate_address',
        method: 'POST',
        body: { address }
      })
    }),
    validateBillingAddress: builder.mutation<
      ValidationResponse,
      BillingAddress
    >({
      query: (billingAddress) => ({
        url: 'checkouts/validate_address',
        method: 'POST',
        body: {
          address: {
            ...billingAddress,
            ...{ billing_address: true }
          }
        }
      })
    }),
    validateVoucher: builder.mutation<
      VoucherValidationResponse,
      VoucherValidationRequest
    >({
      query: ({ code, token }) => ({
        url: `checkouts/${token}/apply_voucher`,
        method: 'POST',
        body: { code }
      })
    })
  })
})

export const {
  useLazyGetAccountByUUIDQuery,
  useGetBraintreeTokenQuery,
  useGetCheckoutByTokenQuery,
  useCompleteCheckoutMutation,
  useQuoteCheckoutMutation,
  useUpdateCheckoutMutation,
  useUploadPurchaseOrderFileMutation,
  useValidateAddressMutation,
  useValidateBillingAddressMutation,
  useValidateVoucherMutation
} = checkoutApi
