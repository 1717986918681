import React, { useCallback, useContext, useEffect, useState } from 'react'
import classnames from 'classnames'
import { PayPalCheckout } from 'braintree-web'

import {
  useAppDispatch,
  useAppSelector,
  useTeardownEffect
} from '@/react/hooks'
import { selectAmount } from '@/react/selectors'
import { setPayment } from '@/react/features/checkout/paymentSlice'
import { setStatus } from '@/react/features/checkout/statusSlice'
import { clearFlash, setFlash } from '@/react/features/checkout/flashSlice'
import { PaymentContext } from '@/react/features/checkout/PaymentClientLoader'
import rollbar from '@/react/utils/rollbar'

import AnalyticsService from '@/services/AnalyticsService'

import { Checkout } from '@/types/checkout'
import { Payment } from '@/types/payment'
import { PaypalError, TokenizationOptions } from '@/types/braintree'

export default function PayPayButton({
  completeCheckout
}: {
  completeCheckout: (checkout: Checkout) => void
}) {
  const buttonId = 'paypal-button'
  const dispatch = useAppDispatch()
  const checkout = useAppSelector((state) => state.checkout)
  const rendered = useAppSelector((state) => state.status.renderedPayPalButtons)
  const amount = useAppSelector(selectAmount)
  const { braintreeEnv, paypalButtons, paypalClient, paypalIntent } =
    useContext(PaymentContext)
  const [loading, setLoading] = useState<boolean>(false)

  const renderButtons = useCallback((buttons, options) => {
    buttons.render(options, `#${buttonId}`)
  }, [])

  const placeOrder = useCallback(
    (payment: Payment) => {
      dispatch(clearFlash())
      completeCheckout({ ...checkout, payment })
    },
    [checkout, completeCheckout, dispatch]
  )

  // const mapBillingAddress = useCallback((address: any): Address => {
  //   return {
  //     address_1: address.line1,
  //     address_2: address.line2,
  //     postal_code: address.postalCode,
  //     city: address.city,
  //     country: address.countryCode,
  //     state: address.state,
  //     phone_number: address.phone
  //   } as Address
  // }, [])

  const initializeButtons = useCallback(
    (buttons, client: PayPalCheckout, env: string) => {
      renderButtons(buttons, {
        env,
        commit: true,
        style: {
          size: 'responsive',
          color: 'blue',
          shape: 'rect',
          label: 'pay',
          tagline: 'false'
        },

        payment: () => {
          return client.createPayment({
            flow: 'checkout' as any,
            intent: paypalIntent as any,
            currency: 'USD',
            displayName: 'Custom Ink, LLC',
            enableShippingAddress: false,
            amount
          })
        },

        onAuthorize: async (data: TokenizationOptions) => {
          AnalyticsService.trackEvent(null, 'place-order', 'pending', amount)

          setLoading(true)
          const payload = await client.tokenizePayment(data as any)
          setLoading(false)

          dispatch(
            setPayment({
              payment_method_nonce: payload.nonce
            })
          )

          // TODO: also update billing address from payload.details.billingAddress
          // if (payload.details.email) {
          //   dispatch(setContact({ email: payload.details.email }))
          // }
          // if (payload.details.billingAddress) {
          //   dispatch(
          //     setBillingAddress(
          //       mapBillingAddress({
          //         ...payload.details.billingAddress,
          //         phone: payload.details.phone
          //       })
          //     )
          //   )
          // }
          placeOrder({
            method: 'paypal',
            payment_method_nonce: payload.nonce
          })
        },

        onError: (error?: PaypalError) => {
          rollbar.error('paypal error', error)
          AnalyticsService.trackEvent(
            null,
            'submit-payment-method',
            'payment-method-failure'
          )
          dispatch(
            setFlash({
              type: 'error',
              message:
                "We've encountered an error while processing your order.",
              detail: 'Please try again, or try a different payment method.'
            })
          )
        }
      })
    },
    [amount, dispatch, paypalIntent, placeOrder, renderButtons]
  )

  useEffect(() => {
    if (!paypalClient || !paypalButtons || !braintreeEnv || rendered) {
      return
    } else {
      dispatch(
        setStatus({
          renderedPayPalButtons: true
        })
      )
      initializeButtons(paypalButtons, paypalClient, braintreeEnv)
    }
  }, [
    braintreeEnv,
    dispatch,
    initializeButtons,
    paypalButtons,
    paypalClient,
    rendered
  ])

  useTeardownEffect(() => {
    dispatch(
      setStatus({
        renderedPayPalButtons: false
      })
    )
  })

  const classes = classnames('PayPalButton', {
    'isDisabled': loading || !amount
  })

  return (
    <div id={buttonId} data-testid="payByPayPalButton" className={classes} />
  )
}
