import React, { useEffect, useRef } from 'react'
import { batch } from 'react-redux'

import { useAppSelector, useResponse, useAppDispatch } from '@/react/hooks'
import { useQuoteCheckoutMutation } from '@/react/services/checkout_api'

import {
  replacePricing,
  setCheckoutStatus,
  updateVoucherByCode
} from '@/react/features/checkout/checkoutSlice'
import { Checkout } from '@/types/checkout'
import debounce from 'lodash.debounce'

/* 
https://redux-toolkit.js.org/rtk-query/usage/mutations#shared-mutation-results
mutation is declared here but any component that wants to hook into the instance
can by using the same cache key (e.g. get response status)
*/
export const quoteMutationCacheKey = 'app-quote'

const QuoteEventWatcher = () => {
  //selectors
  const checkout = useAppSelector((state) => state.checkout)

  const dispatch = useAppDispatch()
  const [quoteCheckout, quoteResponse] = useQuoteCheckoutMutation({
    fixedCacheKey: quoteMutationCacheKey
  })

  // Requote checkout whenever it becomes stale
  const debouncedQuote = useRef<any>(
    debounce(
      (checkout: Checkout) => {
        if (checkout.stale_quote) {
          quoteCheckout(checkout)
        }
      },
      500,
      { trailing: true }
    )
  ).current

  useEffect(() => {
    debouncedQuote(checkout)
  }, [debouncedQuote, checkout])

  useResponse(
    quoteResponse,
    (data: Checkout) => {
      if (data?.pricing) {
        batch(() => {
          dispatch(replacePricing(data.pricing))
          dispatch(setCheckoutStatus({ stale_quote: false }))
          data.vouchers?.forEach((voucher) => {
            dispatch(
              updateVoucherByCode({
                code: voucher.code,
                updatedVoucher: voucher
              })
            )
          })
        })
      }
    },
    () => {
      dispatch(setCheckoutStatus({ stale_quote: false }))
    }
  )

  return <></>
}

export default QuoteEventWatcher
