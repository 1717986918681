import React from 'react'
import type { ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import config from '@/config'

export default function AuthWrapper({ children }: { children: ReactNode }) {
  return (
    <Auth0Provider
      domain={config.auth0_domain}
      clientId={config.auth0_client_id}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.auth0_audience
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}
