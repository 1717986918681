import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Payment, PaymentField, PaymentMethod } from '@/types/payment'
import { Subset } from '@/types/generic'

const initialState = {
  method: 'cc'
} as Payment

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    replacePayment: (_state: Payment, action: PayloadAction<Payment>) =>
      action.payload,
    setPayment: (
      state: Payment,
      action: PayloadAction<Subset<PaymentField>>
    ) => ({
      ...state,
      ...action.payload
    }),
    setPaymentMethod: (
      state: Payment,
      action: PayloadAction<PaymentMethod>
    ) => ({ ...state, method: action.payload }),
    setCardType: (
      state: Payment,
      action: PayloadAction<Payment['card_type']>
    ) => ({ ...state, card_type: action.payload }),
    setVerifyPayment: (
      state: Payment,
      action: PayloadAction<Payment['verified']>
    ) => ({
      ...state,
      verified: action.payload
    }),
    setStoreInVault: (
      state: Payment,
      action: PayloadAction<Payment['store_in_vault']>
    ) => ({
      ...state,
      store_in_vault: action.payload
    })
  }
})

export const {
  replacePayment,
  setPayment,
  setPaymentMethod,
  setCardType,
  setVerifyPayment,
  setStoreInVault
} = paymentSlice.actions
export default paymentSlice.reducer
