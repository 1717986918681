import React from 'react'
import classNames from 'classnames'

export default function Title({
  children,
  className
}: {
  children: any
  className?: string
}) {
  return <div className={classNames('title', className)}>{children}</div>
}
