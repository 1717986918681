import React from 'react'
import Title from '@/react/components/Title'
import Guarantee from '@/react/components/Guarantee'
import OrderSummary from '@/react/features/checkout/OrderSummary'

const OrderSummarySidebar = (props: {
  isQuotingCheckout?: boolean
  alwaysShowTax?: boolean
}) => {
  return (
    <div className="lg:block lg:w-1/2 lg:p-12 hidden p-4">
      <Title>Order Summary</Title>
      <OrderSummary
        isQuotingCheckout={props.isQuotingCheckout}
        alwaysShowTax={props.alwaysShowTax}
      />
      <Guarantee />
    </div>
  )
}

export default OrderSummarySidebar
