import { BillingAddress } from '@/types/address'
import { Checkout } from '@/types/checkout'

export const marshalCheckout = (checkout: Checkout): Checkout => {
  // in the backend, 'same as shipping' is indicated by a nil billing_address.
  const billingOverride = checkout.billing_address?.same_as_shipping
    ? { billing_address: null as unknown as BillingAddress }
    : {}

  return { ...checkout, ...billingOverride }
}
