import React, { useEffect, useState, useCallback } from 'react'
import { useAppSelector } from '@/react/hooks'
import { selectPaid } from '@/react/selectors'
import CheckoutCallbackChannel from '@/channels/checkout_callback_channel'
import rollbar, { isLogArgument } from '@/react/utils/rollbar'

type Channel = {
  subscribe: () => void
  unsubscribe: () => void
}

export default function CallbackChannel({
  handleRedirect
}: {
  handleRedirect: (url: string) => void
}) {
  const token = useAppSelector((state) => state.checkout.token)
  const paid = useAppSelector(selectPaid)
  const [channel, setChannel] = useState<Channel | null>(null)

  const handleError = useCallback((error: any) => {
    const errorMessage = 'Failed to unsubscribe CheckoutCallbackChannel'
    isLogArgument(error)
      ? rollbar.info(errorMessage, error)
      : rollbar.info(errorMessage)
  }, [])

  const handleUnsubscribe = useCallback(
    (url: string) => {
      try {
        channel?.unsubscribe()
      } catch (error) {
        handleError(error)
      }
      handleRedirect(url)
    },
    [handleRedirect, channel, handleError]
  )

  useEffect(() => {
    if (!paid || !token) {
      return
    }
    if (channel === null) {
      setChannel(
        CheckoutCallbackChannel(
          {
            changeRedirectUrl: handleUnsubscribe
          },
          token
        )
      )
    } else {
      channel.subscribe()
    }
  }, [token, paid, channel, handleUnsubscribe])

  return <></>
}
