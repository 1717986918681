import React from 'react'
import Icon from '@/react/components/Icon'
import SubmitButton from '@/react/components/SubmitButton'

export default function Complete() {
  return (
    <div
      className="CheckoutCompleteView flex flex-col justify-center h-screen"
      data-testid="CompletePage"
    >
      <div className="lg:py-12">
        <h1 className="title huge text-center">
          <Icon name="check" className="text-success mr-2" />
          Checkout Complete
        </h1>

        <p className="mb-6 text-xl text-center">
          Thank you! We have successfully processed your payment.
        </p>

        <div className="text-center">
          <p className="mb-6 text-lg">
            You should recieve an order confirmation shortly. If you do not,
            please <a href="https://www.customink.com/contact">contact us</a>.
          </p>
          <SubmitButton
            primary
            fullWidth={false}
            href="https://www.customink.com"
            value="Return to Custom Ink"
          />
        </div>
      </div>
    </div>
  )
}
