import React from 'react'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import type { SelectOption } from '@/types/html'
import type { Subset } from '@/types/generic'
import { FormControl, MenuItem, Select } from '@customink/pigment-react'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

interface SelectInputProps {
  autoComplete?: string
  className?: string
  disabled?: boolean
  errorMessage?: string
  label?: string
  name: string
  options: SelectOption[]
  placeholder?: string
  propertyName: string
  required?: boolean
  type?: string
  value?: any
  defaultValue?: any
  onBlur: (data: Subset<any>) => void
  onChange: (data: Subset<any>) => void
  onFocus?: () => void
}

const SelectInput = (props: SelectInputProps) => {
  const noOpHandler = () => {}
  const onFocus = props.onFocus || noOpHandler

  return (
    <div className={classNames('w-full', props.className)}>
      <FormControl fullWidth={true}>
        <InputLabel htmlFor="dropdown" required={props.required}>
          {props.label}
        </InputLabel>
        <Select
          aria-describedby="dropdown-helper-text"
          autoComplete={props.autoComplete}
          className="w-full"
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          error={!!props.errorMessage}
          fullWidth={true}
          id="dropdown"
          inputProps={{
            name: props.name,
            'data-testid': `${capitalize(props.name)}SelectInput`
          }}
          name={props.name}
          value={props.value ? props.value : props.defaultValue}
          onBlur={(event) => {
            event.preventDefault()
            props.onBlur({
              [props.propertyName]: event.target.value
            })
          }}
          onChange={(event) => {
            event.preventDefault()
            props.onChange({
              [props.propertyName]: event.target.value
            })
          }}
          onFocus={onFocus}
        >
          {props.placeholder && (
            <MenuItem
              className="w-full"
              disabled
              key="placeholder"
              value={props.defaultValue}
            >
              {props.placeholder}
            </MenuItem>
          )}
          {props.options.map((option: SelectOption, index) => {
            return (
              <MenuItem className="w-full" key={index} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
        <FormHelperText
          id="dropdown-helper-text"
          className="pre-pigment-helper-text"
        >
          {props.errorMessage || ''}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default SelectInput
