import React from 'react'
import dayjs from 'dayjs'
import { useAppSelector } from '@/react/hooks'
import deliveryIcon from '@/assets/images/delivery-icon.svg'
import { selectHasMultipleQuoteGroups } from '@/react/selectors'

export default function DeliveryPreview() {
  const delivery = useAppSelector((state) => state.checkout.delivery) || {
    name: 'Standard'
  }
  const hasMultipleQuoteGroups = useAppSelector(selectHasMultipleQuoteGroups)

  const formatDate = (date: any, year: any = {}) => {
    if (date) {
      if (year !== false) {
        return dayjs(date).format('MMM D, YYYY')
      } else {
        return dayjs(date).format('MMM D')
      }
    } else {
      return '-'
    }
  }

  return (
    <div className="ShippingMethodPreview flex items-start justify-between">
      <div className="title">Shipping</div>

      {!hasMultipleQuoteGroups && (
        <div className="flex items-center">
          <img src={deliveryIcon} width="36" className="lg:block hidden mr-4" />
          <div className="text-sm">
            <span className="font-bold uppercase">
              {delivery.name} Delivery
            </span>
            <br />
            {delivery.date ? (
              <span>Arrives {formatDate(delivery.date)}</span>
            ) : (
              <span>Estimated 3 - 5 Weeks</span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
