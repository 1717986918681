import React from 'react'
import { useAppSelector, useAppDispatch } from '@/react/hooks'
import { selectAmount } from '@/react/selectors'

import Icon from '@/react/components/Icon'

import { setStatus } from '@/react/features/checkout/statusSlice'

import formatCurrency from '@/react/utils/currencyFormatter'

const OrderSummaryBar = () => {
  const dispatch = useAppDispatch()
  const amount = useAppSelector(selectAmount)

  const openOrderSummaryModal = () => {
    dispatch(
      setStatus({
        showOrderSummaryModal: true
      })
    )
  }

  return (
    <div
      className="lg:hidden flex items-center px-4 py-2 bg-gray-100 border-b border-gray-300"
      onClick={() => openOrderSummaryModal()}
    >
      <div className="flex items-center text-sm">
        <Icon name="cart" className="mr-2" size="20" />
        Show order summary
        <Icon name="caret-down" className="ml-2" />
      </div>

      <div className="ml-auto font-bold">
        <span>{formatCurrency(amount)}</span>
      </div>
    </div>
  )
}

export default OrderSummaryBar
