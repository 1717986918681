import React from 'react'
import { NavLink } from 'react-router-dom'

type NavBarLinkProps = {
  name: string
  disabled: boolean
}

const NavBarLink = ({ name, disabled }: NavBarLinkProps) => (
  <NavLink
    to={`/${name}`}
    title={name}
    key={name}
    className="_link"
    onClick={(event) => {
      if (disabled) {
        event.preventDefault()
      }
    }}
  >
    <span>{name.toLocaleUpperCase()}</span>
  </NavLink>
)

export default NavBarLink
