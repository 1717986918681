import React, { useEffect, useCallback, useContext, useState } from 'react'
import {
  useTeardownEffect,
  useAppDispatch,
  useAppSelector
} from '@/react/hooks'
import each from 'lodash/each'
import Icon from '@/react/components/Icon'
import InputLabel from '@/react/components/InputLabel'
import {
  hostedFields,
  HostedFields,
  HostedFieldsEvent,
  HostedFieldsTokenizePayload
} from 'braintree-web'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Link,
  PlusCircleIcon,
  MinusCircleIcon,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@customink/pigment-react'
import { PaymentContext } from '@/react/features/checkout/PaymentClientLoader'
import CreditCardIcon from '@/react/components/CreditCardIcon'
import VaultedCards from '@/react/features/checkout/VaultedCards'
import type {
  BraintreeField,
  BraintreeFields,
  BraintreeFieldName
} from '@/types/braintree'
import type { Payment, PaymentError, PaymentField } from '@/types/payment'
import { Subset } from '@/types/generic'
import braintreeInputStyles from '@/constants/braintreeInputStyles'
import { setStoreInVault } from '@/react/features/checkout/paymentSlice'
import { disableAddSaveCardFlag } from '@/react/services/feature_flags'

export default function CreditCardInput({
  cardType,
  setErrors,
  shouldTokenize,
  setShouldTokenize,
  setTokenized,
  setPayment,
  setVerifyPayment
}: {
  cardType: Payment['card_type']
  setErrors: React.Dispatch<React.SetStateAction<PaymentError>>
  shouldTokenize: boolean
  setShouldTokenize: React.Dispatch<React.SetStateAction<boolean>>
  setTokenized: React.Dispatch<React.SetStateAction<boolean>>
  setPayment: (data: Subset<PaymentField>) => void
  setVerifyPayment: (data: Subset<Payment['verified']>) => void
}) {
  const dispatch = useAppDispatch()
  const { paymentFields, setPaymentFields, braintreeClient } =
    useContext(PaymentContext)
  const classes = {
    valid: 'isValid',
    invalid: 'isInvalid'
  }
  const [localCardType, setCardType] = useState<string>(cardType || 'unknown')
  const [isTokenizing, setIsTokenizing] = useState<boolean>(false)

  const [vaultedCards, setVaultedCards] = useState<PaymentField[]>([])
  const storeInVault = useAppSelector(
    (state) => state?.payment?.store_in_vault || false
  )
  const [selectedVaultedCard, setSelectedVaultedCard] = useState<
    PaymentField | undefined
  >()
  const [cvvHostedFields, setCvvHostedFields] = useState<
    HostedFields | undefined
  >()

  const { isAuthenticated } = useAuth0()

  const handleStoreInVaultChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setStoreInVault(event.target.checked))
  }

  const fieldError = useCallback((field) => {
    return field.isValid ? undefined : 'field is invalid'
  }, [])

  const updateValidityClass = useCallback(
    (fields: BraintreeFields, fieldName: BraintreeFieldName): void => {
      const field = fields[fieldName]
      field.container.classList.remove(classes.valid, classes.invalid)

      if (field.isValid) {
        field.container.classList.add(classes.valid)
      }

      if (!field.isPotentiallyValid) {
        field.container.classList.add(classes.invalid)
      }
    },
    [classes.invalid, classes.valid]
  )

  const updateValidityClasses = useCallback(
    (fields: BraintreeFields): void => {
      const errors = {} as PaymentError
      each(fields, (field, fieldName) => {
        if (!field.isValid) {
          field.container.classList.add(classes.invalid)
        }
        errors[fieldName as keyof PaymentError] = fieldError(field)
      })
      setErrors(errors)
    },
    [classes.invalid, fieldError, setErrors]
  )

  const clearValidityClasses = useCallback(
    (field: BraintreeField): void => {
      field.container.classList.remove(classes.valid, classes.invalid)
    },
    [classes.invalid, classes.valid]
  )

  const usingVaultedCard = useCallback(() => {
    return selectedVaultedCard !== undefined
  }, [selectedVaultedCard])

  const tokenizeCard = useCallback(
    (fields: HostedFields): void => {
      setErrors((creditCardErrors) => ({
        ...creditCardErrors,
        tokenization: undefined
      }))
      setShouldTokenize(false)
      setIsTokenizing(true)
      setTokenized(false)

      updateValidityClasses(fields.getState().fields)

      fields
        .tokenize()
        .then((payload: HostedFieldsTokenizePayload) => {
          let tokenizedPayment: PaymentField = {
            card_type: payload.details.cardType,
            card_last_4: payload.details.lastFour,
            payment_method_nonce: payload.nonce
          }
          if (usingVaultedCard() && selectedVaultedCard) {
            tokenizedPayment = {
              ...tokenizedPayment,
              card_type: selectedVaultedCard.card_type,
              card_last_4: selectedVaultedCard.card_last_4,
              credit_card_id: selectedVaultedCard.credit_card_id
            }
          }
          setPayment(tokenizedPayment)
          setIsTokenizing(false)
          setTokenized(true)
        })
        .catch((reason: any) => {
          const errorMessage =
            reason?.name === 'BraintreeError' && !!reason.message
              ? reason.message
              : 'Tokenization Error'

          setErrors((creditCardErrors) => ({
            ...creditCardErrors,
            tokenization: errorMessage
          }))
          setIsTokenizing(false)
        })
    },
    [
      setErrors,
      setPayment,
      setShouldTokenize,
      setTokenized,
      updateValidityClasses,
      selectedVaultedCard,
      usingVaultedCard
    ]
  )

  const onValidityChange = useCallback(
    (event: HostedFieldsEvent) => {
      setTokenized(false)
      setErrors((creditCardErrors) => ({
        ...creditCardErrors,
        tokenization: undefined,
        [event.emittedBy]: fieldError(event.fields[event.emittedBy])
      }))
      updateValidityClass(event.fields, event.emittedBy)
    },
    [fieldError, setErrors, setTokenized, updateValidityClass]
  )

  const onCardTypeChange = useCallback((event: HostedFieldsEvent) => {
    if (event.cards.length === 1) {
      setCardType(event.cards[0].type)
    }
  }, [])

  const onEmpty = useCallback(
    (event: HostedFieldsEvent) => {
      setTokenized(false)
      setErrors({})
      if (event.emittedBy === 'number') {
        setCardType('unknown')
      }
    },
    [setErrors, setTokenized]
  )

  const onNotEmpty = useCallback(
    (event: HostedFieldsEvent) => {
      setErrors((creditCardErrors) => ({
        ...creditCardErrors,
        tokenization: undefined,
        [event.emittedBy]: undefined
      }))
      clearValidityClasses(event.fields[event.emittedBy])
    },
    [clearValidityClasses, setErrors]
  )

  const onBlur = useCallback(() => {
    setTokenized(false)
    setVerifyPayment(false)
  }, [setTokenized, setVerifyPayment])

  const setEventListeners = useCallback(
    (fields: HostedFields, event: 'on' | 'off'): void => {
      // for event documentation, see:
      // https://developer.paypal.com/braintree/docs/guides/hosted-fields/events
      fields[event]('validityChange', onValidityChange)
      fields[event]('cardTypeChange', onCardTypeChange)
      fields[event]('empty', onEmpty)
      fields[event]('notEmpty', onNotEmpty)
      fields[event]('blur', onBlur)
    },
    [onValidityChange, onCardTypeChange, onEmpty, onNotEmpty, onBlur]
  )

  const disableAddSaveCardFlagEnabled = disableAddSaveCardFlag.enabled
  const isDisabled = () => (paymentFields ? '' : ' isDisabled')
  const ccClass = `relative input h-10 p-0 ${isDisabled()}`
  const iconClass =
    'z-1 absolute inset-y-0 left-0 flex items-center justify-center w-10 ml-1 text-gray-500 pointer-events-none'
  const ccInputClass = `CredCardInput ${usingVaultedCard() ? 'hidden' : ''}`

  const handleNewCardClick = () => {
    setSelectedVaultedCard(undefined)
  }

  // TODO: move to PaymentClientLoader
  const initializeBraintree = useCallback(
    (client) => {
      const options = {
        client,
        styles: braintreeInputStyles,
        fields: {
          number: {
            selector: '#creditCardInput-number',
            placeholder: '1111 1111 1111 1111'
          },
          cvv: {
            selector: '#creditCardInput-cvv',
            placeholder: '123'
          },
          expirationDate: {
            selector: '#creditCardInput-expirationDate',
            placeholder: 'MM / YY'
          }
        }
      }

      hostedFields.create(options, (error, fieldsInstance) => {
        if (error || !fieldsInstance) {
          throw error || Error('failed to create hosted fields')
        }
        setEventListeners(fieldsInstance, 'on')
        setPaymentFields(fieldsInstance)
      })
    },
    [setPaymentFields, setEventListeners]
  )

  const handleVaultedCardChange = useCallback(
    (e) => {
      const card = vaultedCards.find(
        (card) => card.credit_card_id === e.target.value
      )
      setSelectedVaultedCard(card)
    },
    [vaultedCards]
  )

  useEffect(() => {
    if (!braintreeClient) {
      return
    } else if (!paymentFields) {
      initializeBraintree(braintreeClient)
    }
  }, [braintreeClient, initializeBraintree, paymentFields])

  useTeardownEffect(() => {
    if (!paymentFields) {
      return
    }
    setEventListeners(paymentFields, 'off')
  })

  useEffect(() => {
    const fields = usingVaultedCard() ? cvvHostedFields : paymentFields
    if (shouldTokenize && !fields) {
      throw Error('Braintree hosted fields have not been initialized')
    }
    if (fields && shouldTokenize && !isTokenizing) {
      tokenizeCard(fields)
    }
  }, [
    paymentFields,
    cvvHostedFields,
    isTokenizing,
    shouldTokenize,
    tokenizeCard,
    usingVaultedCard
  ])

  const cvvLabel = 'CVC'
  // Set the placeholder of the CVV appropriate to the card type
  useEffect(() => {
    const placeholderText =
      localCardType === 'american-express' ? '1234' : '123'
    if (paymentFields) {
      paymentFields.setPlaceholder('cvv', placeholderText)
      // FIXME also change label text
    }
  }, [paymentFields, localCardType])

  return (
    <>
      {isAuthenticated && !disableAddSaveCardFlagEnabled && (
        <>
          <VaultedCards
            onVaultedCardChange={handleVaultedCardChange}
            selectedVaultedCard={selectedVaultedCard}
            setEventListeners={setEventListeners}
            cvvHostedFields={cvvHostedFields}
            setCvvHostedFields={setCvvHostedFields}
            cards={vaultedCards}
            setCards={setVaultedCards}
          />

          <Link
            button
            {...{ onClick: handleNewCardClick }}
            variant="bodyShort1"
          >
            {usingVaultedCard() ? (
              <PlusCircleIcon fontSize="small" />
            ) : (
              <MinusCircleIcon fontSize="small" />
            )}
            Add a new credit card
          </Link>
        </>
      )}
      <div className={ccInputClass} data-testid="CreditCardInput">
        <div className="mb-6">
          <InputLabel htmlFor="creditCardInput-number" required={true}>
            Card number
          </InputLabel>

          <div className="relative">
            <div className="z-1 absolute inset-y-0 left-0 flex items-center ml-3">
              <CreditCardIcon cardType={localCardType} />
            </div>
            <div id="creditCardInput-number" className={ccClass}></div>
          </div>
        </div>

        <div className="flex-nowrap flex mb-6">
          <div className="w-1/2 mb-0">
            <InputLabel
              htmlFor="creditCardInput-expirationDate"
              required={true}
            >
              Expiration date
            </InputLabel>

            <div className="relative">
              <Icon name="calendar" className={iconClass} size="22" />
              <div
                id="creditCardInput-expirationDate"
                className={ccClass}
              ></div>
            </div>
          </div>

          <div className="w-1/2 mb-0 ml-5">
            <InputLabel htmlFor="creditCardInput-cvv" required={true}>
              {cvvLabel}
            </InputLabel>

            <div className="relative">
              <Icon name="lock" className={iconClass} size="22" />
              <div id="creditCardInput-cvv" className={ccClass}></div>
            </div>
          </div>
        </div>

        {isAuthenticated && !disableAddSaveCardFlagEnabled && (
          <div className="mb-6">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={storeInVault}
                    onChange={(event) => handleStoreInVaultChange(event)}
                  />
                }
                label="Save my payment method for future orders"
              />
            </FormGroup>
          </div>
        )}
      </div>
    </>
  )
}
