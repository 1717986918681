import { Config } from '@/types/config'

const getCsrfTokenMetaElement = () =>
  document.head.querySelector('meta[name=csrf-token]') as HTMLMetaElement

const getConfig = (): Config => {
  const element = document.getElementById('config')
  return element ? JSON.parse(element.innerText) : {}
}

export const csrfTokenMetaElement = getCsrfTokenMetaElement()

export const config = getConfig()
