import { deviceRegex } from '@/constants/device'

type deviceTypes = keyof typeof deviceRegex

// figure out how to get typing working for any RegExp
const deviceIs = (device: deviceTypes) => {
  if (!deviceRegex[device]) {
    return false
  }
  return deviceRegex[device].test(navigator.userAgent)
}

const isIos = deviceIs('ios')
const isMac = deviceIs('mac')

export { isIos, deviceIs, isMac }
