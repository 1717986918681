import React, { useContext } from 'react'
import { useAppSelector, useAppDispatch } from '@/react/hooks'
import { useNavigate } from 'react-router-dom'

import { ApplePay } from 'braintree-web'

import { ErrorOf } from '@/types/generic'

import { setBillingAddress } from '@/react/features/checkout/checkoutSlice'
import { PaymentContext } from '@/react/features/checkout/PaymentClientLoader'
import { setFlash } from '@/react/features/checkout/flashSlice'

import { validateBillingAddress } from '@/react/services/address_validation'
import ApplePayService from '@/services/ApplePayService'
import AnalyticsService from '@/services/AnalyticsService'

import { BillingAddress } from '@/types/address'
import { Payment } from '@/types/payment'

// eslint-disable-next-line @typescript-eslint/ban-types
const ApplePayButton = (props: { handlePlaceOrder: Function }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const amount = useAppSelector((state) => state.checkout.pricing?.amount)

  const { applePayClient, braintreeToken } = useContext(PaymentContext)

  const handlePlaceOrder = props.handlePlaceOrder || (() => {})

  const beginApplePay = async () => {
    if (!applePayClient || !braintreeToken) {
      setFlash({
        type: 'error',
        message: 'There was an error contacting Apple Pay.',
        detail: 'Please try another payment method, or try again later.'
      })
      return
    }
    const applePaySession = await initializeApplePaySession(applePayClient)
    applePaySession.begin()
  }

  // Create the apple pay session with the necessary callbacks.
  const initializeApplePaySession = async (
    applePayClient: ApplePay
  ): Promise<ApplePaySession> => {
    const paymentRequest = applePayClient.createPaymentRequest(
      ApplePayService.paymentRequest({ amount }) as any
    )

    // this is not braintree-web/ApplePaySession, but the ApplePaySession
    // object that lives on `window` in Safari browers
    // @ts-ignore
    const session = new ApplePaySession(3, paymentRequest)

    // set callback methods for validation and authorization
    session.onvalidatemerchant = (event) => {
      applePayClient.performValidation(
        {
          validationURL: event.validationURL,
          displayName: 'Custom Ink'
        },
        (err, merchantSession) => {
          if (err) {
            AnalyticsService.trackEvent(
              null,
              'process-order',
              'applepay-token-failure'
            )

            // close the apple pay modal and end the session
            session.abort()

            // return the user to the payment picker with an error message
            setFlash({
              type: 'error',
              message: 'There was an error contacting Apple Pay.',
              detail: 'Please try another payment method, or try again later.'
            })
            navigate('../payment')
            return
          }
          session.completeMerchantValidation(merchantSession)
        }
      )
    }

    session.onpaymentauthorized = (event) => {
      applePayClient.tokenize(
        {
          token: event.payment.token
        },
        async (tokenizeErr, payload) => {
          if (tokenizeErr) {
            AnalyticsService.trackEvent(
              null,
              'process-order',
              'applepay-payment-failure'
            )

            // close the apple pay modal and end the session
            session.abort()

            // return the user to the payment picker with an error message
            setFlash({
              type: 'error',
              message: 'There was an error contacting Apple Pay.',
              detail: 'Please try another payment method, or try again later.'
            })
            navigate('../payment')
            return
          }

          // having successfully tokenized, we now transact with payment info

          // first, we upsert the billing address stored with apple pay
          // this validates the address, a requirement for completing payment
          const { valid, errors } = upsertApplePayBilling(
            event.payment?.billingContact
          )

          // if the billing address is invalid, we flag fields within
          // the apple pay session, and prompt the user to fix it
          if (!valid) {
            const paymentCompletion = ApplePayService.paymentCompletion({
              sessionStatus: ApplePaySession.STATUS_FAILURE,
              checkoutErrors: errors,
              amount
            })

            // The type definition specifies an numeric status code,
            // but it can actually take an ApplePayPaymentAuthorizationResult
            // @ts-ignore
            session.completePayment(paymentCompletion)
          } else {
            // Success, send the validated payment to be persisted with the checkout
            // and dismiss the apple pay modal
            handlePlaceOrder({
              method: 'paypal',
              payment_method_nonce: payload?.nonce
            } as Payment)
            session.completePayment(ApplePaySession.STATUS_SUCCESS)
          }
        }
      )
    }

    return session
  }

  // validate apple pay's billing address, if valid, upsert to checkout
  const upsertApplePayBilling = (
    applePayBilling: ApplePayJS.ApplePayPaymentContact | undefined
  ): { errors: ErrorOf<BillingAddress>; valid: boolean } => {
    if (applePayBilling) {
      const mappedBilling: BillingAddress =
        ApplePayService.mapBilling(applePayBilling)
      const { valid, errors } = validateBillingAddress({
        data: mappedBilling
      })

      if (valid) {
        dispatch(setBillingAddress(mappedBilling))
      }

      return { valid, errors }
    } else {
      // absent billing should not be possible, but fall back to
      // simply using the existing billing (or shipping) address
      return { valid: true, errors: {} }
    }
  }

  return <div className={'ApplePayButton'} onClick={beginApplePay}></div>
}

export default ApplePayButton
