import React, { useCallback, useMemo, useEffect } from 'react'
import { FormControl } from '@customink/pigment-react'
import FormGroup from '@/react/components/FormGroup'
import TextInput from '@/react/components/TextInput'
import PhoneInput from '@/react/components/PhoneInput'
import { Contact as ContactType, ContactError } from '@/types/contact'
import { ShippingAddress, Address } from '@/types/address'
import type { RootState } from '@/react/store'
import { batch } from 'react-redux'
import { useAppSelector, useAppDispatch, useResponse } from '@/react/hooks'
import {
  validateContactField,
  contactValidators,
  validateContact
} from '@/react/services/contact_validation'
import { setContactErrors } from '@/react/features/checkout/errorsSlice'
import { setContact } from '@/react/features/checkout/checkoutSlice'
import { useUpdateCheckoutMutation } from '@/react/services/checkout_api'
import SubmitButton from '@/react/components/SubmitButton'
import {
  selectHasContactErrors,
  selectCanNavigate,
  selectShippingDisabled
} from '@/react/selectors'
import { useNavigate } from 'react-router-dom'
import OrderSummarySidebar from '@/react/features/checkout/OrderSummarySidebar'
import { ga4_contact_page_view } from '@/services/GA4'

export type ContactFieldFormProps = {
  email?: string
  first_name?: string
  last_name?: string
  phone?: string
  organization?: string
}

export type ContactFormConfigProps = {
  [contactFormkey in keyof ContactFieldFormProps]: {
    readonly?: boolean
    disabled?: boolean
  }
}

export type ContactFormProps = ContactFieldFormProps & {
  config?: ContactFormConfigProps
} & {
  handleBlur: (data: Partial<ContactType>) => void
  handleChange: (data: Partial<ContactType>) => void
  errors?: ContactError
  shipping_address?: ShippingAddress
  isLoading?: boolean
  handlePhoneInputBlur?: (
    data: Partial<ContactType>,
    countryCode: string
  ) => void
}

export const ContactForm = ({
  first_name,
  email,
  last_name,
  phone,
  organization,
  handleBlur,
  handleChange,
  handlePhoneInputBlur,
  config,
  errors,
  shipping_address,
  isLoading
}: ContactFormProps) => {
  return (
    <FormControl data-testid="ContactForm" fullWidth>
      <FormGroup className="lg:flex-nowrap flex-wrap">
        {!config?.first_name?.disabled && (
          <TextInput
            autoComplete="given-name"
            className="lg:w-1/2 w-full"
            disabled={config?.first_name?.readonly || isLoading}
            errorMessage={errors?.first_name}
            id="address-first-name"
            label="First Name"
            name="first_name"
            placeholder="Enter your first name"
            propertyName="first_name"
            required={true}
            value={first_name || ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}
        {!config?.last_name?.disabled && (
          <TextInput
            autoComplete="family-name"
            className="lg:w-1/2 lg:ml-5 w-full ml-0"
            disabled={config?.last_name?.readonly || isLoading}
            errorMessage={errors?.last_name}
            id="address-last-name"
            label="Last Name"
            name="last_name"
            placeholder="Enter your last name"
            propertyName="last_name"
            required={true}
            value={last_name || ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        )}
      </FormGroup>
      {!config?.organization?.disabled && (
        <FormGroup className="lg:flex-nowrap flex-wrap">
          <TextInput
            autoComplete="organization"
            className="w-full"
            disabled={config?.organization?.readonly || isLoading}
            errorMessage={errors?.organization}
            id="address-organization"
            label="Organization"
            name="organization"
            placeholder="e.g., CalTech, General Electric"
            propertyName="organization"
            value={organization || ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormGroup>
      )}
      {!config?.email?.disabled && (
        <FormGroup className="lg:flex-nowrap flex-wrap">
          <TextInput
            autoComplete="email"
            className="w-full"
            disabled={config?.email?.readonly || isLoading}
            errorMessage={errors?.email}
            id="contact-email"
            label="Email"
            maxLength={128}
            name="email"
            placeholder="you@example.com"
            propertyName="email"
            required={true}
            value={email || ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormGroup>
      )}
      {!config?.phone?.disabled && (
        <FormGroup className="lg:flex-nowrap flex-wrap w-full">
          <PhoneInput
            required
            disabled={config?.phone?.readonly || isLoading}
            disableFormatOnLoad={shipping_address?.certified}
            errorMessage={errors?.phone}
            phoneNumber={phone || ''}
            propertyName="phone"
            onBlurWithCountry={handlePhoneInputBlur}
            onChange={handleChange}
          />
        </FormGroup>
      )}
    </FormControl>
  )
}

export const Contact = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { contact, shipping_address } = useAppSelector(
    (state: RootState) => state.checkout
  )
  const _config = useAppSelector(
    (state: RootState) => state.checkout.configuration
  )
  const errors = useAppSelector((state) => state.errors.contact)
  const checkout = useAppSelector((state) => state.checkout)
  const hasContactErrors = useAppSelector(selectHasContactErrors)
  const canNavigateToShipping = useAppSelector(selectCanNavigate)['/shipping']
  const shippingDisabled = useAppSelector(selectShippingDisabled)
  const [updateCheckout, updateCheckoutResponse] = useUpdateCheckoutMutation()

  useEffect(() => {
    ga4_contact_page_view()
  }, [])

  // TODO: abstract this so it can be used for other forms
  const config = useMemo(() => {
    const configObj: ContactFormConfigProps = {
      email: {},
      first_name: {},
      last_name: {},
      organization: {},
      phone: {}
    }
    type configKeys = keyof ContactFieldFormProps
    type configBranchOptionKeyType = 'disabled' | 'readonly'
    const configBranchOptionKeys = ['disabled', 'readonly']
    for (const configBranchOptionKey of configBranchOptionKeys) {
      // if top level is boolean, the set config for all the form fields to boolean value
      const contactBranchOptionValue =
        _config?.contact?.[configBranchOptionKey as configBranchOptionKeyType]
      if (typeof contactBranchOptionValue === 'boolean') {
        Object.keys(configObj).forEach((key) => {
          configObj![key as configKeys]![
            configBranchOptionKey as configBranchOptionKeyType
          ] = !!contactBranchOptionValue
        })
      } else if (Array.isArray(contactBranchOptionValue)) {
        contactBranchOptionValue.forEach((key: string) => {
          configObj[key as configKeys] = { [configBranchOptionKey]: true }
        })
      }
    }
    return configObj
  }, [_config])

  useResponse(updateCheckoutResponse, () => {
    if (canNavigateToShipping) {
      navigate('../shipping')
    }
    if (shippingDisabled) {
      navigate('../payment')
    }
  })

  const handleChange = useCallback(
    (data: Partial<ContactType>) => {
      const errors: ContactError = {}
      let key: keyof typeof data
      for (key in data) {
        errors[key] = undefined
      }

      batch(() => {
        dispatch(setContact(data))
        dispatch(setContactErrors(errors))
      })
    },
    [dispatch]
  )

  const handleBlur = useCallback(
    (data: Partial<Address & ContactType>) => {
      let key: keyof Partial<Address & ContactType>
      for (key in data) {
        const options: {
          data: typeof data
          required?: boolean
          country?: string
        } = {
          data: {
            [key]: data[key]
          }
        }
        const errors = validateContactField(options)
        errors.length
          ? dispatch(setContactErrors({ [key]: errors.join(', ') }))
          : dispatch(setContactErrors({ [key]: undefined }))
      }
    },
    [dispatch]
  )

  const handlePhoneInputBlur = (
    data: Partial<ContactType>,
    countryCode: string
  ) => {
    const options = {
      value: data.phone,
      required: true,
      country: countryCode
    }
    const errors = contactValidators.phone(options)
    errors.length
      ? dispatch(setContactErrors({ phone: errors.join(', ') }))
      : dispatch(setContactErrors({ phone: undefined }))
  }

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()

      // perform client-side Address & Contact validation before attempting to save the results
      const { errors, valid } = validateContact({ data: contact })

      if (!valid) {
        dispatch(setContactErrors(errors))
        return
      }

      updateCheckout(checkout)
    },
    [dispatch, contact, updateCheckout, checkout]
  )

  return (
    <div
      className="CheckoutViewContainer container flex"
      data-testid="ContactPage"
    >
      <div className="lg:w-1/2 lg:p-12 lg:pl-8 w-full p-4 mx-auto">
        <div className="mb-12">
          <div className="title">Contact</div>
          <ContactForm
            {...contact}
            errors={errors}
            handlePhoneInputBlur={handlePhoneInputBlur}
            handleBlur={handleBlur}
            handleChange={handleChange}
            isLoading={updateCheckoutResponse.isLoading}
            shipping_address={shipping_address}
            config={config}
          />
          <SubmitButton
            className="mt-4"
            disabled={hasContactErrors}
            testId="submitContact"
            onClick={handleSubmit}
            value={
              shippingDisabled ? 'Continue to Payment' : 'Continue to Shipping'
            }
          />
        </div>
      </div>
      <OrderSummarySidebar />
    </div>
  )
}

export default Contact
