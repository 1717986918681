import React from 'react'
import { Button } from '@customink/pigment-react'

interface Props {
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  href?: string
  testId?: string
  primary?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  value: string
}

const SubmitButton = (props: Props) => {
  const onClick = props.onClick || (() => {})

  return (
    <Button
      className={props.className || ''}
      data-testid={props.testId}
      disabled={props.disabled}
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
      size="large"
      onClick={onClick}
      href={props.href ? props.href : undefined}
    >
      {props.value}
    </Button>
  )
}

export default SubmitButton
