import React, { useContext } from 'react'
import { FormControl, RadioGroup } from '@customink/pigment-react'

import { PaymentContext } from '@/react/features/checkout/PaymentClientLoader'
import { useAppSelector } from '@/react/hooks'

import PaymentMethodRadioButton from '@/react/features/checkout/PaymentMethodRadioButton'

import { PaymentMethod } from '@/types/payment'

import paypalIcon from '@/assets/images/paypal-icon.png'
import applePayIcon from '@/assets/images/apple-pay-mark.svg'
import ccIcon from '@/assets/images/credit-card-icon.png'

interface PaymentMethodOption {
  label: string
  method: PaymentMethod
  icon?: string
  element?: JSX.Element
  testId?: string
}

export default function PaymentMethodPicker({
  creditCardForm,
  purchaseOrderForm
}: {
  [key: string]: React.ReactElement
}) {
  const paymentMethod: PaymentMethod = useAppSelector(
    (state) => state.payment.method
  )
  const paymentOptions = useAppSelector(
    (state) => state.checkout.payment_method_options
  )
  const { isEligibleForApplePay } = useContext(PaymentContext)
  const paymentMethodOptions = paymentOptions || []
  const defaultPaymentMethod = 'cc'

  const showPaymentMethodOption = (
    paymentMethodName: PaymentMethod
  ): boolean => {
    if (paymentMethodName === 'applepay' && !isEligibleForApplePay) {
      return false
    }

    return paymentMethodOptions.includes(paymentMethodName)
  }

  const paymentMethodRadioButtons = () =>
    [
      {
        label: 'PayPal',
        method: 'paypal' as PaymentMethod,
        icon: paypalIcon,
        testId: 'paymentMethodPayPal'
      },
      {
        label: 'Apple Pay',
        method: 'applepay' as PaymentMethod,
        icon: applePayIcon,
        testId: 'paymentMethodApplePay'
      },
      {
        element: creditCardForm,
        label: 'Credit Card',
        method: 'cc' as PaymentMethod,
        icon: ccIcon
      },
      {
        element: purchaseOrderForm,
        label: 'Purchase Order',
        method: 'purchase_order' as PaymentMethod
      }
    ].map((options: PaymentMethodOption, index: number) =>
      showPaymentMethodOption(options.method) ? (
        <PaymentMethodRadioButton key={index} {...options} />
      ) : null
    )

  return (
    <div className="lg:flex-nowrap flex-wrap mb-12">
      <FormControl fullWidth={true}>
        <RadioGroup
          name={'payment-method-picker'}
          className="PaymentMethodPicker border rounded"
          value={paymentMethod}
          defaultValue={defaultPaymentMethod}
        >
          {paymentMethodRadioButtons()}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
