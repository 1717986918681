import React, { useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'

import CallbackChannel from '@/react/features/checkout/CallbackChannel'
import CheckoutLoader from '@/react/features/checkout/CheckoutLoader'
import CheckoutRoutes from '@/react/features/checkout/CheckoutRoutes'
import ErrorBoundaryDialog from '@/react/components/ErrorBoundaryDialog'
import Flash from '@/react/features/checkout/Flash'
import OrderSummaryBar from '@/react/features/checkout/OrderSummaryBar'
import OrderSummaryModal from '@/react/features/checkout/OrderSummaryModal'
import PaymentClientLoader from '@/react/features/checkout/PaymentClientLoader'
import SiteHeader from '@/react/features/checkout/SiteHeader'
import QuoteEventWatcher from '@/react/features/checkout/QuoteEventWatcher'
import UserLogin from '@/react/features/checkout/UserLogin'
import NavigationBar from '@/react/features/checkout/NavigationBar'

import EmptyCart from '@/react/components/EmptyCart'
import ActivityIndicator from '@/react/components/ActivityIndicator'

import { loggerErrorHandler } from '@/react/utils/errorHandlers'

import StorageService from '@/services/StorageService'
import { securityService } from '@/react/services/security_service'
import { auth0Flag } from '@/react/services/feature_flags'
import { isIos } from '@/react/utils/device'

const CHECKOUT_STORAGE_KEY = 'checkout_token'

export default function App() {
  const tokenParam = new URLSearchParams(window.location.search).get('token')
  const storedToken = StorageService.load(CHECKOUT_STORAGE_KEY)
  const token: string = tokenParam || storedToken

  if (token !== storedToken) {
    StorageService.persist(CHECKOUT_STORAGE_KEY, token)
  }

  const handleRedirect = useCallback((url: string) => {
    console.log(url)
    StorageService.remove(CHECKOUT_STORAGE_KEY)
    window.location.replace(url)
  }, [])

  const { getAccessTokenSilently } = useAuth0()
  // set getAccessTokenSilently in global module to reuse it outside a React component
  securityService.setAccessTokenSilently(getAccessTokenSilently)

  const auth0Enabled = auth0Flag.enabled
  const isIosDevice = isIos

  return (
    <div
      className={classNames('flex flex-col min-h-screen', {
        isIosDevice: isIosDevice
      })}
      data-testid="CheckoutApp"
    >
      <SiteHeader />
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryDialog}
        onError={loggerErrorHandler}
      >
        <CheckoutLoader
          handleRedirect={handleRedirect}
          token={token}
          loadingElement={<ActivityIndicator label={'Loading your cart...'} />}
          notFoundElement={<EmptyCart />}
        >
          <PaymentClientLoader>
            <QuoteEventWatcher />
            <CallbackChannel handleRedirect={handleRedirect} />
            <NavigationBar />
            <OrderSummaryBar />
            <OrderSummaryModal />
            <Flash />
            {auth0Enabled && <UserLogin />}
            <CheckoutRoutes />
          </PaymentClientLoader>
        </CheckoutLoader>
      </ErrorBoundary>
      <ci-footer />
    </div>
  )
}
