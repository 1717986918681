import React, { MouseEventHandler } from 'react'
import { FormControlLabel, Radio } from '@customink/pigment-react'

interface Props {
  className?: string
  label?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  checked: boolean
}

const RadioButton = (props: Props) => {
  return (
    <FormControlLabel
      className={props.className || ''}
      label={props.label || ''}
      control={<Radio checked={!!props.checked} />}
    />
  )
}

export default RadioButton
