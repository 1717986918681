import React from 'react'
import type { Payment } from '@/types/payment'

import paypalIcon from '@/assets/images/paypal-icon.png'
import applePayIcon from '@/assets/images/apple-pay-mark.svg'

import CreditCardIcon from '@/react/components/CreditCardIcon'
import PurchaseOrderBlock from '@/react/components/PurchaseOrderBlock'

const PaymentMethodPreview = ({ payment }: { payment?: Payment }) => {
  return (
    <div className="PaymentMethodPreview inline-flex items-center leading-none align-middle">
      {payment?.method === 'purchase_order' ? <PurchaseOrderBlock /> : null}
      {payment?.method === 'paypal' ? (
        <div className="flex items-center">
          <img src={paypalIcon} height="28" className="h-8 mr-2" />
          Paypal
        </div>
      ) : null}
      {payment?.method === 'applepay' ? (
        <div className="flex items-center">
          <img src={applePayIcon} height="28" className="h-8 mr-2" />
          Apple Pay
        </div>
      ) : null}
      {payment?.method === 'cc' ? (
        <div className="flex items-center">
          <div className="CreditCardPreview relative flex items-center">
            <CreditCardIcon cardType={payment?.card_type} className="mr-3" />
            <div>
              <span
                className="font-bold"
                style={{ textTransform: 'capitalize' }}
              >
                {payment?.card_type || 'Unknown'}
              </span>
              {' ending with '}
              <span className="font-bold">
                {payment?.card_last_4 || 'Unknown'}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PaymentMethodPreview
