import { AddressError, BillingAddress, ShippingAddress } from '@/types/address'
import { Payment, PaymentError } from '@/types/payment'
import { ShippingMethod } from '@/types/shipping'
import { Contact, ContactError } from '@/types/contact'
import { PurchaseOrderError } from '@/types/purchaseOrder'
import { User } from '@/types/user'

export interface Item {
  name: string
  cid?: string
  commodity_code: string
  sku?: string
  kind: string
  unit_of_measure: string
  quantity: number
  description?: string
  unit_amount: string
  tax_amount?: string
  subtotal_amount: string
  total_amount: string
  image_urls?: string[]
  quote_group_id?: string
  // analytics attributes
  item_brand: string
  item_category: string
  item_category2: string
  item_category3: string
  item_variant: string
}

export interface Pricing {
  amount: string
  subtotal: string
  tax: string
  tax_exempt: boolean
  shipping?: string
  service_charge_amount: string
  service_charge_description: string
  discount_amount: string
  discount_description: string
  voucher_discount_amount: string
  voucher_discount_description: string
  collected?: string
  discounts: PricingDiscounts[]
}

export interface Checkout extends CheckoutStatus {
  token: string
  reference_id: string
  business_id: string
  contact: Contact
  vouchers: Vouchers[]
  pricing?: Pricing
  items: Item[]
  delivery: ShippingMethod
  shipping_address?: ShippingAddress
  billing_address?: BillingAddress
  paid_at?: string
  completed_at?: string
  payment?: Payment
  metadata?: any
  callback_url: string
  redirect_url?: string
  payment_method_options: string[]
  shipping_country_options: string[]
  configuration?: Configuration
  user?: User
}

export interface AddressOption {
  id?: string
  displayValue?: string
  value: {
    shipping_address: Partial<ShippingAddress>
    pricing: Partial<Pricing>
  }
}

export interface Configuration {
  affiliation?: string
  contact?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
  }
  billing_address?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
    tax_fields_readonly?: boolean
  }
  shipping_address?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
    method_options?: string[]
    location_options?: AddressOption[]
    form_shipping_location?: AddressOption
  }
  delivery?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
    method_options?: string[]
  }
  payment?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
    method_options?: string[]
  }
  pricing?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
    taxable_address?: taxableAddressOptions
  }
  vouchers?: {
    disabled?: boolean | string[]
    'readonly'?: boolean | string[]
  }
}

export type taxableAddressOptions = 'shipping' | 'billing' | 'none'
export interface PricingDiscounts {
  amount: string
  description: string
  type: string
  code?: string
  quote_group_id?: string
}

export const voucherDiscountTypes = [
  'manufacturer_voucher_discount',
  'store_voucher_discount',
  'voucher_discount'
]

export type Vouchers = {
  code: string
  type?: string
  active?: boolean
  start_date?: string
  expiration_date?: string
  discount?: {
    amount_off?: number
    type: string
  }
  applied?: boolean
}

export type CheckoutStatus = {
  stale_quote?: boolean
  paid?: boolean
  completed?: boolean
}

export type CheckoutError = {
  contact?: ContactError
  shipping_address?: AddressError
  billing_address?: AddressError
  payment?: PaymentError
  purchase_order?: PurchaseOrderError
  vouchers: string | undefined
}
