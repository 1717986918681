import React from 'react'
import { useAppSelector, useAppDispatch } from '@/react/hooks'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@customink/pigment-react'

import { setStatus } from '@/react/features/checkout/statusSlice'
import OrderSummary from '@/react/features/checkout/OrderSummary'

const OrderSummaryModal = () => {
  const dispatch = useAppDispatch()
  const showOrderSummaryModal = useAppSelector(
    (state) => state.status.showOrderSummaryModal
  )

  const handleClose = () => {
    dispatch(
      setStatus({
        showOrderSummaryModal: false
      })
    )
  }

  return showOrderSummaryModal ? (
    <Dialog
      className="CertifyAddressModal"
      data-testid="orderSummaryModal"
      open={true}
      title="Order Summary"
      onClose={handleClose}
    >
      <DialogContent>
        <OrderSummary />
      </DialogContent>
      <DialogActions>
        <Button
          className="lg:w-1/2 whitespace-nowrap lg:ml-4 lg:mb-0 w-full mb-3 ml-0"
          fullWidth
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default OrderSummaryModal
