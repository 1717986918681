import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/react/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { setUserAccountId } from '@/react/features/checkout/userSlice'
import {
  useGetCheckoutByTokenQuery,
  useLazyGetAccountByUUIDQuery
} from '@/react/services/checkout_api'
import { replaceCheckout } from '@/react/features/checkout/checkoutSlice'
import { selectRedirectable, selectPaid } from '@/react/selectors'
import type { RootState } from '@/react/store'

import { ga4_begin_checkout } from '@/services/GA4'

export interface CheckoutLoaderProps {
  token: string
  children: any
  loadingElement: any
  notFoundElement: any
  handleRedirect: (url: string) => void
}

const REDIRECT_POLL_INTERVAL = 10000

const CheckoutLoader = ({
  token,
  children,
  loadingElement,
  notFoundElement,
  handleRedirect
}: CheckoutLoaderProps) => {
  const dispatch = useAppDispatch()
  const {
    data: checkoutQueryData,
    isLoading: checkoutQueryIsLoading,
    isSuccess: checkoutQueryIsSuccess,
    isError: checkoutQueryIsError
  } = useGetCheckoutByTokenQuery(token, {
    skip: !token
  })
  const { isAuthenticated, user } = useAuth0()
  const [
    getAccountTrigger,
    {
      isSuccess: accountQueryIsSuccess,
      data: accountData,
      isLoading: accountQueryIsLoading,
      isError: accountQueryIsError
    }
  ] = useLazyGetAccountByUUIDQuery()
  const storedUser = useAppSelector((state: RootState) => state.user)
  const checkout = useAppSelector((state: RootState) => state.checkout)
  const paid = useAppSelector(selectPaid)
  const redirectable = useAppSelector(selectRedirectable)

  // A checkout is in the redux store if it's returned from redux with data (not an empty object).
  const checkoutIsInStore = !!checkout?.reference_id

  // A user is in the redux store if it's returned from redux with data (not an empty object).
  const userIsInStore = !!storedUser?.account_id

  // All required data has been stored and retrieved from the redux store
  const allIsInStore = isAuthenticated
    ? checkoutIsInStore && (userIsInStore || accountQueryIsError)
    : checkoutIsInStore

  // A checkout is in a loading state if the query to the API is currently loading (checkoutQueryIsLoading) OR
  // the query to the API was successful (checkoutQueryIsSuccess), but the checkout is not yet stored in redux (checkoutIsInStore)
  const checkoutIsLoadingState =
    (checkoutQueryIsLoading ||
      (checkoutQueryIsSuccess && !checkoutIsInStore)) &&
    !checkoutQueryIsError

  // An account is in a loading state if the query to the API is currently loading (accountQueryIsLoading) OR
  // the query to the API was successful (accountQueryIsSuccess), but the user is not yet stored in redux (userIsInStore)
  const accountIsLoadingState =
    (accountQueryIsLoading || (accountQueryIsSuccess && !userIsInStore)) &&
    !accountQueryIsError

  // If the user is authenticated, both the checkout and account must load. If not authenticated, just the checkout.
  const allLoadingState = isAuthenticated
    ? accountIsLoadingState || checkoutIsLoadingState
    : checkoutIsLoadingState

  // A checkout is displayable if it has a token AND the query to the API is not still loading (checkoutQueryIsLoading) AND
  // the checkout is in the redux store (checkoutIsInStore) AND the checkout is not redirectable (redirectable)
  const displayable = token && !allLoadingState && allIsInStore && !redirectable

  useEffect(() => {
    if (displayable) {
      ga4_begin_checkout()
    }
  }, [displayable])

  // set the checkout when the api data updates
  useEffect(() => {
    if (typeof checkoutQueryData !== 'undefined') {
      dispatch(replaceCheckout(checkoutQueryData))
    }
  }, [checkoutQueryData, dispatch])

  // refresh the page to check for a redirect_url if paid
  useEffect(() => {
    if (paid && !redirectable) {
      setTimeout(() => {
        window.location.reload()
      }, REDIRECT_POLL_INTERVAL)
    }
  }, [handleRedirect, redirectable, paid])

  // redirect on render if the redirect_url is present
  useEffect(() => {
    if (redirectable) {
      handleRedirect(redirectable)
    }
  }, [redirectable, handleRedirect])

  // If the user is authenticated, trigger request to get their account ID
  useEffect(() => {
    if (isAuthenticated && user?.reference_uuid) {
      getAccountTrigger(user.reference_uuid)
    }
  }, [user, isAuthenticated, getAccountTrigger])

  // If the account data is loaded, set the account ID in the store
  useEffect(() => {
    if (accountQueryIsSuccess && accountData?.account_id) {
      dispatch(setUserAccountId({ account_id: accountData.account_id }))
    }
  }, [accountQueryIsSuccess, accountData, dispatch])

  if (allLoadingState) {
    return loadingElement
  } else if (displayable) {
    return children
  } else {
    return notFoundElement
  }
}

export default CheckoutLoader
