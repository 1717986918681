import React, { ChangeEvent } from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@customink/pigment-react'
import SelectInput from '@/react/components/SelectInput'
import type { SelectOption } from '@/types/html'
import { AddressOption } from '@/types/checkout'
import { ShippingAddressError } from '@/types/address'

export type SelectableShippingProps = {
  formOptions?: AddressOption
  onRadioChange?: (val: string) => void
  onLocationChange: (data: any) => void
  addressForm?: React.ReactElement
  locationOptions: AddressOption[]
  selectedShippingIds?: string[]
  errors?: ShippingAddressError
  maxRadioOptions?: number
}

const formatLabelAndShippingCost = (
  label = 'Ship to My Address',
  stringShippingCost = ''
) => {
  const shippingCost = Number.parseFloat(stringShippingCost)
  let formattedLabel = `${label}`
  if (shippingCost) {
    formattedLabel += ` ($${stringShippingCost})`
  }
  return formattedLabel
}

const RadioSelectableShipping = ({
  formOptions,
  displayValue,
  selectedShippingIds,
  options,
  showLocationDropdown,
  handleRadioChange,
  errors
}: {
  formOptions?: AddressOption
  selectedShippingIds?: string[]
  displayValue?: string
  options?: any[]
  showLocationDropdown: boolean
  handleRadioChange?: (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => void
  errors?: ShippingAddressError
}) => {
  const value = !selectedShippingIds?.length ? 'free' : selectedShippingIds[0]
  return (
    <FormControl error={!!errors?.selected_shipping_ids}>
      <FormLabel className="choose-shipping-label">{displayValue}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={value}
        name="radio-buttons-group"
        onChange={handleRadioChange}
      >
        {!!formOptions && (
          <FormControlLabel
            control={<Radio />}
            value={'free'}
            label={formatLabelAndShippingCost(
              formOptions?.displayValue,
              formOptions?.value.pricing.shipping
            )}
          />
        )}
        {showLocationDropdown ? (
          <FormControlLabel
            control={<Radio />}
            value={selectedShippingIds?.[0]}
            label={'Ship to Location'}
          />
        ) : (
          options?.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                control={<Radio />}
                value={option.id}
                label={formatLabelAndShippingCost(
                  `Ship to ${option?.value?.shipping_address?.title}`,
                  option?.value?.pricing.shipping
                )}
              />
            )
          })
        )}
      </RadioGroup>
      <FormHelperText>{errors?.selected_shipping_ids}</FormHelperText>
    </FormControl>
  )
}

const LocationOptions = ({
  locationOptions,
  selectedShippingIds,
  handleLocationChange,
  errors
}: {
  locationOptions: AddressOption[]
  handleLocationChange: (data: any) => void
  errors?: ShippingAddressError
  selectedShippingIds?: string[]
}) => {
  return (
    <SelectInput
      className="w-full"
      name="location"
      options={mapLocationOptionToSelectOption(locationOptions)}
      propertyName="location"
      // we only support single shipping location atm
      placeholder={'Select option'}
      defaultValue={'Select'}
      value={selectedShippingIds?.[0]}
      onBlur={() => {}}
      onChange={handleLocationChange}
    />
  )
}

const mapLocationOptionToSelectOption = (options: AddressOption[]) => {
  return options.map((option) => {
    const obj: SelectOption = {}
    obj.label = formatLabelAndShippingCost(
      option.value?.shipping_address?.title,
      option.value?.pricing.shipping
    )
    obj.value = option.id
    return obj
  })
}

const SelectableShipping = ({
  formOptions,
  locationOptions,
  onLocationChange,
  addressForm,
  onRadioChange = () => {},
  selectedShippingIds,
  maxRadioOptions = 3,
  errors
}: SelectableShippingProps) => {
  const handleRadioChange = (event: ChangeEvent, val: string) => {
    onRadioChange(val)
  }

  const handleLocationChange = (data: any) => {
    onLocationChange({ selected_shipping_ids: [data.location] })
  }

  const hasFormOption = !!formOptions

  const possibleOptions = hasFormOption
    ? locationOptions?.length + 1
    : locationOptions?.length

  const showLocationDropdown = possibleOptions > maxRadioOptions

  const headerText = 'Choose a shipping location'

  const addressFormSelected = !selectedShippingIds?.length && hasFormOption

  if (!formOptions && showLocationDropdown) {
    return (
      <FormControl className="w-full" data-testid="LocationSelect">
        <FormLabel className="choose-shipping-label">{headerText}</FormLabel>
        <LocationOptions
          selectedShippingIds={selectedShippingIds}
          errors={errors}
          locationOptions={locationOptions}
          handleLocationChange={handleLocationChange}
        />
      </FormControl>
    )
  } else {
    return (
      <>
        <RadioSelectableShipping
          formOptions={formOptions}
          displayValue={headerText}
          selectedShippingIds={selectedShippingIds}
          handleRadioChange={handleRadioChange}
          showLocationDropdown={showLocationDropdown}
          options={locationOptions}
          errors={errors}
        />
        {addressFormSelected && addressForm}
        {!addressFormSelected && showLocationDropdown && (
          <LocationOptions
            selectedShippingIds={selectedShippingIds}
            errors={errors}
            locationOptions={locationOptions}
            handleLocationChange={handleLocationChange}
          />
        )}
      </>
    )
  }
}

export { SelectableShipping }
