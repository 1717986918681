import config from '@/config'
import { GTM as analytics } from '@/services/WindowService'

const namespace = 'consolidatedcheckout'

export default {
  trackPageView(path: string) {
    analytics.dataLayer = analytics.dataLayer || []
    const eventData = {
      event: 'GAPageView',
      GAPageView: {
        page: `/${namespace}${path}`,
        location: analytics.location.origin + path
      }
    }

    // Send page view
    analytics.dataLayer.push(eventData)
  },

  trackEvent(
    category: string | null,
    action?: string,
    label?: string,
    value?: any
  ) {
    analytics.dataLayer = analytics.dataLayer || []

    const eventData = {
      event: 'GAEvent',
      GAEvent: {
        category: category || namespace,
        action,
        label,
        value
      }
    }

    // Send custom event
    analytics.dataLayer.push(eventData)
  },

  // ---------------------------------- */
  // Google Optimize
  // ---------------------------------- */

  // Activate Google Optimize Experiment via analytics
  activateExperiment(name: string) {
    analytics.dataLayer.push({
      'event': `optimize.activate.${name}`
    })
  },

  getExperimentValue(id: any) {
    return new Promise((resolve) => {
      analytics.dataLayer.push('event', 'optimize.callback', {
        name: id,
        callback: resolve
      })
    })
  },

  // ---------------------------------- */
  // End of Google Optimize
  // ---------------------------------- */

  resetDatalayer() {
    try {
      if (!config.google_tag_manager_id) {
        return
      }

      if (!analytics.google_tag_manager) {
        return
      }

      if (!analytics.google_tag_manager[config.google_tag_manager_id]) {
        return
      }

      analytics.google_tag_manager[
        config.google_tag_manager_id
      ].dataLayer.reset()
    } catch (e) {
      throw new Error('Failed to reset dataLayer')
    }
  }
}
