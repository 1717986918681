import React from 'react'

export default function Image({
  className,
  src
}: {
  className?: string
  src: string
}) {
  return (
    <div className={`Image ${className}`}>
      <img src={src} />
    </div>
  )
}
