import React from 'react'
import PigmentLabel from '@mui/material/InputLabel'

export default function InputLabel(props: {
  children: string
  htmlFor: string
  required?: boolean
}) {
  return (
    <div className="pre-pigment-label-wrapper">
      <PigmentLabel
        className="pre-pigment-label"
        htmlFor={props.htmlFor}
        required={props.required}
      >
        {props.children}
      </PigmentLabel>
    </div>
  )
}
