import React from 'react'
import { useAppSelector } from '@/react/hooks'
import rollbar from '@/react/utils/rollbar'

const Flash = () => {
  const flash = useAppSelector((state) => state.flash)

  if (flash) {
    rollbar.debug(
      `Flash Error: ${flash.message}${flash.detail ? ` (${flash.detail})` : ''}`
    )
  }

  return (
    <>
      {flash ? (
        <div
          className={`text-center font-semibold mt-6 ${
            flash.type === 'error' ? 'text-danger' : ''
          }`}
          data-testid="Flash"
        >
          <div className="text-xl">{flash.message}</div>

          {flash.detail ? <div className="mt-1">{flash.detail}</div> : null}
        </div>
      ) : null}
    </>
  )
}

export default Flash
