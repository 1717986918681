import React from 'react'
import { useAppSelector } from '@/react/hooks'
import { PurchaseOrderField } from '@/types/purchaseOrder'

export default function PurchaseOrderBlock() {
  const purchaseOrder = useAppSelector((state) => state.purchase_order)

  const display = (key: keyof PurchaseOrderField, label: string) =>
    purchaseOrder && key in purchaseOrder ? (
      <div className="mb-2">
        <div className="font-bold">{label}</div>
        <div>{purchaseOrder[key]}</div>
      </div>
    ) : null

  const approvalSignature = purchaseOrder?.has_approval_signature
    ? ' (has approval signature)'
    : null

  const fileName =
    purchaseOrder?.purchase_order_upload &&
    'name' in purchaseOrder.purchase_order_upload ? (
      <div className="mb-2">
        <div className="font-bold">PO File</div>
        <div>
          {purchaseOrder.purchase_order_upload.name}
          {approvalSignature}
        </div>
      </div>
    ) : null

  return (
    <div className="PurchaseOrderBlock leading-normal">
      <div className="sub-title">Purchase Order</div>
      {display('contact', 'Name of Department')}
      {display('institution', 'Institution')}
      {display('billing_email', 'Billing Email')}
      {display('po_number', 'PO Number')}
      {fileName}
    </div>
  )
}
