import React, { useContext, useEffect, useState, useCallback } from 'react'
import { PaymentContext } from '@/react/features/checkout/PaymentClientLoader'
import { vaultManager, hostedFields, HostedFields } from 'braintree-web'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel
} from '@customink/pigment-react'
import Icon from '@/react/components/Icon'
import CreditCardIcon from '@/react/components/CreditCardIcon'
import braintreeInputStyles from '@/constants/braintreeInputStyles'
import { PaymentField } from '@/types/payment'

export default function VaultedCards({
  onVaultedCardChange,
  selectedVaultedCard,
  setEventListeners,
  cvvHostedFields,
  setCvvHostedFields,
  cards,
  setCards
}: {
  onVaultedCardChange: (e: any) => void
  selectedVaultedCard: PaymentField | undefined
  setEventListeners: (fields: HostedFields, event: 'on' | 'off') => void
  cvvHostedFields: HostedFields | undefined
  setCvvHostedFields: React.Dispatch<
    React.SetStateAction<HostedFields | undefined>
  >
  cards: PaymentField[]
  setCards: React.Dispatch<React.SetStateAction<PaymentField[]>>
}) {
  const { braintreeClient } = useContext(PaymentContext)
  const [vaultIsLoading, setVaultIsLoading] = useState<boolean>(true)

  const hasSelectedCard = useCallback(() => {
    return selectedVaultedCard !== undefined
  }, [selectedVaultedCard])

  useEffect(() => {
    if (!hasSelectedCard() && cvvHostedFields !== undefined) {
      setEventListeners(cvvHostedFields, 'off')
      cvvHostedFields?.teardown(() => {
        setCvvHostedFields(undefined)
      })
    }
  }, [
    selectedVaultedCard,
    setEventListeners,
    cvvHostedFields,
    setCvvHostedFields,
    hasSelectedCard
  ])

  useEffect(() => {
    vaultManager.create({ client: braintreeClient }, (err, vault) => {
      vault
        ?.fetchPaymentMethods()
        .then((data) => {
          setCards(
            data.map((card) => {
              return {
                credit_card_id: card.nonce,
                // @ts-ignore
                card_last_4: card.details.lastFour,
                // @ts-ignore
                card_type: card.details.cardType
              }
            })
          )
        })
        .finally(() => {
          setVaultIsLoading(false)
        })
    })
  }, [braintreeClient, setCards])

  useEffect(() => {
    if (!hasSelectedCard() || !!cvvHostedFields) {
      return
    }
    hostedFields.create(
      {
        client: braintreeClient,
        styles: braintreeInputStyles,
        fields: {
          cvv: {
            selector: '#creditCardInput-cvv-only',
            placeholder: '•••'
          }
        }
      },
      (error, fieldsInstance) => {
        if (error || !fieldsInstance) {
          throw error || Error('failed to create hosted fields')
        }
        setEventListeners(fieldsInstance, 'on')
        setCvvHostedFields(fieldsInstance)
      }
    )
  }, [
    selectedVaultedCard,
    setEventListeners,
    hasSelectedCard,
    braintreeClient,
    cvvHostedFields,
    setCvvHostedFields
  ])

  if (vaultIsLoading) {
    return (
      <div data-testid="VaultedCards">Loading any saved payment methods...</div>
    )
  } else if (cards.length > 0) {
    return (
      <div data-testid="VaultedCards">
        <FormControl>
          <FormLabel id="vaulted-cards-group-label">
            Saved Credit Cards
          </FormLabel>
          <RadioGroup
            aria-labelledby="vaulted-cards-group-label"
            defaultValue="inky"
            name="radio-buttons-group"
          >
            {cards.map((card) => {
              return (
                <FormControlLabel
                  value={card.credit_card_id}
                  control={
                    <Radio
                      checked={
                        selectedVaultedCard?.credit_card_id ===
                        card.credit_card_id
                      }
                      onChange={onVaultedCardChange}
                    />
                  }
                  label={
                    <div>
                      <CreditCardIcon cardType={card.card_type} />
                      <span>ending in {card.card_last_4}</span>
                    </div>
                  }
                  key={card.credit_card_id}
                />
              )
            })}
          </RadioGroup>
          {hasSelectedCard() && (
            <div className="w-1/2 mb-0 ml-5">
              <InputLabel htmlFor="creditCardInput-cvv-only" required={true}>
                CVV
              </InputLabel>

              <div className="relative">
                <Icon
                  name="lock"
                  className="z-1 absolute inset-y-0 left-0 flex items-center justify-center w-10 ml-1 text-gray-500 pointer-events-none"
                  size="22"
                />
                <div
                  id="creditCardInput-cvv-only"
                  className="input relative h-10 p-0"
                ></div>
              </div>
            </div>
          )}
        </FormControl>
      </div>
    )
  } else {
    return null
  }
}
