import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch, useResponse } from '@/react/hooks'
import {
  setCheckoutStatus,
  setShippingAddress,
  setShippingAddressStatus
} from '@/react/features/checkout/checkoutSlice'
import { clearShippingErrors } from '@/react/features/checkout/errorsSlice'
import { setStatus } from '@/react/features/checkout/statusSlice'
import { useUpdateCheckoutMutation } from '@/react/services/checkout_api'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@customink/pigment-react'
import AddressBlock from '@/react/components/AddressBlock'
import type { ShippingAddress } from '@/types/address'

const CertifyAddressModal = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const checkout = useAppSelector((state) => state.checkout)
  const suggestion = useAppSelector((state) => state.suggestions.suggestion)
  const showCertifyAddressModal = useAppSelector(
    (state) => state.status.showCertifyAddressModal
  )
  const [updateCheckout, response] = useUpdateCheckoutMutation()

  const isSuggestion = !!suggestion
  const address = isSuggestion ? suggestion : checkout.shipping_address

  // TODO: there is currently a bug where using suggested addresses
  // does not update the checkout in the backend, and thus trigger a quote.
  // for now, we manually mark the quote as stale here, and watch
  // for staleness in the Payment page where we quote the checkout
  const handleAcceptSuggestion = () => {
    dispatch(clearShippingErrors())
    dispatch(setShippingAddress(suggestion as ShippingAddress))
    dispatch(setCheckoutStatus({ stale_quote: true }))
    updateCheckout(checkout)
  }

  const handleCertify = () => {
    dispatch(clearShippingErrors())
    dispatch(
      setShippingAddressStatus({
        certified: true
      })
    )
    handleClose()
    navigate('../payment')
  }

  const handleClose = () => {
    dispatch(
      setStatus({
        showCertifyAddressModal: false
      })
    )
  }

  useResponse(response, () => handleCertify())

  return showCertifyAddressModal ? (
    <Dialog
      className="CertifyAddressModal"
      data-testid="certifyAddressModal"
      open={true}
      title="Confirm your address"
      onClose={handleClose}
    >
      <DialogContent>
        {isSuggestion ? (
          <span>
            To make sure you receive your order on time, we suggest using the
            following address:
          </span>
        ) : (
          <span>
            We were not able to find and verify your shipping address. <br />
            Please certify that your address is correct:
          </span>
        )}
        <div className="mt-border relative p-5 mt-6 mb-4 text-lg border rounded">
          {isSuggestion && (
            <span className="rounded-xl -top-4 right-4 absolute px-4 text-sm text-white bg-green-600">
              ✔ Verified for delivery
            </span>
          )}
          <AddressBlock
            address={address}
            contact={checkout?.contact}
          ></AddressBlock>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="modal-buttons">
          <Button
            className="lg:w-1/2 whitespace-nowrap w-full"
            fullWidth
            variant="secondary"
            onClick={handleClose}
          >
            Edit Address
          </Button>
          <Button
            className="lg:w-1/2 whitespace-nowrap lg:ml-4 lg:mb-0 w-full mb-3 ml-0"
            fullWidth
            onClick={isSuggestion ? handleAcceptSuggestion : handleCertify}
          >
            {isSuggestion ? 'Use Verified Address' : 'My Address is Correct'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  ) : null
}
export default CertifyAddressModal
