import { BillingAddress, BillingAddressError } from '@/types/address'

const PAYMENT_REQUEST_LABEL = 'Custom Ink'

type MappableAddressErrors = {
  [K in ApplePayJS.ApplePayErrorContactField]?: Array<string | undefined>
}

function generateApplePayErrors(
  billingAddressErrors: BillingAddressError
): Array<ApplePayError> {
  const filterEmpty = (arr: Array<string | undefined>) => {
    return arr.filter((el) => el)
  }

  const addressErrors: MappableAddressErrors = {
    name: filterEmpty([
      billingAddressErrors.first_name,
      billingAddressErrors.last_name
    ]),
    addressLines: filterEmpty([
      billingAddressErrors.address_1,
      billingAddressErrors.address_2
    ]),
    postalCode: filterEmpty([billingAddressErrors.postal_code]),
    locality: filterEmpty([billingAddressErrors.city]),
    country: filterEmpty([billingAddressErrors.country]),
    administrativeArea: filterEmpty([billingAddressErrors.state])
  }

  return (
    Object.entries(addressErrors) as Array<
      [ApplePayJS.ApplePayErrorContactField, Array<string | undefined>]
    >
  )
    .map(
      (
        entry: [ApplePayJS.ApplePayErrorContactField, Array<string | undefined>]
      ) => {
        const [field, errors]: [
          ApplePayJS.ApplePayErrorContactField,
          Array<string | undefined>
        ] = entry
        if (errors.length === 0) {
          return []
        }
        return errors.map((error: string | undefined) => {
          // translates snake-case field name to proper case,
          // e.g. first_name -> First Name
          // the result is displayed to the user in the Apple Pay modal
          const friendly_field = field
            .split('_')
            .map(
              (word) => word[0].toUpperCase() + word.substring(1).toLowerCase()
            )
            .join(' ')

          return new ApplePayError(
            'billingContactInvalid',
            field,
            `${friendly_field}: ${error}`
          )
        })
      }
    )
    .flat()
}

export default {
  // Generate a payment request for the Apple Pay SDK.
  paymentRequest({ amount }: { amount?: string }) {
    // Note: for those wondering, we do not pass in line items here
    // because Braintree does not support Apple Pay-style line items,
    // and thus doesn't support Level 3 transactions.
    // See https://github.com/customink/checkout/pull/143
    return {
      total: {
        label: PAYMENT_REQUEST_LABEL,
        amount
      },
      requiredBillingContactFields: ['postalAddress']
    }
  },

  // Generates a payload very similar to paymentRequest,
  // with the addition of status and error keys and the
  // re-keying of the total and line-item fields.
  paymentCompletion({
    amount,
    sessionStatus,
    checkoutErrors
  }: {
    amount?: string
    sessionStatus: number
    checkoutErrors: BillingAddressError
  }) {
    const { total } = this.paymentRequest({ amount: amount })
    const applePayErrors = generateApplePayErrors(checkoutErrors)
    return {
      status: sessionStatus,
      ...(applePayErrors.length && { errors: applePayErrors, newTotal: total })
    }
  },

  mapBilling(
    applePayBilling: ApplePayJS.ApplePayPaymentContact
  ): BillingAddress {
    // Apple Pay does not specify many of the below fields as required.
    // We will provide the fallback to conform with our interface,
    // validation will catch any blank fields.
    return {
      billing_address: true,
      first_name: applePayBilling.givenName || '',
      last_name: applePayBilling.familyName || '',
      // apple pay address UI has a 'street' field and
      // a 'apt, suite, bldg.' field, concatted into an array
      address_1: applePayBilling.addressLines?.[0] || '',
      address_2: applePayBilling.addressLines?.[1] || '',
      postal_code: applePayBilling.postalCode || '',
      city: applePayBilling.locality || '',
      country: applePayBilling.countryCode || '',
      state: applePayBilling.administrativeArea,
      phone_number: applePayBilling.phoneNumber
    }
  }
}
