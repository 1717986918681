import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Address } from '@/types/address'

type Suggestion = Address | null | undefined
interface SuggestionsState {
  suggestion: Suggestion
}

const initialState = {} as SuggestionsState

const suggestionsSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    clearSuggestions: () => ({
      suggestion: undefined
    }),
    setSuggestions: (
      _state: SuggestionsState,
      action: PayloadAction<Suggestion>
    ) => ({ suggestion: action.payload })
  }
})

export const { clearSuggestions, setSuggestions } = suggestionsSlice.actions
export default suggestionsSlice.reducer
