// ==== React ====
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { PigmentThemeProvider } from '@customink/pigment-react'
import ReactApp from '@/react/App'
import reactStore from '@/react/store'
import AuthWrapper from '@/react/features/checkout/AuthWrapper'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import type { ReactNode } from 'react'

import '@/assets/styles/styles.css'

const rootNode = document.getElementById('react')
// eslint-disable-next-line import/no-named-as-default-member
ReactDOM.render(
  <AuthWrapper>
    <Provider store={reactStore}>
      <PigmentThemeProvider>
        <BrowserRouter>
          <ReactApp />
        </BrowserRouter>
      </PigmentThemeProvider>
    </Provider>
  </AuthWrapper>,
  rootNode
)
