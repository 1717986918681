import React, { useState, useMemo, useEffect } from 'react'
import {
  Button,
  IconButton,
  XClearCircleIcon,
  AlertWarningIcon
} from '@customink/pigment-react'
import Collapse from '@mui/material/Collapse'
import TextInput from '@/react/components/TextInput'
import { validatePresence } from '@/react/services/checkout_validation'
import FormHelperText from '@mui/material/FormHelperText'
import { useAppDispatch, useResponse, useAppSelector } from '@/react/hooks'
import classNames from 'classnames'
import { onEnterPress } from '@/utils/eventHandlers'
import { Currency } from '@/react/components/LineItem'
import {
  addVoucherCode,
  removeVoucherByCode,
  clearVoucher,
  setCheckoutStatus,
  updateVoucherByCode
} from '@/react/features/checkout/checkoutSlice'
import {
  setVoucherErrors,
  clearVoucherErrors
} from '@/react/features/checkout/errorsSlice'
import { PricingDiscounts, Vouchers, Checkout } from '@/types/checkout'
import { batch } from 'react-redux'
import {
  useQuoteCheckoutMutation,
  useUpdateCheckoutMutation,
  useValidateVoucherMutation
} from '@/react/services/checkout_api'
import { quoteMutationCacheKey } from '@/react/features/checkout/QuoteEventWatcher'
import { ApiError } from '@/types/api'
import type { RootState } from '@/react/store'

interface VoucherFormProps {
  lineItemVoucher?: PricingDiscounts
  checkoutVoucher?: Vouchers
}

// error handling in general needs some shape up
const defaultVoucherError = 'There was an error applying your Voucher'
const voucherErrorRegex = /^voucher.*/gi

function VoucherForm({
  lineItemVoucher: voucher,
  checkoutVoucher: { code: formValue, applied: voucherApplied } = {
    code: '',
    applied: false
  }
}: VoucherFormProps) {
  // State
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const checkout = useAppSelector((state) => state.checkout)

  const [_quote, quoteResponse] = useQuoteCheckoutMutation({
    fixedCacheKey: quoteMutationCacheKey
  })

  const [updateCheckout] = useUpdateCheckoutMutation()

  const [validateVoucher, validateVoucherResponse] =
    useValidateVoucherMutation()

  const voucherErrors = useAppSelector(
    (state: RootState) => state.errors.vouchers
  )
  const token = useAppSelector((state) => state.checkout.token)

  useResponse(
    validateVoucherResponse,
    (data: Checkout) => {
      batch(() => {
        data.vouchers?.forEach((_voucher) => {
          dispatch(
            updateVoucherByCode({
              code: _voucher.code,
              updatedVoucher: _voucher
            })
          )
        })
        dispatch(setCheckoutStatus({ stale_quote: true }))
      })
    },
    (error) => {
      dispatch(setCheckoutStatus({ stale_quote: true }))
      asyncVoucherError(error?.detail)
    }
  )

  useResponse(
    quoteResponse,
    () => {
      setLoading(false)
      updateCheckout(checkout)
    },
    (errorResponse: ApiError) => {
      if (!errorResponse) {
        return
      }
      setLoading(false)
      // if the quote errors out, only a single error will be returned.
      // in our case we care only about errors that start with "Voucher"
      // we dont want to pick up errors not related to vouchers
      if (errorResponse.detail?.match(voucherErrorRegex) && formValue)
        asyncVoucherError(errorResponse?.detail || defaultVoucherError)
    }
  )

  useEffect(() => {
    if (formValue) {
      setIsOpen(true)
    }
  }, [formValue])

  const asyncVoucherError = (error: string | object) => {
    setLoading(false)
    if (typeof error === 'string') {
      dispatch(setVoucherErrors(error))
    } else {
      dispatch(setVoucherErrors(error?.toString()))
    }
  }

  const showInputError = useMemo(() => !!voucherErrors, [voucherErrors])

  // Event handlers
  const handleVoucherInputChange = (data: { voucher: string }) => {
    const _errors = syncVoucherError()
    if (!_errors.length) {
      dispatch(clearVoucherErrors())
    }
    dispatch(addVoucherCode({ code: data.voucher }))
  }

  const toggleVoucherForm = () => {
    setIsOpen(!isOpen)
  }

  const syncVoucherError = () => {
    return validatePresence()({ value: formValue })
  }

  const handleApplyClick = () => {
    const _errors = syncVoucherError()
    if (!_errors.length) {
      setLoading(true)
      validateVoucher({ code: formValue, token })
    } else {
      dispatch(setVoucherErrors(_errors.join(', ')))
    }
  }

  const removeAppliedVoucher = () => {
    handleClearVoucherInput()
    setIsOpen(true)
    if (voucher?.code) {
      setLoading(true)
      batch(() => {
        dispatch(removeVoucherByCode(voucher.code))
        validateVoucher({ code: '', token })
      })
    }
  }

  const handleClearVoucherInput = () => {
    batch(() => {
      dispatch(clearVoucherErrors())
      dispatch(clearVoucher())
    })
  }

  // Render
  const renderVoucherInput = () => {
    return (
      <>
        <a
          className="voucher-prompt blue"
          onClick={toggleVoucherForm}
          data-testid="voucher-input-label"
        >
          Have a voucher code?
        </a>
        <Collapse in={isOpen}>
          <div className="flex items-center">
            <TextInput
              disabled={loading}
              className={classNames('voucher w-full', {
                loading: loading
              })}
              id="voucher-input"
              name="voucher"
              propertyName="voucher"
              onChange={handleVoucherInputChange}
              onKeyUp={onEnterPress(handleApplyClick)}
              value={formValue}
              error={showInputError}
              removeHelperTextSpace
              endAdornment={
                <>
                  {formValue && (
                    <IconButton
                      disabled={loading}
                      aria-label="clear voucher"
                      onClick={handleClearVoucherInput}
                      edge="end"
                    >
                      <XClearCircleIcon />
                    </IconButton>
                  )}
                </>
              }
            />
            <Button
              className="apply-voucher-button ml-2.5"
              onClick={handleApplyClick}
              disabled={loading}
            >
              Apply
            </Button>
          </div>
          <FormHelperText className="pre-pigment-helper-text warning flex flex-row content-center mb-2">
            {showInputError ? (
              <>
                {<AlertWarningIcon />}
                {voucherErrors}
              </>
            ) : (
              ''
            )}
          </FormHelperText>
        </Collapse>
      </>
    )
  }

  const renderVoucherApplied = () => {
    return (
      <div className="flex flex-col">
        {voucher && renderVoucherAmount(voucher)}
        <div className="flex justify-between">
          <div className="flex-col">
            <span className="font-semibold">Voucher Applied</span>
            <div className="applied-voucher-value" data-testid="voucher-code">
              {formValue}
            </div>
          </div>
          <div
            onClick={removeAppliedVoucher}
            className={classNames({
              blue: !loading,
              grey: loading,
              'pointer-events-none': loading
            })}
          >
            Remove
          </div>
        </div>
      </div>
    )
  }

  const renderVoucherAmount = (voucher: PricingDiscounts) => {
    return (
      <div className="flex justify-between pb-2 font-semibold">
        <span data-testid="voucher-description">{voucher.description}</span>
        <div className="red" data-testid="voucher-amount">
          <span>-</span>
          {<Currency amount={voucher.amount} />}
        </div>
      </div>
    )
  }

  return (
    <div className="flex-col py-4" data-testid="VoucherInput">
      {voucherApplied ? renderVoucherApplied() : renderVoucherInput()}
    </div>
  )
}
export { VoucherForm, VoucherFormProps }
