import consumer from './consumer'

export default function CheckoutCallbackChannel(app, checkoutToken) {
  let subscription

  return {
    subscribe: function () {
      subscription = consumer.subscriptions.create(
        { channel: 'CheckoutCallbackChannel', checkout_token: checkoutToken },
        {
          connected() {
            console.log('listening for updates to checkout', checkoutToken)
          },

          disconnected() {
            console.log('lost connection from checkout', checkoutToken)
          },

          received(redirectUrl) {
            if (redirectUrl) {
              app.changeRedirectUrl(redirectUrl)
            }
          }
        }
      )
    },
    unsubscribe: function () {
      console.log('unsubscribing listener from checkout', checkoutToken)
      subscription.unsubscribe()
    }
  }
}
