import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import {
  PurchaseOrder,
  PurchaseOrderError,
  PurchaseOrderKey,
  PurchaseOrderValue
} from '@/types/purchaseOrder'
import {
  FieldValidator,
  DataValidator,
  ValidationOptions,
  CheckoutValidator
} from '@/types/validation'
import {
  doNotValidate,
  invalidData,
  validateEmailAddress,
  validateContactField,
  validateInstitution,
  validatePONumber,
  validateIsChecked,
  validateFile,
  validateUpload
} from '@/react/services/checkout_validation'
import rollbar from '@/react/utils/rollbar'

const purchase_order_validation = {
  max_contact_length: 80,
  max_institution_length: 80,
  max_po_number_length: 40,
  max_po_file_size_in_mb: 5,
  supported_file_types: [
    'image/png',
    'image/jpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
  ]
}

const purchaseOrderValidators: FieldValidator<
  PurchaseOrderKey,
  PurchaseOrderValue
> = {
  'contact': validateContactField({
    max: purchase_order_validation.max_contact_length
  }),
  'institution': validateInstitution({
    max: purchase_order_validation.max_institution_length
  }),
  'billing_email': validateEmailAddress({
    errors: { presence: 'Provide your billing email' },
    alwaysRequired: true
  }),
  'po_number': validatePONumber({
    max: purchase_order_validation.max_po_number_length
  }),
  'has_approval_signature': doNotValidate(),
  'agree_to_terms_and_conditions': validateIsChecked(),
  'po_file': validateFile({
    max: purchase_order_validation.max_po_file_size_in_mb,
    set: purchase_order_validation.supported_file_types
  }),
  'purchase_order_upload': doNotValidate(),
  'valid': doNotValidate()
}

const validatePurchaseOrderUpload = validateUpload()

export const validatePurchaseOrderField: DataValidator<PurchaseOrder> = (
  options
) => {
  if (!options || invalidData(options.data) || isEmpty(options.data)) {
    return []
  }
  const { required } = options
  const fieldName = Object.keys(options.data)[0] as PurchaseOrderKey

  if (fieldName in purchaseOrderValidators) {
    const value = options.data[fieldName]
    return purchaseOrderValidators[fieldName]({ value, required })
  } else {
    rollbar.error(
      'no validations available for purchase order field: ',
      fieldName
    )
    return []
  }
}

export const validatePurchaseOrder: CheckoutValidator<
  PurchaseOrder,
  PurchaseOrderError
> = (options) => {
  const errors = {} as PurchaseOrderError
  if (!options || invalidData(options.data)) {
    return { errors, valid: false }
  }
  const purchaseOrder = options.data as PurchaseOrder

  let valid = true
  each(purchaseOrderValidators, (validator, field) => {
    const purchaseOrderField = field as PurchaseOrderKey
    const value = purchaseOrder[purchaseOrderField]

    const validationOptions: ValidationOptions<PurchaseOrderValue> = {
      value,
      required: true
    }

    // when we validate the po_field field by itself, we use po_file,
    // but when we validate the PO, the file is no longer present
    // if the upload was succesful, the purchase_order_upload will have the 'name' property
    const fieldErrors =
      field === 'po_file'
        ? validatePurchaseOrderUpload({
            value: purchaseOrder.purchase_order_upload?.name
          })
        : validator(validationOptions)

    if (fieldErrors.length > 0) {
      valid = false
      errors[purchaseOrderField] = fieldErrors.join(', ')
    }
  })

  return { errors, valid }
}
