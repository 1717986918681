import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import { Contact, ContactError } from '@/types/contact'
import {
  FieldValidator,
  DataValidator,
  ValidationOptions,
  CheckoutValidator
} from '@/types/validation'
import {
  invalidData,
  validatePhoneNumber,
  validateEmailAddress,
  validateFirstName,
  validateLastName,
  validateOrganization
} from '@/react/services/checkout_validation'
import { config } from '@/services/DocumentService'
import rollbar from '@/react/utils/rollbar'

type ContactValidation = Pick<
  Contact,
  'first_name' | 'last_name' | 'phone' | 'email' | 'organization'
>

const { max_name_length, max_phone_length } = config.address_validation

export const contactValidators: FieldValidator<
  keyof ContactValidation,
  Contact[keyof ContactValidation]
> = {
  'first_name': validateFirstName({ max: max_name_length }),
  'last_name': validateLastName({ max: max_name_length }),
  'phone': validatePhoneNumber({ max: max_phone_length }),
  'email': validateEmailAddress({
    errors: { presence: 'Enter your email' },
    alwaysRequired: true
  }),
  'organization': validateOrganization({ max: max_name_length })
}

export const validateContactField: DataValidator<ContactValidation> = (
  options
) => {
  if (!options || invalidData(options.data) || isEmpty(options.data)) {
    return []
  }
  const { required } = options
  const fieldName = Object.keys(options.data)[0] as keyof ContactValidation

  if (fieldName in contactValidators) {
    const value = options.data[fieldName]
    return contactValidators[fieldName]({ value, required })
  } else {
    rollbar.error('no validations available for contact field: ', fieldName)
    return []
  }
}

export const validateContact: CheckoutValidator<Contact, ContactError> = (
  options
) => {
  const errors = {} as ContactError
  if (!options || invalidData(options.data)) {
    return { errors, valid: false }
  }
  const contact = options.data as Contact

  let valid = true
  each(contactValidators, (validator, field) => {
    const contactField = field as keyof ContactValidation
    const value = contact[contactField]

    const validationOptions: ValidationOptions<
      Contact[keyof ContactValidation]
    > = {
      value,
      required: true
    }

    const fieldErrors = validator(validationOptions)
    if (fieldErrors.length > 0) {
      valid = false
      errors[contactField] = fieldErrors.join(', ')
    }
  })
  return { errors, valid }
}
