// we want to get the property from window here so incompatible
// devices return undefined rather than a ReferenceError
// @ts-ignore
const getApplePaySession = () => window.ApplePaySession

export const applePaySession = getApplePaySession()

const getGoogleTagManagerWindow = () =>
  window as Window &
    typeof globalThis & {
      dataLayer: any[]
      google_tag_manager: { [key: string]: any }
    }

export const GTM = getGoogleTagManagerWindow()
