import React from 'react'
import { batch } from 'react-redux'
import { Link } from '@customink/pigment-react'
import { useAppSelector, useAppDispatch } from '@/react/hooks'
import {
  PurchaseOrder,
  PurchaseOrderField,
  PurchaseOrderStatus
} from '@/types/purchaseOrder'
import { Subset } from '@/types/generic'
import TextInput from '@/react/components/TextInput'
import Checkbox from '@/react/components/Checkbox'
import PurchaseOrderUploader from '@/react/features/checkout/PurchaseOrderUploader'
import { validatePurchaseOrderField } from '@/react/services/purchase_order_validation'
import {
  setPurchaseOrderErrors,
  clearPurchaseOrderErrors
} from '@/react/features/checkout/errorsSlice'
import {
  setPurchaseOrder,
  setPurchaseOrderStatus
} from '@/react/features/checkout/purchaseOrderSlice'
import { setVerifyPayment } from '@/react/features/checkout/paymentSlice'

export default function PurchaseOrderForm() {
  const dispatch = useAppDispatch()
  const purchaseOrder = useAppSelector((state) => state.purchase_order)
  const errors = useAppSelector((state) => state.errors.purchase_order)

  const termsAndConditionsLabel = (
    <>
      * I / my organization consents to Custom Ink's User Agreement &amp;
      Policies available{' '}
      <Link
        href="https://www.customink.com/about/user_agreement"
        // @ts-ignore
        rel="noopener"
        // @ts-ignore
        target="_blank"
      >
        here
      </Link>
      .
    </>
  )

  const handleBlur = (data: Subset<PurchaseOrderField>) => {
    let key: keyof PurchaseOrder
    for (key in data) {
      const fieldErrors = validatePurchaseOrderField({ data })
      if (fieldErrors.length) {
        dispatch(setPurchaseOrderErrors({ [key]: fieldErrors.join(', ') }))
      }
    }
  }

  const onChange = (data: Subset<PurchaseOrderField | PurchaseOrderStatus>) => {
    const keys = Object.keys(data) as Array<keyof PurchaseOrder>
    batch(() => {
      dispatch(clearPurchaseOrderErrors(keys))
      dispatch(setPurchaseOrderStatus({ valid: false }))
      dispatch(setVerifyPayment(false))
    })
  }

  const handleChange = (data: Subset<PurchaseOrderField>) => {
    onChange(data)
    dispatch(setPurchaseOrder(data))
  }

  const handleCheck = (data: Subset<PurchaseOrderStatus>) => {
    dispatch(setPurchaseOrderStatus(data))
  }

  const handleTermsAndConditionsCheck = (data: Subset<PurchaseOrderStatus>) => {
    onChange(data)
    dispatch(setPurchaseOrderStatus(data))
  }

  return (
    <div className="PurchaseOrderForm" data-testid="PurchaseOrderForm">
      <TextInput
        id="purchase-order-contact"
        label="Name of Department"
        value={purchaseOrder?.contact || ''}
        name="contact"
        className="w-full"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="e.g. Accounts Payable, Billing Dept."
        propertyName="contact"
        errorMessage={errors?.contact}
        required={true}
      />
      <TextInput
        id="purchase-order-institution"
        label="Institution"
        autoComplete="organization"
        value={purchaseOrder?.institution || ''}
        name="institution"
        className="w-full"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="e.g. CalTech, General Electric"
        propertyName="institution"
        errorMessage={errors?.institution}
        required={true}
      />
      <TextInput
        id="purchase-order-email"
        label="Billing Email"
        autoComplete="email"
        value={purchaseOrder?.billing_email || ''}
        name="billing_email"
        className="w-full"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Billing email"
        propertyName="billing_email"
        errorMessage={errors?.billing_email}
        required={true}
      />
      <TextInput
        id="purchase-order-number"
        label="PO Number"
        value={purchaseOrder?.po_number || ''}
        name="po_number"
        className="w-full"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder="PO number"
        propertyName="po_number"
        errorMessage={errors?.po_number}
        required={true}
      />
      <PurchaseOrderUploader />
      <Checkbox
        label="My PO contains approval signature OR signature is not required."
        propertyName="has_approval_signature"
        onChange={handleCheck}
        checked={!!purchaseOrder?.has_approval_signature}
      ></Checkbox>
      <Checkbox
        label={termsAndConditionsLabel}
        propertyName="agree_to_terms_and_conditions"
        onChange={handleTermsAndConditionsCheck}
        checked={!!purchaseOrder?.agree_to_terms_and_conditions}
        required={true}
        errorMessage={errors?.agree_to_terms_and_conditions}
      ></Checkbox>
    </div>
  )
}
