import React from 'react'
import classNames from 'classnames'
import {
  Checkbox as PigmentCheckbox,
  FormControlLabel,
  FormControl
} from '@customink/pigment-react'
import FormHelperText from '@mui/material/FormHelperText'

interface CheckboxProps {
  checked: boolean
  propertyName?: string
  disabled?: boolean
  errorMessage?: string
  label?: string | JSX.Element
  required?: boolean
  onChange?: (data: any) => void
}

const Checkbox = (props: CheckboxProps) => {
  const classes = classNames('Checkbox', {
    'checkbox-error': !!props.errorMessage
  })

  return (
    <div className={classes}>
      <FormControl error={!!props.errorMessage}>
        <FormControlLabel
          label={props.label || ''}
          disabled={props.disabled}
          control={
            <PigmentCheckbox
              checked={props.checked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (props.propertyName && props.onChange) {
                  props.onChange({
                    [props.propertyName]: event.target.checked
                  })
                }
              }}
              required={props.required}
            />
          }
        ></FormControlLabel>
        <FormHelperText className="pre-pigment-helper-text">
          {!!props.errorMessage ? props.errorMessage : null}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default Checkbox
