import React from 'react'
import { Button } from '@customink/pigment-react'

const EmptyCart = () => (
  <div className="p-6 m-auto text-center">
    <div className="title huge mb-4">Your shopping cart is empty</div>

    <div className="text-muted w-2/3 m-auto text-lg">
      We could not find any items in your cart. Please continue to the homepage
      to order your design.
    </div>

    <Button href="https://www.customink.com" className="my-6">
      Return to the Homepage
    </Button>
  </div>
)

export default EmptyCart
