import { GetTokenSilentlyOptions } from '@auth0/auth0-react'

let getAccessTokenSilently: (
  options: GetTokenSilentlyOptions | undefined
) => Promise<string>

export const securityService = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    auth0RefFunction: (
      options: GetTokenSilentlyOptions | undefined
    ) => Promise<string>
  ) => (getAccessTokenSilently = auth0RefFunction)
}
