import { EffectCallback, useEffect } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '@/react/store'
import rollbar from '@/react/utils/rollbar'
import { ApiErrorResponse } from '@/types/api'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useDebouncedEffect = (
  callback: EffectCallback,
  deps: any[],
  time: number
) => {
  useEffect(() => {
    const timeout = setTimeout(callback, time)
    return () => {
      clearTimeout(timeout)
    }
  }, [...deps, callback, time])
}

export const useEffectOnce = (callback: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callback(), [])
}

export const useTeardownEffect = (callback: () => void) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => callback(), [])
}

export const useResponse = (
  response: { data?: any; error?: ApiErrorResponse | any },
  handler: (data?: any) => void,
  errorHandler?: (response: any) => void
) => {
  useEffect(() => {
    if (response?.data) {
      handler(response.data)
    } else if (response?.error) {
      errorHandler && errorHandler(response.error.data)
      rollbar.info(
        `External Error${
          response.error.status ? ` ${response.error.status}` : ''
        }${
          response.error.data?.message ? `: ${response.error.data.message}` : ''
        }${
          response.error.data?.detail ? `: ${response.error.data.detail}` : ''
        }`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.data, response?.error])
}

export const useErrorResponse = (
  response: { data?: any; error?: any },
  handler: (data?: any) => void
) => {
  useEffect(() => {
    if (response?.error) {
      handler(response.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.data, response?.error])
}
