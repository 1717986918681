import React from 'react'
import { isEmpty } from 'lodash'
import { useAppSelector } from '@/react/hooks'
import {
  selectVouchersReadonly,
  selectPricingDisabled,
  selectVouchersDisabled
} from '@/react/selectors'
import LineItem, { LineItemWithImages } from '@/react/components/LineItem'
import type { RootState } from '@/react/store'
import formatCurrency from '@/react/utils/currencyFormatter'
import { VoucherForm } from '@/react/components/VoucherForm'
import { voucherFormFlag } from '@/react/services/feature_flags'
import { voucherDiscountTypes } from '@/types/checkout'

const OrderSummary = (props: {
  isQuotingCheckout?: boolean
  alwaysShowTax?: boolean
}) => {
  // should configuration logic go here?
  const checkout = useAppSelector((state: RootState) => state.checkout)
  const voucherFlagEnabled = voucherFormFlag.enabled

  const voucherDisabled = useAppSelector(selectVouchersDisabled)
  const voucherReadonly = useAppSelector(selectVouchersReadonly)
  const voucherNotDisabledAndReadonly = !voucherDisabled && voucherReadonly

  const lineItemVoucher = checkout?.pricing?.discounts?.find((_discount) => {
    return voucherDiscountTypes.includes(_discount.type)
  })
  // in case the LoB does not pass the correct config, we'll treat "unanchored" voucher
  // discounts (i.e. don't have a matching `vouchers` item) as readonly
  const unanchoredVoucherDiscount =
    lineItemVoucher && !lineItemVoucher.code && isEmpty(checkout.vouchers)
  const pricingDisabled = useAppSelector(selectPricingDisabled)
  let checkoutVoucher
  if (checkout.vouchers && checkout.vouchers.length) {
    checkoutVoucher = checkout.vouchers[0]
  }

  return (
    <>
      <div className="LineItems divide-y">
        {checkout.items &&
          checkout.items.map((item, idx) => {
            const uom =
              item.unit_of_measure === 'ITM' ||
              typeof item.unit_of_measure !== 'string'
                ? 'each'
                : item.unit_of_measure.toLowerCase()
            const quantityPrice =
              item.unit_amount && !pricingDisabled
                ? `Qty ${item.quantity} @ ${formatCurrency(
                    item.unit_amount
                  )} ${uom}`
                : `Qty ${item.quantity}`
            const key = `checkout-LineItem-${idx}`

            return (
              <LineItemWithImages
                amount={item.subtotal_amount}
                baseKey={key}
                description={item.description}
                images={item.image_urls || []}
                key={key}
                quantityPrice={quantityPrice}
                title={item.name}
                pricingDisabled={pricingDisabled}
              />
            )
          })}
        {!pricingDisabled && checkout.pricing && (
          <>
            <LineItem amount={checkout.pricing.subtotal} title="Subtotal" />
            <LineItem
              amount={checkout.pricing.service_charge_amount}
              description={checkout.pricing.service_charge_description}
              title="Service Charges"
            />
            <LineItem
              alwaysShow
              amount={checkout.pricing.shipping}
              title="Shipping &amp; Handling"
            />
            <LineItem
              amount={checkout.pricing.discount_amount}
              credit
              description={checkout.pricing.discount_description}
              title="Discounts"
            />
            <LineItem
              alwaysShow={props.alwaysShowTax}
              amount={checkout.pricing.tax}
              placeholder={props.alwaysShowTax ? '--' : undefined}
              loading={props.isQuotingCheckout}
              title="Tax"
            />
            {
              // feature off || unanchored voucher = legacy
              !voucherFlagEnabled || unanchoredVoucherDiscount ? (
                <LineItem
                  amount={checkout.pricing.voucher_discount_amount}
                  credit
                  description={checkout.pricing.voucher_discount_description}
                  title="Voucher"
                />
              ) : // feature on, voucher enabled and readonly
              voucherNotDisabledAndReadonly ? (
                <LineItem
                  amount={lineItemVoucher?.amount}
                  credit
                  title={lineItemVoucher?.description || 'Voucher'}
                />
              ) : (
                // feature on, voucher enabled and editable
                !voucherDisabled && (
                  <VoucherForm
                    lineItemVoucher={lineItemVoucher}
                    checkoutVoucher={checkoutVoucher}
                  ></VoucherForm>
                )
              )
            }
            <LineItem
              amount={checkout.pricing.collected}
              credit
              title="Already Collected"
            />
            <LineItem
              amount={checkout.pricing.amount}
              bottomLine
              title="Total"
            />
            <div className="flex items-center py-4 text-sm">
              <i className="Icon icon icon-info mr-1 text-lg"></i>
              Customs charges may apply for orders outside of US
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default OrderSummary
