import React, { useEffect, useState } from 'react'
import { useEffectOnce } from '@/react/hooks'
import { parsePhoneNumber } from 'awesome-phonenumber'
import SelectInput from '@/react/components/SelectInput'
import TextInput from '@/react/components/TextInput'
import { Address } from '@/types/address'
import { Contact } from '@/types/contact'
import { Subset } from '@/types/generic'
import { countries, getCountryByCode } from '@/react/services/addressHelpers'
import FlagIcon from '@/react/components/FlagIcon'

interface PhoneInputProps {
  countryCode?: string
  disabled?: boolean
  disableFormatOnLoad?: boolean
  errorMessage?: string
  phoneNumber?: string
  required?: boolean
  propertyName?: string
  onBlur?: (data: Subset<Address>) => void
  onChange: (address: Subset<Address>) => void
  onBlurWithCountry?: (
    data: Partial<Address & Contact>,
    countryCode: string
  ) => void
}

const PhoneInput = ({
  propertyName = 'phone_number',
  ...props
}: PhoneInputProps) => {
  const { onBlur, onChange, required, onBlurWithCountry } = props
  const [countryCode, setUpperCaseCountryCode] = useState<string | undefined>()
  const [flagIsFocused, setFlagIsFocused] = useState<boolean>(false)

  const getRegion = () => {
    if (!props.phoneNumber) {
      return undefined
    }
    return parsePhoneNumber(props.phoneNumber).getRegionCode()
  }

  const getDefaultCountry = () => {
    const region = getRegion()
    if (region) {
      return region
    } else if (props.countryCode && required) {
      return props.countryCode
    } else if (required) {
      return 'US'
    } else {
      return
    }
  }

  const [defaultCountry] = useState<string | undefined>(getDefaultCountry())

  // Sanitize international number prefix `00` to `+`
  const sanitize = (number: string) => {
    const find = /^\+{1}00/
    return `+${number.replace(find, '').replace('+', '')}`
  }

  const processPhoneNumber = (number: string, countryCode: string) => {
    const sanitized = sanitize(number)
    const pn = countryCode
      ? parsePhoneNumber(sanitized, countryCode)
      : parsePhoneNumber(sanitized)

    return {
      valid: pn.isValid(),
      regionCode: pn.getRegionCode(),
      formatted: pn.getNumber('international')
    }
  }

  const setPhoneNumber = (phoneNumber: string) => {
    if (props.phoneNumber !== phoneNumber) {
      onChange({ [propertyName]: phoneNumber })
    }
  }

  const setCountryCode = (code?: string) => {
    const nextCountryCode = code?.toUpperCase()
    if (nextCountryCode !== countryCode?.toUpperCase()) {
      setUpperCaseCountryCode(nextCountryCode)
    }
  }

  const handleChange = (phoneNumber: string) => {
    const processed = processPhoneNumber(phoneNumber, countryCode || '')

    if (processed.regionCode) {
      setCountryCode(processed.regionCode)
    }

    const nextPhoneNumber = processed.valid ? processed.formatted : phoneNumber
    setPhoneNumber(nextPhoneNumber)
  }

  const replaceCountryCode = (code: string) => {
    setCountryCode(code)
    const currentCountry = getCountryByCode(countryCode || '')
    const nextCountry = getCountryByCode(code)
    let mutablePhoneNumber = props.phoneNumber

    if (currentCountry) {
      mutablePhoneNumber = props.phoneNumber?.replace(
        new RegExp(`^\\+${currentCountry.dial}`),
        ''
      )
    }

    if (mutablePhoneNumber) {
      handleChange(`+${nextCountry!.dial}${mutablePhoneNumber}`)
    }
  }

  const handleBlur = () => {
    if (props.phoneNumber) {
      handleChange(props.phoneNumber)
    }
    onBlurWithCountry
      ? onBlurWithCountry(
          {
            [propertyName]: props.phoneNumber
          },
          (countryCode || defaultCountry) as string
        )
      : onBlur?.({
          [propertyName]: props.phoneNumber
        })
  }

  useEffectOnce(() => {
    const phoneNumber = processPhoneNumber(
      props.phoneNumber || '',
      defaultCountry || ''
    )
    if (phoneNumber.valid) {
      setCountryCode(phoneNumber.regionCode)

      if (!props.disableFormatOnLoad) {
        setPhoneNumber(phoneNumber.formatted)
      }
    }
  })

  useEffect(() => {
    if (
      !countryCode &&
      defaultCountry &&
      required &&
      !props.disableFormatOnLoad
    ) {
      setCountryCode(defaultCountry)

      if (!props.phoneNumber || props.phoneNumber.length === 0) {
        const country = getCountryByCode(defaultCountry)
        if (country) {
          setPhoneNumber(`+${country.dial}`)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  const FlagSelect = (
    <>
      <FlagIcon country={countryCode} isFocused={flagIsFocused} />
      <SelectInput
        className="absolute inset-0 w-12 h-full opacity-0 cursor-pointer"
        disabled={props.disabled}
        defaultValue="Select"
        name="region"
        options={countries.map((country) => ({
          label: `${country.name} (+${country.dial})`,
          value: country.code
        }))}
        placeholder="Select Country"
        propertyName="region"
        value={countryCode}
        onBlur={() => {
          handleBlur()
          setFlagIsFocused(false)
        }}
        onChange={(data) => replaceCountryCode(data['region'])}
        onFocus={() => setFlagIsFocused(true)}
      />
    </>
  )

  return (
    <div className="PhoneInput flex-wrap w-full" data-testid="PhoneInput">
      <TextInput
        autoComplete="tel"
        disabled={props.disabled}
        errorMessage={props.errorMessage}
        id="phone-number"
        label="Phone Number"
        name="phone_number"
        placeholder="Mobile number preferred"
        propertyName={propertyName}
        required={required}
        startAdornment={FlagSelect}
        type="tel"
        value={props.phoneNumber || ''}
        onBlur={() => handleBlur()}
        onChange={(data: any) => setPhoneNumber(data[propertyName])}
      />
    </div>
  )
}

export default PhoneInput
