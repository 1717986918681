import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Subset } from '@/types/generic'

interface StatusState {
  isCompletingCheckout: boolean
  isLoadingCheckout: boolean
  renderedPayPalButtons: boolean
  showCertifyAddressModal: boolean
  showOrderSummaryModal: boolean
  showPayment: boolean
}

const initialState = {} as StatusState

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus: (
      state: StatusState,
      action: PayloadAction<Subset<StatusState>>
    ) => ({ ...state, ...action.payload })
  }
})

export const { setStatus } = statusSlice.actions
export default statusSlice.reducer
