import React from 'react'
import type { Address } from '@/types/address'
import type { Contact } from '@/types/contact'
import {
  getCountryName,
  getStateName,
  isUsAddress
} from '@/react/services/addressHelpers'

const AddressBlock = ({
  title,
  address,
  contact,
  email
}: {
  title?: string
  address?: Address
  email?: Contact['email']
  contact?: Contact
}) => {
  const stateName = getStateName(address)
  const countryName = getCountryName(address)

  const displayName =
    address?.first_name || address?.last_name ? (
      <div className="font-bold">
        {[address?.first_name, address?.last_name].filter(Boolean).join(' ')}
      </div>
    ) : null

  const organization = contact?.organization ? (
    <div className="font-bold">{contact?.organization}</div>
  ) : null

  const streetLine =
    address?.address_1 || address?.address_2 ? (
      <div>
        {[address?.address_1, address?.address_2].filter(Boolean).join(' ')}
      </div>
    ) : null

  const cityState =
    address?.city || stateName
      ? [address?.city, stateName].filter(Boolean).join(', ')
      : undefined

  const usCityLine =
    cityState || address?.postal_code ? (
      <div>{[cityState, address?.postal_code].filter(Boolean).join(' ')}</div>
    ) : null

  const usCountryLine = countryName ? <div>{countryName}</div> : null

  const intlCityLine =
    address?.city || address?.postal_code ? (
      <div>
        {[address?.city, address?.postal_code].filter(Boolean).join(', ')}
      </div>
    ) : null

  const intlCountryLine =
    stateName || countryName ? (
      <div>{[stateName, countryName].filter(Boolean).join(', ')}</div>
    ) : null

  const emailAddress = email ? <div>{email}</div> : null

  const phoneNumber = address?.phone_number ? (
    <div>{address.phone_number}</div>
  ) : null

  return (
    <div className="text-left" data-testid="AddressBlock">
      {title && <b>{title}</b>}
      {displayName}
      {organization}
      {streetLine}
      {isUsAddress(address) ? (
        <>
          {usCityLine}
          {usCountryLine}
        </>
      ) : (
        <>
          {intlCityLine}
          {intlCountryLine}
        </>
      )}
      {emailAddress}
      {phoneNumber}
    </div>
  )
}

export default AddressBlock
