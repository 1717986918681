import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AddressError } from '@/types/address'
import type { CheckoutError } from '@/types/checkout'
import type { ContactError } from '@/types/contact'
import type { PaymentError } from '@/types/payment'
import type { PurchaseOrderError } from '@/types/purchaseOrder'
import { Subset } from '@/types/generic'

const initialState = {} as CheckoutError

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setErrors: (_state: CheckoutError, action: PayloadAction<CheckoutError>) =>
      action.payload,
    setContactErrors: (
      state: CheckoutError,
      action: PayloadAction<Subset<ContactError>>
    ) => ({ ...state, contact: { ...state.contact, ...action.payload } }),
    setShippingErrors: (
      state: CheckoutError,
      action: PayloadAction<Subset<AddressError>>
    ) => ({
      ...state,
      shipping_address: { ...state.shipping_address, ...action.payload }
    }),
    clearShippingErrors: (state: CheckoutError) => ({
      ...state,
      shipping_address: undefined
    }),
    setPaymentErrors: (
      state: CheckoutError,
      action: PayloadAction<Subset<PaymentError>>
    ) => ({ ...state, payment: { ...state.payment, ...action.payload } }),
    clearPaymentErrors: (
      state: CheckoutError,
      action: PayloadAction<Array<keyof PaymentError>>
    ) => {
      const draft = { ...state.payment }

      action.payload.forEach((key) => {
        draft[key] = undefined
      })

      return {
        ...state,
        payment: draft
      }
    },
    clearAllPaymentErrors: (state: CheckoutError) => ({
      ...state,
      payment: undefined
    }),
    setBillingErrors: (
      state: CheckoutError,
      action: PayloadAction<Subset<AddressError>>
    ) => ({
      ...state,
      billing_address: { ...state.billing_address, ...action.payload }
    }),
    clearBillingErrors: (state: CheckoutError) => ({
      ...state,
      billing_address: undefined
    }),
    setPurchaseOrderErrors: (
      state: CheckoutError,
      action: PayloadAction<Subset<PurchaseOrderError>>
    ) => ({
      ...state,
      purchase_order: { ...state.purchase_order, ...action.payload }
    }),
    clearPurchaseOrderErrors: (
      state: CheckoutError,
      action: PayloadAction<Array<keyof PurchaseOrderError>>
    ) => {
      const draft = { ...state.purchase_order }

      action.payload.forEach((key) => {
        draft[key] = undefined
      })

      return {
        ...state,
        purchase_order: draft
      }
    },
    setVoucherErrors: (
      state: CheckoutError,
      action: PayloadAction<string>
    ) => ({
      ...state,
      vouchers: action.payload
    }),
    clearVoucherErrors: (state: CheckoutError) => ({
      ...state,
      vouchers: undefined
    })
  }
})

export const {
  clearAllPaymentErrors,
  clearBillingErrors,
  clearPaymentErrors,
  clearShippingErrors,
  clearPurchaseOrderErrors,
  setBillingErrors,
  setErrors,
  setContactErrors,
  setShippingErrors,
  setPaymentErrors,
  setPurchaseOrderErrors,
  setVoucherErrors,
  clearVoucherErrors
} = errorsSlice.actions
export default errorsSlice.reducer
