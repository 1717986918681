import React, {
  createContext,
  SetStateAction,
  useEffect,
  useState,
  useCallback
} from 'react'
import {
  BraintreeError,
  ApplePay,
  applePay,
  HostedFields,
  Client,
  client,
  paypalCheckout,
  PayPalCheckout
} from 'braintree-web'
import { useAppDispatch, useAppSelector, useEffectOnce } from '@/react/hooks'
import { selectIsPayPal } from '@/react/selectors'
import { useGetBraintreeTokenQuery } from '@/react/services/checkout_api'
import rollbar from '@/react/utils/rollbar'
import Payment from '@/react/features/checkout/Payment'
import { setFlash } from '@/react/features/checkout/flashSlice'
import { applePaySession } from '@/services/WindowService'

// FIXME remove everything possible from context to avoid unnecessary re-renders
type PaymentClientState = {
  braintreeEnv?: string
  braintreeToken?: string
  paymentFields?: HostedFields
  setPaymentFields: React.Dispatch<SetStateAction<HostedFields | undefined>>
  braintreeClient?: Client
  applePayClient?: ApplePay
  paypalClient?: PayPalCheckout
  paypalButtons?: any
  paypalIntent: string
  isEligibleForApplePay: boolean
}

const initialState = {} as PaymentClientState

export const PaymentContext = createContext<PaymentClientState>(initialState)

const PaymentClientLoader = ({ children }: { children: any }) => {
  const maxRetries = 3
  const appDispatch = useAppDispatch()
  const dispatch = useCallback(appDispatch, [appDispatch])
  const storedUser = useAppSelector((state) => state.user)
  const checkoutToken = useAppSelector((state) => state.checkout.token)
  const { data } = useGetBraintreeTokenQuery({
    token: checkoutToken,
    accountId: storedUser?.account_id
  })

  const isPayPal = useAppSelector(selectIsPayPal)
  const [clientId, setClientId] = useState<string | undefined>()

  const [retries, setRetries] = useState<number>(maxRetries)
  const [paymentFields, setPaymentFields] = useState<HostedFields | undefined>()
  const [_braintreeError, setBraintreeError] = useState<
    BraintreeError | Error | undefined
  >()

  const [context, setContext] = useState<PaymentClientState>({
    paymentFields,
    setPaymentFields,
    paypalIntent: 'capture'
  } as PaymentClientState)

  const initializeBraintree = useCallback((token: string): void => {
    client.create(
      { authorization: token },
      (braintreeError, braintreeClient) => {
        if (braintreeError) {
          setBraintreeError(braintreeError)
          setRetries((current) => current - 1)
        } else {
          setContext((state) => ({
            ...state,
            braintreeClient
          }))
        }
      }
    )
  }, [])

  const initializeApplePay = useCallback((braintreeClient: Client): void => {
    applePay.create(
      { client: braintreeClient },
      (braintreeError, applePayClient) => {
        if (braintreeError) {
          setBraintreeError(braintreeError)
        } else if (!applePayClient) {
          const loaderError = new Error(
            'apple pay client creation failed to return a client instance'
          )
          setContext((state) => ({
            ...state,
            loaderError
          }))
        } else {
          setContext((state) => ({
            ...state,
            applePayClient
          }))
        }
      }
    )
  }, [])

  const initializePayPal = useCallback((braintreeClient: Client): void => {
    paypalCheckout.create(
      { client: braintreeClient },
      (braintreeError, paypalClient) => {
        if (braintreeError) {
          setBraintreeError(braintreeError)
        } else {
          setContext((state) => ({
            ...state,
            paypalClient
          }))
        }
      }
    )
  }, [])

  // set apple pay eligibility status
  useEffectOnce(() => {
    const isEligibleForApplePay = !!(
      applePaySession && ApplePaySession?.canMakePayments()
    )

    setContext((state) => ({
      ...state,
      isEligibleForApplePay
    }))
  })

  // get paypal client id after the paypal client is connected
  useEffect(() => {
    const getClientId = async (paypalClient: PayPalCheckout) => {
      try {
        const response = await paypalClient.getClientId()
        setClientId(response)
      } catch (error) {
        rollbar.error('failed to get paypal client id', error)
      }
    }
    if (context.paypalClient && !clientId) {
      getClientId(context.paypalClient)
    }
  }, [clientId, context.paypalClient])

  // setup standalone paypal buttons
  useEffect(() => {
    const loadPaypalCheckout = async () => {
      return (import('paypal-checkout' as string) as any).then(
        (response: { Button?: any }) => {
          if (response?.Button) {
            setContext((state) => ({
              ...state,
              paypalButtons: response.Button
            }))
          }
        }
      )
    }

    if (isPayPal && clientId && !context.paypalButtons) {
      loadPaypalCheckout()
    }
  }, [
    clientId,
    context.braintreeToken,
    context.paypalButtons,
    context.paypalIntent,
    isPayPal
  ])

  // setup paypal client connection after the braintree client is connected
  useEffect(() => {
    if (context.paypalClient) {
      return
    } else if (context.braintreeClient) {
      initializePayPal(context.braintreeClient)
    }
  }, [context.braintreeClient, context.paypalClient, initializePayPal])

  // setup applepay client connection after the braintree client is connected
  useEffect(() => {
    if (context.applePayClient) {
      return
    } else if (context.braintreeClient) {
      initializeApplePay(context.braintreeClient)
    }
  }, [context.applePayClient, context.braintreeClient, initializeApplePay])

  // setup braintree client connection first
  useEffect(() => {
    if (context.braintreeClient) {
      return
    } else if (context.braintreeToken && context.braintreeEnv) {
      if (retries > 0) {
        initializeBraintree(context.braintreeToken)
      } else {
        dispatch(
          setFlash({
            type: 'error',
            message:
              'We are having trouble connecting to payment services, please try again later'
          })
        )
      }
    }
  }, [
    context.braintreeClient,
    context.braintreeEnv,
    context.braintreeToken,
    dispatch,
    initializeBraintree,
    retries
  ])

  useEffect(() => {
    if (context.braintreeToken && context.braintreeEnv) {
      return
    } else if (data) {
      setContext((state) => ({
        ...state,
        braintreeEnv: data.braintree_env,
        braintreeToken: data.braintree_token
      }))
    }
  }, [context.braintreeEnv, context.braintreeToken, data])

  useEffect(() => {
    if (!paymentFields) {
      return
    }
    setContext((state) => ({ ...state, paymentFields }))
  }, [paymentFields])

  return (
    <PaymentContext.Provider value={context}>
      {children}
      <Payment />
    </PaymentContext.Provider>
  )
}

export default PaymentClientLoader
